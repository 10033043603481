import React, { useState, useEffect } from 'react';
import { Button, InputNumber, Typography } from 'antd';

import InputControl from '../controls/InputControl/InputControl';
import SelectControl from '../controls/SelectControl/SelectControl';

import { ReactComponent as ClosIcon } from 'svg/icon/close-black.svg';
import { ReactComponent as LockIcon } from 'svg/graphicEditor/lock-small.svg';

import styles from './ProjectProperties.module.css';
import globalStyles from '../GlobalGraphicEditor.module.css';

const { Text } = Typography;
export default (props) => {
  const [designName, setDesignName] = useState(props.designName);
  const [width, setWidth] = useState(props.width ?? '');
  const [height, setHeight] = useState(props.height ?? '');
  const [preset, setPreset] = useState(props.preset);
  const [userWidth, setUserWidth] = useState(props.width ?? '');
  const [userHeight, setUserHeight] = useState(props.height ?? '');
  const [isLocked, setIsLocked] = useState(true);

  useEffect(() => {
    const obj = props.presetsDataSource.find((t) => t.id === preset);
    setIsLocked(!obj?.customSize);
  }, [preset]);

  const propertiesChanged = (value) => {
    setDesignName(value);
  };

  const presetChanged = (value) => {
    const obj = props.presetsDataSource.find((t) => t.id === value);
    setPreset(value);
    if (!obj.customSize) {
      setWidth(obj.width);
      setHeight(obj.height);
    } else {
      setWidth(userWidth);
      setHeight(userHeight);
    }
  };

  const widthChanged = (value) => {
    setWidth(value);
    if (!isLocked) {
      setUserWidth(value);
    }
  };

  const heightChanged = (value) => {
    setHeight(value);
    if (!isLocked) {
      setUserHeight(value);
    }
  };

  const save = () => {
    props.setProperties(designName, preset, width, height);
    props.close();
  };

  return (
    <div className={styles.modalBg}>
      <div className={styles.modal}>
        <div className={styles.headerBlock}>
          <Text className={styles.labelText}>Template name and dimensions</Text>
          <Button
            type='text'
            icon={<ClosIcon />}
            onClick={props.close}
            className={styles.closeIcon}
          />
        </div>
        <div>
          <InputControl
            value={designName}
            onChange={(e) => propertiesChanged(e.target.value)}
            customStyles={`${globalStyles.input} ${styles.input}`}
          />
          <div className={styles.selectBlock}>
            <Text className={styles.labelText}>Size presets</Text>
            <SelectControl
              row={true}
              value={preset}
              dataSource={props.presetsDataSource}
              onChange={presetChanged}
              style={`${globalStyles.input} ${styles.input}`}
            />
          </div>
          <div className={styles.containerRow}>
            <div className={styles.measureBlock}>
              <Text>W</Text>
              <InputNumber
                disabled={isLocked}
                type='number'
                value={width}
                onChange={widthChanged}
                className={`${globalStyles.input} ${styles.smallInput}`}
              />
              <Text className={styles.labelText}>px</Text>
            </div>
            <div>{isLocked && <LockIcon />}</div>
            <div className={styles.measureBlock}>
              <Text>H</Text>
              <InputNumber
                disabled={isLocked}
                type='number'
                value={height}
                onChange={heightChanged}
                className={`${globalStyles.input} ${styles.smallInput}`}
              />
              <Text className={styles.labelText}>px</Text>
            </div>
          </div>
        </div>
        <div className={styles.bottomBlock}>
          <button className={styles.button} onClick={props.close}>
            Cancel
          </button>
          <button
            className={`${styles.button} ${styles.buttonOk}`}
            onClick={save}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
