import React from 'react'
import {Button} from 'antd'

import {ReactComponent as CloseIcon} from "../../../svg/icon/close-black.svg";

import styles from './ProjectsReport.module.css'

export const ProjectsReport = ({open, children, handleClose}) => {
  return (
    <div className={open ? styles.projectReport : styles.projectReportClosed}>
      <div className={styles.buttonContainer}>
        <Button className={styles.button} icon={<CloseIcon />} onClick={handleClose} />
      </div>
      {children}
    </div>
  )
}