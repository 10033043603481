import React from 'react';
import { Spin } from 'antd';

export const ValidationLogs = ({logs, title, isLoading = false}) => {

  if(isLoading) return <div className='centered-container'><Spin /></div>

  return (
    <div>
      <h1>{title}</h1>
      {logs && (
        <div>
          <pre>{JSON.stringify(logs, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}