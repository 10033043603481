export const emptyColor = 'rgba(0,0,0,0)';

export const isColorSet = (value) => {
  return value && value !== emptyColor;
};

export const isSeparatelyEnabled = (cornerLT, cornerLR, cornerBR, cornerBL) => {
  return !(
    cornerLT === cornerLR &&
    cornerLT === cornerBR &&
    cornerLT === cornerBL
  );
};

export const refreshTransform = (group) => {
  if (group) {
    const tr = group.getStage().findOne('Transformer');
    if (tr) {
      tr.nodes(tr.nodes());
    }
  }
};
