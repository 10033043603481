import { isAxiosError } from "axios";

import { axiosApi } from "axios-api"

export const getPricingPlans = async () => {
  try {
      const { data } = await axiosApi.get('v2/billing/price-plans/');
      return data;
  } catch (error) {
      if (isAxiosError(error)) {
          message.warning({
              content: error.message,
              duration: 3,
          });
      } else {
          throw new Error(error);
      }
  }
}