import React from 'react';
import dayjs from "dayjs";
import {Button, Typography} from "antd";

import {ReactComponent as Pending} from '../../svg/icon/pending-notification.svg';
import {ReactComponent as Success} from '../../svg/icon/success-notification.svg';
import {ReactComponent as Error} from '../../svg/icon/error-notification.svg';
import {ReactComponent as Close} from '../../svg/icon/close-green.svg';

import styles from './SubscriptionNotification.module.css';

const {Text} = Typography;

const SubscriptionNotification = ({status, onNotificationClose, endDate = null}) => {
    const getIcon = (status, endDate) => {
        const iconsByStatus = {
            error: {
                title: 'Your payment transaction failed',
                description: 'Please make sure that there are enough funds on your payment card and try again.',
                icon: (<Error/>),
                bgColor: '#FFF9F9',
                color: '#FF4B55'
            },
            success: {
                title: 'Your payment transaction was successful',
                description: 'Please wait until the payment is completed.',
                icon: (<Success/>),
                bgColor: '#ECFDEE',
                color: '#5EB067'
            }
            ,
            2: {
                title: `Your subscription ends on ${dayjs(endDate).format('MM.DD.YYYY')}`,
                description: 'To continue working on your nodes, feeds, and designs, please renew your subscription.',
                icon: (<Pending/>),
                bgColor: '#FFFDF9',
                color: '#FFB74B'
            },
            3: {
                title: 'Your subscription period has ended',
                description: 'Please renew your subscription to continue working.',
                icon: (<Error/>),
                bgColor: '#FFF9F9',
                color: '#FF4B55'
            },
            5: {
                title: 'You now have a free trial',
                bgColor: '#EFEDF8',
                color: '#6651BD'
            },
            default: '',
        }
        return iconsByStatus[status] ?? iconsByStatus.default;
    };


    return (
        <div
            className={styles.wrapper}
            style={{
                backgroundColor: getIcon(status).bgColor,
                color: getIcon(status).color,
                borderColor: getIcon(status).color,
            }}
        >
            <div>
                {getIcon(status).icon}
            </div>
            <div className={styles.textBlock}>
                <Text className={styles.title}>
                    {getIcon(status, endDate).title}
                </Text>
                <Text className={styles.description}>
                    {getIcon(status).description}
                </Text>
            </div>
            {status === 'success' &&
                <Button type='text' onClick={onNotificationClose} icon={<Close/>}/>
            }
        </div>
    );
};

export default SubscriptionNotification;
