import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Button, InputNumber, Typography } from 'antd';
import { ColumnWidthOutlined } from '@ant-design/icons';

import SelectControl from '../../controls/SelectControl/SelectControl';
import PositioningControl from '../../controls/PositioningControl/PositioningControl';
import { refreshTransform } from '../PropertiesHelper';
import { scaleImage, scaleText } from '../../../services/ScaleService';
import {
  getAbsoluteElementPosition,
  getParentPosition,
  refreshParentGroup,
} from '../../../services/PropertiesService';
import {
  distributionAlignHorizontalDataSource,
  distributionAlignVerticalDataSource,
  distributionDirectionsDataSource,
} from './GroupAppearanceConstants';
import { ReactComponent as MinusIcon } from 'svg/graphicEditor/minus-big-black.svg';
import { ReactComponent as PlusIcon } from 'svg/graphicEditor/plus-big-black.svg';

import myStyles from './GroupAppearance.module.css';
import globalStyles from '../../GlobalGraphicEditor.module.css';

const { Text } = Typography;

export default function (props) {
  const [layoutPositioning, setLayoutPositioning] = useState();
  const [positionX, setPositionX] = useState();
  const [positionY, setPositionY] = useState();
  const [groupWidth, setGroupWidth] = useState();
  const [groupHeight, setGroupHeight] = useState();
  const [distribute, setDistribute] = useState();
  const [distributeDirection, setDistributeDirection] = useState();
  const [distributeAlign, setDistributeAlign] = useState();
  const [distributeOffset, setDistributeOffset] = useState();
  const [inputGroupWidth, setInputGroupWidth] = useState();
  const [inputGroupHeight, setInputGroupHeight] = useState();
  const [isDistributeOffsetEnabled, setDistributeOffsetEnabled] =
    useState(false);

  const minGroupWidth = 25;
  const minGroupHeight = 25;

  const { group } = props;

  useEffect(() => {
    const stage = props.group.getStage();
    const groupClientRect = props.group.getClientRect();
    const canvasPosition = stage.findOne('#canvas_bg').position();
    const position = getAbsoluteElementPosition(group, canvasPosition);

    const distributeOffset = props.group.getAttr('distributeOffset') ?? 0;

    setPositionX(position.x);
    setPositionY(position.y);

    setGroupWidth(parseInt(groupClientRect.width));
    setGroupHeight(parseInt(groupClientRect.height));

    setInputGroupWidth(parseInt(groupClientRect.width));
    setInputGroupHeight(parseInt(groupClientRect.height));

    setDistribute(props.group.hasName('distributed'));
    setDistributeDirection(
      props.group.getAttr('distributeDirection') ??
        distributionDirectionsDataSource[0].id,
    );
    setDistributeAlign(
      props.group.getAttr('distributeAlign') ??
        distributionAlignVerticalDataSource[0].id,
    );
    setDistributeOffset(distributeOffset);
    setDistributeOffsetEnabled(distributeOffset !== 0);
    const attachId = props.group.getAttr('attachId');
    let aid;
    if (attachId !== undefined) {
      aid = stage
        .find('.target')
        .find((el) => el.getAttr('attachIds').indexOf(attachId) >= 0);
    }
    const attachSelectId = aid ? aid._id : 'x';
    setLayoutPositioning(attachSelectId === 'x' ? 'absolute' : 'relative');
  }, [props.group, props.refreshToggle]);

  const setPropertyValue = (setter, value) => {
    setter(value);
    propertyChanged();
  };

  const changePositionX = (value) => {
    const val = parseInt(value);
    if (isNaN(val)) {
      return;
    }

    const parentPosition = getParentPosition(group);
    group.x(
      val + group.getStage().findOne('#canvas_bg').x() - parentPosition.x,
    );
    refreshParentGroup(group);
    setPropertyValue(setPositionX, val);
  };

  const changePositionY = (value) => {
    const val = parseInt(value);
    if (isNaN(val)) {
      return;
    }
    const parentPosition = getParentPosition(group);
    group.y(
      val + props.group.getStage().findOne('#canvas_bg').y() - parentPosition.y,
    );
    refreshParentGroup(group);
    setPropertyValue(setPositionY, val);
  };

  const changeDistribute = (val) => {
    if (val) {
      props.distributeGroup(
        props.group,
        distributeDirection,
        distributeAlign,
        distributeOffset,
      );
    } else {
      props.destroyDistributedGroup(props.group);
    }
    setPropertyValue(setDistribute, val);
  };

  const changeDistributeDirection = (val) => {
    if (distribute) {
      props.distributeGroup(
        props.group,
        val,
        distributeAlign,
        distributeOffset,
      );
    } else {
      props.destroyDistributedGroup(props.group);
    }
    setPropertyValue(setDistributeDirection, val);
  };

  const changeDistributeAlign = (val) => {
    if (distribute) {
      props.distributeGroup(
        props.group,
        distributeDirection,
        val,
        distributeOffset,
      );
    } else {
      props.destroyDistributedGroup(props.group);
    }
    setPropertyValue(setDistributeAlign, val);
  };

  const changeDistributeOffset = (value) => {
    const val = parseInt(value);
    if (isNaN(val)) {
      return;
    }

    if (distribute) {
      props.distributeGroup(
        props.group,
        distributeDirection,
        distributeAlign,
        val,
      );
    } else {
      props.destroyDistributedGroup(props.group);
    }
    setPropertyValue(setDistributeOffset, val);
  };

  const changeCancel = useRef();
  const debouncedWidthFn = _.debounce((value) => {
    if (changeCancel.current) {
      return;
    }
    changeWidthFunc(value);
  }, 1000);

  const debouncedHeightFn = _.debounce((value) => {
    if (changeCancel.current) {
      return;
    }
    changeHeightFunc(value);
  }, 1000);

  const changeWidth = (value) => {
    changeCancel.current = true;
    const val = parseInt(value);
    setInputGroupWidth(val ?? 0);

    if (isNaN(val)) {
      return;
    }

    if (val === 0 || val < minGroupWidth) {
      return;
    }

    setGroupWidth(parseInt(val));

    const groupClientRect = props.group.getClientRect();

    if (groupClientRect.width / val < 5) {
      changeWidthFunc(val);
    } else {
      changeCancel.current = false;
      debouncedWidthFn(value);
    }
  };

  const changeHeight = (value) => {
    changeCancel.current = true;
    const val = parseInt(value);
    setInputGroupHeight(val ?? 0);

    if (isNaN(val)) {
      return;
    }

    if (val === 0 || val < minGroupHeight) {
      return;
    }

    setGroupHeight(parseInt(val));

    const groupClientRect = props.group.getClientRect();
    if (groupClientRect.height / val < 5) {
      changeHeightFunc(val);
    } else {
      changeCancel.current = false;
      debouncedHeightFn(value);
    }
  };

  const changeWidthFunc = (val) => {
    const groupClientRect = props.group.getClientRect();
    const oldWidth = groupClientRect.width ?? 1;

    scaleGroup(group, { x: val / oldWidth, y: 1 });
    refreshTransform(props.group);
  };

  const changeHeightFunc = (val) => {
    const groupClientRect = props.group.getClientRect();
    const oldHeight = groupClientRect.height ?? 1;

    scaleGroup(group, { x: 1, y: val / oldHeight });
    refreshTransform(props.group);
  };

  const scaleGroup = (group, scale) => {
    // duplicate from GraphicEditorApp.jsx
    if (group.getAttr('elementType') === 'group') {
      const newScale = scale ?? group.scale();
      for (const child of group.children) {
        child.position({
          x: child.x() * newScale.x,
          y: child.y() * newScale.y,
        });
        if (['text', 'image'].includes(child.getAttr('elementType'))) {
          child.scale(newScale);
        } else if (child.getAttr('elementType') !== 'group') {
          child.scale({
            x: child.scaleX() * newScale.x,
            y: child.scaleY() * newScale.y,
          });
        }
        switchScale(child, newScale);
      }
      group.scale({ x: 1, y: 1 });
    }
  };

  const switchScale = (target, scale) => {
    switch (target.getAttr('elementType')) {
      case 'text':
        scaleText(target.findOne('.main'));
        break;
      case 'image':
        scaleImage(target.findOne('.main'));
        break;
      case 'group':
        scaleGroup(target, scale);
        break;
      default:
        break;
    }
  };

  const propertyChanged = () => {
    if (props.onPropertyChanged) {
      props.onPropertyChanged();
    }
  };

  const widthStyle = {
    width: '100%',
    color: inputGroupWidth >= minGroupWidth ? 'black' : 'red',
  };

  const heightStyle = {
    width: '100%',
    color: inputGroupHeight >= minGroupHeight ? 'black' : 'red',
  };

  const changeDistributeEnable = () => {
    changeDistribute(!distribute);
    updateDistributeState(!distribute);
  };

  const changeDistributeOffsetEnable = () => {
    setDistributeOffsetEnabled(!isDistributeOffsetEnabled);
    updateDistributeOffsetState(!isDistributeOffsetEnabled);
  };

  const updateDistributeState = (isEnabled) => {
    const element = props.group;
    if (!isEnabled) {
      element.setAttr('_lastStateDistribute', {
        distribute,
        distributeDirection,
        distributeAlign,
      });
      if (isDistributeOffsetEnabled) {
        element.setAttr('_lastStateDistributeOffset', { distributeOffset });
      }

      props.destroyDistributedGroup(element);
    } else {
      const state = element.getAttr('_lastStateDistribute');
      if (state) {
        const offSetState = element.getAttr('_lastStateDistributeOffset');

        props.distributeGroup(
          element,
          state.distributeDirection,
          state.distributeAlign,
          isDistributeOffsetEnabled && offSetState?.distributeOffset
            ? offSetState?.distributeOffset
            : 0,
        );

        setPropertyValue(setDistribute, state.distribute);
        setPropertyValue(setDistributeDirection, state.distributeDirection);
        setPropertyValue(setDistributeAlign, state.distributeAlign);
      }
    }
  };

  const updateDistributeOffsetState = (isEnabled) => {
    const element = props.group;
    if (!isEnabled) {
      element.setAttr('_lastStateDistributeOffset', { distributeOffset });
      changeDistributeOffset(0);
    } else {
      const state = element.getAttr('_lastStateDistributeOffset');
      if (state) {
        changeDistributeOffset(state.distributeOffset);
      }
    }
  };

  return (
    <div>
      <div
        className={`${globalStyles.bottomBorderDivider} ${globalStyles.bottomBigPaddings}  ${globalStyles.selectBlock} ${globalStyles.bigGapBlock}`}
      >
        <div className={myStyles.positionWrapper}>
          <div className={`${globalStyles.selectBlock} ${myStyles.bigGap}`}>
            <div
              className={`${globalStyles.spaceBetweenBlock} ${myStyles.smallGap}`}
            >
              <Text className={globalStyles.text}>X</Text>
              <InputNumber
                className={`${globalStyles.input} ${myStyles.input}`}
                size='small'
                disabled={layoutPositioning !== 'absolute'}
                value={positionX}
                onChange={changePositionX}
              />
            </div>
            <div
              className={`${globalStyles.spaceBetweenBlock} ${myStyles.smallGap}`}
            >
              <Text className={globalStyles.text}>Y</Text>
              <InputNumber
                className={`${globalStyles.input} ${myStyles.input}`}
                size='small'
                disabled={layoutPositioning !== 'absolute'}
                value={positionY}
                onChange={changePositionY}
              />
            </div>
          </div>

          <div className={`${globalStyles.selectBlock} ${myStyles.bigGap}`}>
            <div
              className={`${globalStyles.spaceBetweenBlock} ${myStyles.smallGap}`}
            >
              <Text className={globalStyles.text}>W</Text>
              <InputNumber
                className={`${globalStyles.input} ${myStyles.input}`}
                size='small'
                value={groupWidth}
                onChange={changeWidth}
              />
            </div>
            <div
              className={`${globalStyles.spaceBetweenBlock} ${myStyles.smallGap}`}
            >
              <Text className={globalStyles.text}>H</Text>
              <InputNumber
                className={`${globalStyles.input} ${myStyles.input}`}
                size='small'
                value={groupHeight}
                onChange={changeHeight}
              />
            </div>
          </div>
        </div>
        <PositioningControl
          group={props.group}
          attachToElement={props.attachToElement}
          detachElement={props.detachElement}
          refreshToggle={props.refreshToggle}
          setLayoutPositioning={setLayoutPositioning}
          onPropertyChanged={propertyChanged}
        />
      </div>

      <div className={globalStyles.bottomBorderDivider}>
        <div className={globalStyles.spaceBetweenBlock}>
          <Text className={globalStyles.smallTitle}>Distribution</Text>

          <Button
            shape='circle'
            type='text'
            icon={distribute ? <MinusIcon /> : <PlusIcon />}
            onClick={changeDistributeEnable}
          />
        </div>
        {distribute && (
          <div className={globalStyles.innerBlock}>
            <div className={`${globalStyles.selectBlock} ${myStyles.smallGap}`}>
              <div className={globalStyles.spaceBetweenBlock}>
                <Text className={globalStyles.text}>Direction:</Text>
                <div className={myStyles.selectWrapper}>
                  <SelectControl
                    dataSource={distributionDirectionsDataSource}
                    onChange={changeDistributeDirection}
                    value={distributeDirection}
                  />
                </div>
              </div>

              <div className={globalStyles.spaceBetweenBlock}>
                <Text className={globalStyles.text}>Align:</Text>
                <div className={myStyles.selectWrapper}>
                  <SelectControl
                    dataSource={
                      ['left', 'right'].indexOf(distributeDirection) >= 0
                        ? distributionAlignVerticalDataSource
                        : distributionAlignHorizontalDataSource
                    }
                    onChange={changeDistributeAlign}
                    value={distributeAlign}
                  />
                </div>
              </div>
            </div>
            <div className={globalStyles.innerBlock}>
              <div className={globalStyles.spaceBetweenBlock}>
                <Text className={globalStyles.smallTitle}>Offset</Text>
                <Button
                  shape='circle'
                  type='text'
                  icon={
                    isDistributeOffsetEnabled ? <MinusIcon /> : <PlusIcon />
                  }
                  onClick={changeDistributeOffsetEnable}
                />
              </div>
              {isDistributeOffsetEnabled && (
                <div className={globalStyles.innerBlock}>
                  <div className={globalStyles.spaceBetweenBlock}>
                    <Text className={globalStyles.text}>Offset settings:</Text>
                    <InputNumber
                      prefix={<ColumnWidthOutlined />}
                      size='small'
                      value={distributeOffset}
                      onChange={changeDistributeOffset}
                      className={globalStyles.input}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
