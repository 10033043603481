import { Checkbox, Button } from 'antd';
import React, { useState, useEffect, Fragment, useMemo, useLayoutEffect } from 'react';

import { shortenString } from '../../../helpers';
import { ReactComponent as DownIcon } from '../../../svg/icon/down-arrow.svg';

import styles from '../AppSidebar.module.css'

export const NestedCheckboxes = ({organizationsWithFilteredProjects, onProjectsByOrganizationsCheck}) => {
  const [selectAll, setSelectAll] = useState(true)
  const [selectedOrganizations, setSelectedOrganizations] = useState([])
  const [selectedProjects, setSelectedProjects] = useState([])
  const [dropdownState, setDropdownState] = useState([])
  const [, setIsLoading] = useState(true)

  const cachedOrganizations = useMemo(() => organizationsWithFilteredProjects.map(organization => organization.id), [organizationsWithFilteredProjects])
  const cachedProjects = useMemo(() => organizationsWithFilteredProjects.flatMap(organization => organization.projects_filtered_by_id.map(project => project.id)), [organizationsWithFilteredProjects])

  const selectAllChange = () => {
    if (selectedOrganizations.length !== organizationsWithFilteredProjects.length && organizationsWithFilteredProjects) {
      setSelectedOrganizations(cachedOrganizations)
    } else if (!selectAll) {
      setSelectedOrganizations([])
      setSelectedProjects([])
      return;
    }
    setSelectedProjects(cachedProjects)
  }

  const toggleOrganization = (id) => {
    const projects = organizationsWithFilteredProjects
                      .find(organization => organization.id === id).projects_filtered_by_id
                      .map(project => project.id)

    setSelectedOrganizations(prev => {
      if (prev.includes(id)) {
        setSelectedProjects(prev => prev.filter(p => !projects.includes(p)))
        return prev.filter(p => p !== id)
      }

      setSelectedProjects(prev => [...prev, ...projects])
      return [...prev, id]
    })
  }

  const toggleProject = (organizationId, projectId) => {
    const isOrganizationChecked = selectedOrganizations.includes(organizationId)
    const isProjectChecked = selectedProjects.includes(projectId)

    let newSelectedSnapshot

    if(isProjectChecked) {
      newSelectedSnapshot = selectedProjects.filter(p => p !== projectId)
      setSelectedProjects(newSelectedSnapshot)
    } else {
      newSelectedSnapshot = [...selectedProjects, projectId]
      setSelectedProjects(newSelectedSnapshot)
    }

    if(isOrganizationChecked) {
      setSelectedOrganizations(prev => prev.filter(p => p !== organizationId))
    } else {
      setSelectedOrganizations(prev => [...prev, organizationId])
    }

    const isAllProjectsInOrganizationChecked = organizationsWithFilteredProjects
                                  .find(organization => organization.id === organizationId).projects_filtered_by_id
                                  .filter(project => newSelectedSnapshot.includes(project.id)).length

    if(isAllProjectsInOrganizationChecked) {
      setSelectedOrganizations(prev => [...prev, organizationId])
    } else {
      setSelectedOrganizations(prev => prev.filter(p => p !== organizationId))
    }
  }

  useEffect(() => {
    selectAllChange()
    setDropdownState(cachedOrganizations)
  }, [])

  useEffect(() => {
    selectAllChange()
  }, [selectAll])

  useLayoutEffect(() => {
    if(selectedOrganizations.length !== 0 && selectedProjects.length !== 0) {
      setIsLoading(false)
    }
  }, [selectedOrganizations, selectedProjects])

  useEffect(() => {
    const list = selectedOrganizations.map(organizationId => {
      const projects = organizationsWithFilteredProjects
        .find(organization => organization.id === organizationId)
        .projects_filtered_by_id
        .map(project => project.id)

      return {
        id: organizationId,
        projects: selectedProjects.filter(p => projects.includes(p))
      }
    })

    onProjectsByOrganizationsCheck(list)
  }, [selectedOrganizations, selectedProjects])

  return (
    <>
      <div className={styles.organizationsCheckboxesGroup}>
        <Checkbox
          onChange={() => setSelectAll(!selectAll)}
          checked={selectedOrganizations.length === organizationsWithFilteredProjects.length}
        >
          All
        </Checkbox>
        <div className={styles.checkboxesBlock}>
          {
            organizationsWithFilteredProjects
              .sort((a, b) => b.projects_filtered_by_id.length - a.projects_filtered_by_id.length)
              .map((organization) => {
                return (
                  <Fragment key={organization.id}>
                    <div className={styles.organizationsCheckbox}>
                      <Checkbox
                        onChange={() => toggleOrganization(organization.id)}
                        checked={selectedOrganizations.includes(organization.id)}
                        disabled={organization.projects_filtered_by_id.length === 0}
                      >
                        {shortenString(organization.name, 25)}
                      </Checkbox>
                      <Button
                        disabled={organization.projects_filtered_by_id.length === 0}
                        className={styles.dropdownButton}
                        icon={
                          <DownIcon
                            className={styles.dropdownIcon}
                            style={{transform: dropdownState.includes(organization.id) ? 'rotate(0deg)' : 'rotate(180deg)'}}
                          />
                        }
                        onClick={(e) => {
                          e.stopPropagation()

                          setDropdownState(prev => {
                            if(prev.includes(organization.id)) {
                              return prev.filter(p => p !== organization.id)
                            } else {
                              return [...prev, organization.id]
                            }
                          })
                        }}
                      />
                    </div>
                    {organization.projects_filtered_by_id.map((project) => {
                      return (
                        <Checkbox.Group
                          value={selectedProjects}
                          key={project.id}
                          style={{display: dropdownState.includes(organization.id) ? 'block' : 'none'}}
                        >
                          <div className={styles.projectsCheckboxes}>
                            <Checkbox onChange={() => toggleProject(organization.id, project.id)} value={project.id}>
                              {shortenString(project.name, 21)}
                            </Checkbox>
                          </div>
                      </Checkbox.Group>
                    )})}
                  </Fragment>
                )
              })
          }
        </div>
      </div>
    </>
  )
}
