import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Popover, Spin, Typography} from "antd";
import {ReactComponent as DownIcon} from "../../../../svg/icon/down-arrow.svg";

import styles from "../UserManagementTable/UserManagementTable.module.css";

const {Text} = Typography;

const CheckBoxesForTable = ({
                                projects,
                                record,
                                rowData,
                                onProjectClick,
                                isProjectsOpen,
                                isAllowed,
                                loading
                            }) => {

    const [myCheckedList, setMyCheckedList] = useState([]);

    const projectsWithAccess = projects.map(project => ({
        ...project,
        access: record && record.some(userProject => userProject.id === project.id)
    }));

    useEffect(() => {
        const defaultProjects = projectsWithAccess.reduce((acc, project) => {
            if (project.access) {
                acc.push(project.id);
            }
            return acc;
        }, []);
        setMyCheckedList(defaultProjects)
    }, [record]);
    const onProjectCheck = (list) => {
        setMyCheckedList(list);
    };

    const emptyProjectsLists = () => {
        setMyCheckedList([]);
    };

    const renderCheckBoxes = (defaultProjects, user) => {
        const tableButton = document.getElementById(user.id);
        const tableButtonWidth = tableButton ? tableButton.offsetWidth : 50;

        return (
            <Checkbox.Group
                onChange={onProjectCheck}
                value={myCheckedList}
            >
                <div className={styles.accessCheckbox} style={{width: `${tableButtonWidth - 12}px`}}>
                    {!!projects.length && projects.map((project) => (
                        <Checkbox
                            key={project.id}
                            value={project.id}
                        >
                            {project.name}
                        </Checkbox>
                    ))}
                </div>
            </Checkbox.Group>
        )
    };


    const accessForAllProjects = projectsWithAccess.length > 0 && projectsWithAccess.every(project => project.access);
    return (
      loading === rowData.id ?
        <div className='loading-container'>
            <Spin size='small'/>
        </div> :
        <Popover
            content={() => renderCheckBoxes( myCheckedList, rowData)}
            trigger="click"
            placement='bottom'
            arrow={false}
            onOpenChange={(open) => onProjectClick(open, rowData, myCheckedList, emptyProjectsLists)}
        >
            <Button id={rowData.id} type='text' className={styles.accessButton} disabled={isAllowed}>
                {accessForAllProjects ?
                    <>All Projects</> :
                    <Text>
                        {record && !!record.length ?
                            record.map((data, index) => (
                                    <span key={data.id}>
                                                    {data.name}
                                        {index !== record.length - 1 && ', '}
                                                </span>
                                )
                            ) : 'No projects'
                        }
                    </Text>}
                <div>
                    <DownIcon
                        className={`
                            ${styles.selectIcon}
                            ${isProjectsOpen === rowData.key && styles.activeSelectIcon}`
                        }
                    />
                </div>
            </Button>
        </Popover>
    );
};

export default CheckBoxesForTable;
