export const setMenuPosition = (stage, targetElement, backgroundElement, leftSidebarWidth) => {
	const defaultWidth = 200;
	const menuNode = document.getElementById('menu');


	if (!targetElement || !backgroundElement) {
		menuNode.style.display = 'none';
		return;
	}

	const foundStage = targetElement.parent.parent;
	const elementType = targetElement.attrs.elementType;
	const scaleX =  !stage ? foundStage.attrs.scaleX :  stage.scaleX();

	let elementWidth;

	if (elementType === 'ellipse' || elementType === 'polygon' || elementType === 'star' || elementType === 'line' ) {
		elementWidth = (backgroundElement.parent.attrs.scaleX || 1)  * defaultWidth;

	} else {
    const group = backgroundElement.getParent();
    const extraDimension = group?.getAttr('extraDimension');
    if (elementType === 'image' && group && extraDimension) {
      elementWidth = extraDimension.width;
    } else {
      elementWidth = backgroundElement.width();
    }
	}

	const positions = {
		x: targetElement.absolutePosition().x + leftSidebarWidth + elementWidth * (scaleX || 1),
		y: targetElement.absolutePosition().y,
	};

	menuNode.style.display = 'initial';
	menuNode.style.left = `${positions.x}px`;
	menuNode.style.top = `${positions.y}px`;
};