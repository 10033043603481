import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  message,
  Switch,
  Tooltip,
  Typography,
  Popconfirm,
} from 'antd';

import { convertToArrayOfStrings, formatNumbers } from '../../../helpers';
import { COLORS, GenerationStatus, Roles } from '../../../constants';
import { runProject, stopProject, updateProject } from '../ProjectsApi/projectsApi';
import EditableOrganizationName from '../../EditableOrganizationName/EditableOrganizationName';
import ProjectOutputCard from '../ProjectCard/ProjectOutputCard';
import MyButton from '../../shared/MyButton/MyButton';
import CustomPopConfirm from '../../shared/CustomPopConfirm/CustomPopConfirm';
import { ProjectsReport } from '../ProjectsReports/ProjectsReport';
import { ValidationLogs } from '../ProjectsReports/ValidationLogs';
import { useWebSocket } from '../../../contexts/SocketContext';
import {
	StatusBlock,
	isProjectButtonsDisabled,
	isProjectRunning,
	isProjectOrOutputStopping,
} from '../StatusBlock/StatusBlock';
import { ReactComponent as ArrowIcon } from 'svg/icon/arrow.svg';
import { ReactComponent as RunProjectIcon } from 'svg/icon/run-project.svg';
import { ReactComponent as StopProjectIcon } from 'svg/icon/stop-project.svg'
import { ReactComponent as RerunProjectIcon } from 'svg/rerun-icon.svg';
import { ReactComponent as TrashIcon } from 'svg/icon/trash-red-big.svg';
import { ReactComponent as ExportIcon } from 'svg/icon/export-violet.svg';
import { ReactComponent as CopyIcon } from 'svg/icon/copy-project.svg';

import styles from './ProjectBlock.module.css';

const { Text } = Typography;
const { grayBackground, disabledViolet } = COLORS;

const buttonStyles = {
  width: '178px',
  height: '40px',
};

const ProjectBlock = ({
  project,
  organization,
  onAddingProjectFinish,
  cancelProjectCreating,
  onProjectDelete,
  getOneProjectByOrganization,
  isAllowed,
  role,
  isSuperAdmin,
  isCreating = false,
  organizationId = null
}) => {
  const [selectedHours, setSelectedHours] = useState([]);
  const [enabled, setEnabled] = useState(
    project?.feed?.schedule?.enabled || false,
  );
  const [enabledLoading, setEnabledLoading] = useState(false);
  const [projectName, setProjectName] = useState(project?.name || '');
  const [feedUrl, setFeedUrl] = useState(project?.feed?.feed_url || '');
  const [loadingProject, setLoadingProject] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
	const [projectRunLoading, setProjectRunLoading] = useState(false);
  const initialSku = project?.feed?.valid_count || 0;
  const hours = Array.from({ length: 24 }, (_, index) => index.toString());
  const { data: socketData } = useWebSocket();
  const [projectStatus, setProjectStatus] = useState({
    outputs: {},
  });

  useEffect(() => {
    if (project) {
      let projectStatus = project.status;
      let projectProgress = project.status === 'D' ? '100' : null;

      if (socketData[project.id]) {
        projectStatus = socketData[project.id].status;
        projectProgress = socketData[project.id].total;
      }

      const outputs = project.outputs.reduce((acc, curr) => {
        let status = curr.status;
        let progress = null;

        if (socketData[project.id]?.['outputs'][curr.id]) {
          status = socketData[project.id]['outputs'][curr.id].status;
          progress = socketData[project.id]['outputs'][curr.id].progress;
        }

        return {
          ...acc,
          [curr.id]: {
            ...curr,
            status: status,
            progress: progress,
          },
        };
      }, {});

      setProjectStatus({
        status: projectStatus,
        progress: projectProgress,
        outputs: outputs,
      });
    }
  }, [project, socketData]);

  useEffect(() => {
    if (project?.feed?.schedule?.value) {
      const parseCronFormat = (cronValue) => {
        const startIndex = cronValue.indexOf(' ') + 1;
        const endIndex = cronValue.indexOf('*');
        const hours = cronValue.substring(startIndex, endIndex).trim();
        return convertToArrayOfStrings(hours);
      };

      setSelectedHours(parseCronFormat(project?.feed?.schedule?.value));
    }
  }, [project?.feed?.schedule?.value]);

  useEffect(() => {
    if (!isCreating) {
      setEnabled(project?.feed?.schedule?.enabled);
    }
  }, [project?.feed]);

	useEffect(() => {
		const updateProject = async () => {
			await getOneProjectByOrganization(organization.id, project.id);
		}
		if (projectStatus.status === GenerationStatus.PARSED && project.status === GenerationStatus.PARSING) {
			void updateProject();
		}
	}, [projectStatus?.status, project?.status]);

  const handleHourClick = (hour) => {
    const isHourSelected = selectedHours.includes(hour);
    let updatedHours;

    if (isHourSelected) {
      if (isSuperAdmin && selectedHours.length > 1) {
        updatedHours = selectedHours.filter(
          (selectedHour) => selectedHour !== hour,
        );
      }
    } else {
      updatedHours = isSuperAdmin ? [...selectedHours, hour] : [hour];
    }

    if (updatedHours) {
      setSelectedHours(updatedHours);
      return updatedHours;
    }
    return null;
  };

  const updateHoursSchedule = async (hour) => {
    if (!enabled) return;

    const updatedHours = handleHourClick(hour);

    if (isCreating || (!isCreating && !project) || !updatedHours) return;

    const result = await updateProject(project?.id, {
      schedule: {
        minute: '0',
        hour: updatedHours.join(','),
        day_of_week: '*',
        day_of_month: '*',
        month_of_year: '*',
      },
      enabled: true,
    });
    if (!result) return;

    await getOneProjectByOrganization(organization.id, project.id);

    message.success({
      content: `Schedule for project ${project.name} updated`,
      duration: 3,
    });
  };

  const checkIfHourSelected = (givenHour) => {
    if (!givenHour) return false;
    return selectedHours.some((hour) => givenHour === hour);
  };

  const handleScheduleEnable = async () => {
    if (isCreating || !project?.feed?.schedule) {
      setEnabled(!enabled);
      return;
    }

    try {
      setEnabledLoading(true);
      const bodyRequest = {
        enabled: !enabled,
      };

      if (!enabled && selectedHours) {
        bodyRequest.schedule = {
          minute: '0',
          hour: selectedHours.join(','),
          day_of_week: '*',
          day_of_month: '*',
          month_of_year: '*',
        };
      }
      await updateProject(project?.id, bodyRequest);
      await getOneProjectByOrganization(organization.id, project.id);
    } finally {
      setEnabledLoading(false);
    }
  };

  const goToProject = () => {
    const projectId = project?.id;
    const ruleId = project?.rule ? project?.rule?.id : 'new';
    const organizationQuery = organizationId ? `?organization=${organizationId}` : '';
    return `/project/${projectId}/rule/${ruleId}${organizationQuery}`
  };

  const submitProjectNameChange = async (values) => {
    if (!isCreating) {
      const result = await updateProject(project.id, {
        name: values.organizationName,
      });
      await getOneProjectByOrganization(organization.id, project.id);
      if (!result) return;
      message.success({
        content: 'Project name updated',
        duration: 3,
      });
    }
  };

  const submitFeedUrl = async () => {
    try {
      setLoadingProject(true);
      if (isCreating) {
        if (projectName.length === 0) {
          message.error({
            content:
              'Project name is required! Please enter the name for your new project',
            duration: 3,
          });
        }

        if (projectName && feedUrl && organization) {
          const requestBody = {
            name: projectName,
            feed: {
              feed_url: feedUrl,
            },
            organization: organization.id,
            enabled: false,
          };

          if (selectedHours && enabled) {
            requestBody.schedule = {
              minute: '0',
              hour: selectedHours.join(','),
              day_of_week: '*',
              day_of_month: '*',
              month_of_year: '*',
            };
            requestBody.enabled = true;
          }
          await onAddingProjectFinish(requestBody);
        }
      }
      if (!isCreating && feedUrl !== project?.feed?.feed_url) {
        const result = await updateProject(project.id, {
          feed: { feed_url: feedUrl },
        });
        await getOneProjectByOrganization(organization.id, project.id);
        if (!result) return;
        message.success({
          content: `Feed url updated to ${feedUrl}`,
          duration: 3,
        });
      }
      if (!isCreating && feedUrl === project?.feed?.feed_url) {
        await navigator.clipboard.writeText(feedUrl);
        message.success({
          content: `Feed url ${feedUrl} copied`,
          duration: 3,
        });
      }
    } finally {
      setLoadingProject(false);
    }
  };

  const getOutputStatusMessage = (status) => {
    let outputsStatus;
    switch (status) {
      case 'D':
        outputsStatus = 'Ready!';
        break;
      case 'P':
        outputsStatus = 'Creating...';
        break;
      case 'E':
        outputsStatus = 'Error!';
        break;
      default:
        outputsStatus = 'Error!';
    }
    return outputsStatus;
  };

  const projectOutputs = project?.outputs?.map((output) => ({
    ...output,
    status: getOutputStatusMessage(output.status),
  }));

  const onRunProjectClick = async () => {
	  setProjectRunLoading(true);
    const resultRunProject = await runProject(project.id).finally(() => setProjectRunLoading(false));

    if (!resultRunProject) return;

    await getOneProjectByOrganization(organization.id, project.id);
    message.success('Project Started!');
  };

  const onRerunProjectClick = async () => {
    const resultRerunProject = await runProject(project.id, [], true);

    if (!resultRerunProject) return;

    await getOneProjectByOrganization(organization.id, project.id);
    message.success('Project Started!');
  };

	const isRunAllowed = !isAllowed || isProjectButtonsDisabled(projectStatus.status);
	const isRunning = isProjectRunning(projectStatus.status);
	const isDeleteAllowed = isCreating  ? false : isRunAllowed ? true : !isAllowed;
	const isStopping = isProjectOrOutputStopping(projectStatus);

  const getHourButtonsStyle = (isHoursSelected) => {
    const disabled = {
        disabled: true,
        background: grayBackground,
        cursor: 'not-allowed'
    };
    const active = {
      disabled: false,
      background: '',
      cursor: ''
    };
    if ((isRunning || isRunAllowed) && !isCreating) return disabled;
    if (enabled) return active;
    if (!(isCreating && isHoursSelected && isAllowed && project && project.feed.status === 'P')) {
      return disabled;
    }
    return active;
  };

  return (
        <div className='inner-custom-container'>
            <div className={styles.topBlock}>
                <EditableOrganizationName
                    value={projectName}
                    requiredMessage="Field is required!"
                    onChange={(event => setProjectName(event.target.value))}
                    onSubmit={submitProjectNameChange}
                    disabled={isCreating ? false : (!isAllowed || isRunning)}
                    isInputOpen={isCreating}
                    placeholder='Enter the project name'
                />
                <div className={styles.buttonsBlock}>
                    {!isCreating && <div className={styles.bigButtons}>
                        {
                          role !== Roles.VIEWER && (
                            <>
                                {isRunning ?
                                    <MyButton
                                        customStyles={{ ...buttonStyles }}
                                        onClick={() => stopProject(project.id)}
                                        className={`${styles.rerunButton}`}
                                        disabled={isStopping}
                                    >
                                        <div className={styles.buttonBlock}>
                                            Stop project <StopProjectIcon/>
                                        </div>
                                    </MyButton> :
                                    <>
                                        <Popconfirm
                                            title={'Are you sure you want to renew this feed? This will reset current progress.'}
                                            onConfirm={onRerunProjectClick}
                                            okText="Renew"
                                            cancelText="Cancel"
                                            placement="bottomRight"
                                        >
                                            <MyButton
                                                customStyles={{ ...buttonStyles }}
                                                disabled={isRunAllowed}
                                                className={`${styles.rerunButton}`}
                                                tooltipTitle='Renew will erase product data and the input will be parsed from the very start'
                                            >
                                                <div className={styles.buttonBlock}>
                                                    Renew project <RerunProjectIcon/>
                                                </div>
                                            </MyButton>
                                        </Popconfirm>
                                        <MyButton
                                            customStyles={{
                                                ...buttonStyles,
                                                background: !isAllowed ? disabledViolet : ''
                                            }}
                                            disabled={isRunAllowed}
                                            onClick={onRunProjectClick}
                                            className={styles.disabledButton}
                                            loading={projectRunLoading}
                                        >
                                            <div className={styles.buttonBlock}>
                                                Run project <RunProjectIcon/>
                                            </div>
                                        </MyButton>
                                    </>
                                }
                                <div className={styles.separator}/>
                            </>
                          )
                        }
                        <Button
                          disabled={isCreating}
                          href={goToProject()}
                          className={`${styles.goToProjectButton} ${styles.buttonBlock}`}
                        >
                          Go to project
                          <ArrowIcon/>
                        </Button>
                    </div>}
                    {
                        (role === Roles.ADMIN && isAllowed) || isCreating ? (
                            <CustomPopConfirm
                                title={`Are you sure you want to ${isCreating ? 'cancel project creation' : 'delete this project'}?`}
                                okText={isCreating ? 'Yes' : 'Delete'}
                                cancelText={isCreating ? 'No' : 'Cancel'}
                                onConfirm={() => isCreating ? cancelProjectCreating() : onProjectDelete(project?.id, project?.name)}
                                placement='topLeft'
                            >
                                <Button
                                    shape='circle'
                                    type='text'
                                    className={`${styles.circleButton}`}
                                    icon={<TrashIcon/>}
                                    disabled={isDeleteAllowed}
                                />
                            </CustomPopConfirm>
                        ) : null
                    }
                </div>
            </div>
            <div className={styles.centerBlock}>
                <div className={styles.projectInfoBlock}>

                    <Form
                        onFinish={submitFeedUrl}
                        initialValues={{feedUrl}}
                        className={styles.projectNameBlock}
                    >
                        <Form.Item
                            name="feedUrl"
                            rules={[{type: 'url', required: true, message: 'Enter valid feed url'}]}
                            className={styles.inputWith}
                        >
                            <Input
                                name="feedUrl"
                                className={`${styles.projectNameInput} ${styles.inputWith}`}
                                placeholder='Pass feed url'
                                onChange={(event => setFeedUrl(event.target.value))}
                                disabled={!isCreating && (!isAllowed || project?.feed.status === 'P' || isRunning)}
                            />
                        </Form.Item>
                        <Form.Item>
                            {role !== Roles.VIEWER &&
                                <Tooltip
                                    title={isCreating ? 'Validate feed' : feedUrl !== project.feed?.feed_url ? 'Accept updated feed url' : 'Copy feed url'}>
                                    <Button
                                        htmlType={'submit'}
                                        shape='circle'
                                        type='text'
                                        className={styles.exportButton}
                                        icon={feedUrl === project?.feed?.feed_url ? <CopyIcon/> : <ExportIcon/>}
                                        loading={loadingProject}
                                    />
                                </Tooltip>
                            }
                        </Form.Item>
                    </Form>
                    <StatusBlock type='Project' status={projectStatus.status} progress={projectStatus.progress} />
                    {!isCreating &&
                        <Text className={styles.totalItems}>
                            {initialSku ? formatNumbers(initialSku) : 0} Total items
                        </Text>
                    }
                </div>
                <div className={styles.scheduleBlock}>
                     <div className={styles.scheduleSwitchBlock}>
                        <Text className={styles.scheduleTitle}>Run schedule</Text>
                        <Switch
                          checked={enabled}
                          onChange={handleScheduleEnable}
                          disabled={role === Roles.VIEWER || (!isCreating  && isRunAllowed)}
                          loading={enabledLoading}
                        />
                    </div>
                    <div className={styles.scheduleInnerBlock}>
	                    {hours.map((hour, index) => {
		                    const isHoursSelected = checkIfHourSelected(hour);
                        const styleStatus = getHourButtonsStyle(isHoursSelected);
		                    return (
			                    <Button
				                    disabled={styleStatus.disabled}
				                    key={index}
				                    onClick={() => updateHoursSchedule(hour)}
				                    className={`${styles.hourBox} ${isHoursSelected && enabled ? styles.activeHourBox : ''}`}
				                    style={{
					                    backgroundColor: styleStatus.background,
					                    cursor: styleStatus.cursor,
				                    }}
			                    >
				                    {hour}
			                    </Button>
		                    );
	                    })}
                    </div>
                    {!isCreating &&
                        <div className={styles.validationBlock}>
                            <MyButton
                                customStyles={{...buttonStyles}}
                                onClick={() => setModalOpen(true)}
                                className={`${styles.validationButton}`}
                            >
                                Validation logs
                            </MyButton>
                        </div>
                    }
                </div>
            </div>
            {!isCreating &&
                <div className={styles.outputsBlock}>
                    {!!projectOutputs.length && projectOutputs.map((output) => (
                        <ProjectOutputCard
                            disabled={!isAllowed}
                            projectId={project.id}
                            key={output.id}
                            output={output}
                            outputStatus={projectStatus.outputs[output.id]}
                            projectStatus={projectStatus.status}
                            feed={project?.feed}
                            outputsAmount={projectOutputs.length}
                        />
                    ))}
                </div>
            }
            <ProjectsReport
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
            >
                 {project?.feed?.validation_logs &&
                   <ValidationLogs logs={project.feed.validation_logs} title='Validation logs'/>
                 }
            </ProjectsReport>
        </div>
    );
};

export default ProjectBlock;
