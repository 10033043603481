import React from 'react';
import {Button, message, Upload} from "antd";
import {ReactComponent as PlusIcon} from '../../../svg/icon/plus.svg';

import styles from './UploadFontButton.module.css';

const UploadFontButton = (props) => {

    const uploadFont = ({onSuccess, onError, file}) => {
        props.uploadFont(file).then(result => {
            if (result) {
                onSuccess(result);
                message.success({
                    content: "Font uploaded!",
                    duration: 3
                });
            } else {
                onError();
            }
        });
    }

    return (
        <Upload
            accept=".ttf"
            fileList={[]}
            multiple={false}
            customRequest={(onSuccess, onError, file) =>
                uploadFont(onSuccess, onError, file)
            }
            style={{width: '100%'}}
            className={styles.uploadButton}
        >
            <Button
                icon={<PlusIcon/>}
                className={styles.button}
                type='text'
                block
            >
                Add font
            </Button>
        </Upload>
    );
};

export default UploadFontButton;