import React, { useState } from 'react';
import { Divider, Form, Input, Row, message, Col, Result, Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import {Link, useHistory, withRouter} from 'react-router-dom';
import MyButton from '../../shared/MyButton/MyButton';
import { TERMS_LINKS } from '../../../constants';
import logo from '../../../svg/common/logo.svg';
import './Login.css';

const RegisterUser = ({ backend }) => {
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [registerFinished, setRegisterFinished] = useState(false);

  const onFinish = async ({
    email,
    first_name,
    last_name,
    password,
    confirmPassword,
    organizationName,
  }) => {
    if (password !== confirmPassword) {
      message.warning('Passwords do not match!');
      return;
    }

    const requestData = {
      username: email,
      first_name,
      last_name,
      email,
      password,
      organization: {
        name: organizationName,
      },
    };
    const result = await backend.register(requestData, setErrors, history);
    setRegisterFinished(result);
  };

  return (
    <Row className='login-container'>
      {registerFinished ? (
        <Result
          icon={<CheckCircleOutlined className='registration-confirm-icon' />}
          title={
            <div>
              <h5>Thank you for registration!</h5>
              <h5>Confirm your email address to get access to the app</h5>
            </div>
          }
          extra={
            <Button size='large' type='primary' htmlType='link' href='/login'>
              login with existing account
            </Button>
          }
        />
      ) : (
        <Col
          className='login-panel'
          sm={{ offset: 2, span: 20 }}
          md={{ offset: 4, span: 18 }}
          lg={{ offset: 6, span: 12 }}
          xl={{ offset: 8, span: 8 }}
          xxl={{ offset: 9, span: 6 }}
        >
          <div className='login-logo-div'>
            <img src={logo} alt='' />
            <span className='login-logo-label'>Register</span>
          </div>
          <Form
            name='basic'
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            requiredMark={false}
            autoComplete='new-password'
          >
            <Form.Item
              autoComplete='off'
              className='login-input-label'
              label='E-mail'
              name='email'
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please input your e-mail!',
                },
              ]}
              validateStatus={errors.email || errors.username ? 'error' : ''}
              help={errors.email || errors.username}
            >
              <Input className='login-input-field registration-input-field' />
            </Form.Item>
            <Form.Item
              autoComplete='off'
              className='login-input-label'
              label='Name'
              name='first_name'
              rules={[
                {
                  required: true,
                  message: 'Please input your name!',
                },
              ]}
            >
              <Input className='login-input-field registration-input-field' />
            </Form.Item>

            <Form.Item
              autoComplete='off'
              className='login-input-label'
              label='Last name'
              name='last_name'
              rules={[
                {
                  required: true,
                  message: 'Please input your last name!',
                },
              ]}
            >
              <Input className='login-input-field' />
            </Form.Item>

            <Form.Item
              autoComplete='off'
              className='login-input-label'
              label='Organization name'
              name='organizationName'
              rules={[
                {
                  required: true,
                  message: "Please input the organization's name!",
                },
              ]}
              validateStatus={errors.organizationName ? 'error' : ''}
              help={errors.organizationName}
            >
              <Input className='login-input-field registration-input-field' />
            </Form.Item>

            <Form.Item
              className='login-input-label'
              label='Password'
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              validateStatus={errors?.password ? 'error' : ''}
              help={errors?.password}
            >
              <Input.Password
                autoComplete='new-password'
                visibilityToggle={false}
                className='login-input-field registration-input-field'
              />
            </Form.Item>

            <Form.Item
              className='login-input-label'
              label='Confirm password'
              name='confirmPassword'
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
              ]}
            >
              <Input.Password
                autoComplete='off'
                visibilityToggle={false}
                className='login-input-field registration-input-field'
              />
            </Form.Item>

            <div className='login-simple-text'>
              By clicking the "Register" button, you accept the terms of{' '}
              {TERMS_LINKS.map((link, index) => (
                <span key={link.href}>
                  <a
                    href={link.href}
                    className='violet-link-register'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {link.text}
                  </a>
                  {index < TERMS_LINKS.length - 2 && ', '}
                  {index === TERMS_LINKS.length - 2 && ' and '}
                </span>
              ))}
            </div>

            <div className='submit-registration-button-block'>
              <Form.Item>
                <MyButton
                  htmlType='submit'
                  className='login-button'
                  text='Register'
                />
              </Form.Item>
            </div>
          </Form>

          <div style={{ marginRight: '-2rem', marginLeft: '-2rem' }}>
            <Divider style={{ margin: '0' }} />
          </div>

          <div className='registration-page-login-link'>
            <Link className='login-link-gray' to='/login'>
              Login
            </Link>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default withRouter(RegisterUser);
