import React, { useEffect, useState } from 'react';
import {Button, Checkbox, Layout, Skeleton, Typography} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import MyButton from '../shared/MyButton/MyButton';
import { shortenString } from '../../helpers';
import { PROJECTS_PATH } from '../../constants';
import { ReactComponent as OrganizationsIcon } from '../../svg/icon/organizations-group.svg';
import { ReactComponent as LogoutIcon } from '../../svg/logout.svg';
import { ReactComponent as ProjectsIcon } from '../../svg/icon/projects-group.svg';
import { ReactComponent as Plus } from '../../svg/icon/white-plus.svg';
import { ReactComponent as DownIcon } from '../../svg/icon/down-arrow.svg';
import { NestedCheckboxes } from './NestedCheckboxes/NestedCheckboxes';

import styles from './AppSidebar.module.css';

const { Sider } = Layout;
const { Text } = Typography;

function AppSidebar({
  organizationsByProjects,
  organizationsWithFilteredProjects,
  organizations,
  selectOrganizationsList,
  selectedOrganizationsList,
  loadingOrganizations,
  setOrganizationsLoading,
  selectProjectsLists,
  user,
  onAddingOrganizationClick,
  onAddingProjectClick,
  logOut,
  children,
}) {
  const history = useHistory();
  const { pathname } = useLocation();

  const [bigSidebarContent, setBigSidebarContent] = useState(null);
  const [checkedOrganizationsList, setCheckedOrganizationsList] = useState([]);
  const [checkedProjectsLists, setCheckedProjectsLists] = useState([]);
  const [logoutButtonDisabled, setLogoutButtonDisabled] = useState(false);
  const [isProjectsListOpen, setIsProjectsListOpen] = useState(true);
  const checkAllOrganizations =
    organizations.length > 0 &&
    organizations.length === checkedOrganizationsList.length;
  const isProjectsPageOpen = PROJECTS_PATH.test(pathname);

  useEffect(() => {
    selectProjectsLists([]);
    selectOrganizationsList([]);
    if (isProjectsPageOpen || pathname.startsWith('/admin')) {
      setBigSidebarContent('organizations');
    } else if (pathname === '/projects-new') {
      setBigSidebarContent('projects-new');
    }
  }, [
    pathname,
    isProjectsPageOpen,
    selectProjectsLists,
  ]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [bigSidebarContent]);

  useEffect(() => {
    if (
      organizations.length &&
      organizationsByProjects.length &&
      !isProjectsPageOpen &&
      bigSidebarContent !== 'projects-new' &&
      selectedOrganizationsList.length === 0
    ) {
      setCheckedOrganizationsList(
        organizations.map((organization) => organization.id),
      );
      selectOrganizationsList(organizationsByProjects, true);
    }
  }, [
    organizations,
    bigSidebarContent,
    isProjectsPageOpen,
    organizationsByProjects,
    selectOrganizationsList,
  ]);

  useEffect(() => {
    if (selectedOrganizationsList.length !== checkedOrganizationsList.length ) {
      setCheckedOrganizationsList(
        selectedOrganizationsList.map((organization) => organization.id),
      )
    }
  }, [selectedOrganizationsList, checkedOrganizationsList.length]);

  useEffect(() => {
    const setProjectListForOneOrganization = async () => {
      try {
        setOrganizationsLoading(true);
        await setCheckedProjectsLists(
          organizationsWithFilteredProjects[0].projects_filtered_by_id.length
            ? organizationsWithFilteredProjects[0].projects_filtered_by_id.map(
              (project) => project.id,
            )
            : [],
        );
        selectProjectsLists(organizationsWithFilteredProjects);
      } finally {
        setOrganizationsLoading(false);
      }
    }
    if (isProjectsPageOpen && organizationsWithFilteredProjects.length) {
      setProjectListForOneOrganization();
    }
  }, [
    isProjectsPageOpen,
    organizationsWithFilteredProjects,
    selectProjectsLists,
  ]);

  useEffect(() => {
    if (
      organizationsWithFilteredProjects.length &&
      bigSidebarContent === 'projects-new'
    ) {
      setCheckedOrganizationsList(
        organizationsWithFilteredProjects.map(
          (organization) => organization.id,
        ),
      );
    }
  }, [organizationsWithFilteredProjects, bigSidebarContent]);
  const getListOfFiltredProjects = (selectedProjects) => {
    const selectedProjectsMap = {};
    selectedProjects.forEach((org) => {
      selectedProjectsMap[org.id] = org.projects;
    });

    return organizationsWithFilteredProjects.reduce((filteredArray, org) => {
      if (selectedProjectsMap.hasOwnProperty(org.id)) {
        const filteredOrg = { ...org };
        filteredOrg.projects_filtered_by_id =
          org.projects_filtered_by_id.filter((project) => {
            return selectedProjectsMap[org.id].includes(project.id);
          });
        if (filteredOrg.projects_filtered_by_id.length) {
          filteredArray.push(filteredOrg);
        }
      }
      return filteredArray;
    }, []);
  };

  const onButtonClick = (content) => {
    setBigSidebarContent(content);
    setCheckedOrganizationsList([]);
    setCheckedProjectsLists([]);
  };

  const pushToPage = (page) => {
    if (page === 'organizations' && pathname === '/admin') return;
    if (page === 'projects-new' && pathname === '/projects-new') return;
    onButtonClick(page);
    history.push(`/${page === 'organizations' ? 'admin' : page}`);
  };

  const filterCheckedList = (commonList, checkedOrganizations) => {
    return commonList.filter((item) => checkedOrganizations.includes(item.id));
  };


  const onOrganizationsCheck = (list) => {
    setCheckedOrganizationsList(list);
    selectOrganizationsList(
      filterCheckedList(organizationsByProjects, list),
      true,
    );
  };

  const onAllOrganizationsCheck = (e) => {
    const allOrganizations = organizations.map(
      (organization) => organization.id,
    );
    setCheckedOrganizationsList(e.target.checked ? allOrganizations : []);
    selectOrganizationsList(
      e.target.checked ? organizationsByProjects : [],
      true,
    );
  };

  const onAllProjectsCheck = (e) => {
    const allProjects =
      organizationsWithFilteredProjects[0].projects_filtered_by_id.map(
        (project) => project.id,
      );
    setCheckedProjectsLists(e.target.checked ? allProjects : []);
    selectProjectsLists(
      e.target.checked
        ? organizationsWithFilteredProjects
        : [
            {
              ...organizationsWithFilteredProjects[0],
              projects_filtered_by_id: [],
            },
          ],
    );
  };

  const onProjectsCheck = (list) => {
    setCheckedProjectsLists(list);
    const projectsInOneOrganization = organizationsWithFilteredProjects[0];
    selectProjectsLists([
      {
        ...projectsInOneOrganization,
        projects_filtered_by_id: filterCheckedList(
          projectsInOneOrganization.projects_filtered_by_id,
          list,
        ),
      },
    ]);
  };

  const onProjectsByOrganizationsCheck = (selectedProjects) => {
    selectProjectsLists(getListOfFiltredProjects(selectedProjects));
  };

  const renderProjectsOfOneOrganization = () => {
    const filteredProjects =
      organizationsWithFilteredProjects[0]?.projects_filtered_by_id || [];
    return (
      <div>
        <div className={styles.projectsButtons}>
          <MyButton
            onClick={onAddingProjectClick}
            disabled={!organizationsWithFilteredProjects[0]?.generation_allowed}
            withIcon
          >
            Add project
            <Plus />
          </MyButton>
          <Button
            type='text'
            onClick={() => setIsProjectsListOpen(!isProjectsListOpen)}
            className={styles.projectsOpen}
            block
          >
            Projects
            <DownIcon
              className={`${styles.arrow} ${isProjectsListOpen ? styles.arrowActive : ''}`}
            />
          </Button>
        </div>
      <Skeleton
        loading={loadingOrganizations && filteredProjects.length === 0}
        paragraph={{ rows: 10 }}
      >
        {isProjectsListOpen &&
          <div className={styles.organizationsCheckboxesGroup}>
            <Checkbox
              onChange={onAllProjectsCheck}
              checked={
                !!filteredProjects.length &&
                filteredProjects.length === checkedProjectsLists.length
              }
            >
              All ({filteredProjects.length && filteredProjects.length})
            </Checkbox>
            <Checkbox.Group
              onChange={onProjectsCheck}
              value={checkedProjectsLists}
            >
              <div className={styles.checkboxesBlock}>
                {!!filteredProjects.length &&
                  filteredProjects.map((project) => (
                    <Checkbox value={project.id} key={project.id}>
                      {shortenString(project.name, 25)}
                    </Checkbox>
                  ))}
              </div>
            </Checkbox.Group>
          </div>}
      </Skeleton>

      </div>
    );
  };

  const renderOrganizations = () => (
    <div className={styles.organizationsBlock}>
      <MyButton
        onClick={onAddingOrganizationClick}
        withIcon
        disabled={loadingOrganizations}
      >
        Add organization
        <Plus />
      </MyButton>

      <Skeleton loading={loadingOrganizations} paragraph={{ rows: 10 }}>
        <div className={styles.organizationsCheckboxesGroup}>
          <Checkbox
            onChange={onAllOrganizationsCheck}
            checked={checkAllOrganizations}
          >
            All ({organizations.length})
          </Checkbox>
          <Checkbox.Group
            onChange={onOrganizationsCheck}
            value={checkedOrganizationsList}
          >
            <div className={styles.checkboxesBlock}>
              {!!organizations.length &&
                organizations.map((organization) => (
                  <Checkbox key={organization.id} value={organization.id}>
                    {organization.name}
                  </Checkbox>
                ))}
            </div>
          </Checkbox.Group>
        </div>
      </Skeleton>
    </div>
  );

  const renderOrganizationsByProjects = () => (
    <div>
      <Text className={styles.projectsOpen}>
        Projects
      </Text>
      <Skeleton
        loading={organizationsWithFilteredProjects.length === 0 && loadingOrganizations}
        paragraph={{ rows: 10 }}
      >
        <NestedCheckboxes
          organizationsWithFilteredProjects={organizationsWithFilteredProjects}
          onProjectsByOrganizationsCheck={onProjectsByOrganizationsCheck}
        />
      </Skeleton>
    </div>
  );

  const renderBigSidebarContent = () => {
    if (bigSidebarContent === 'organizations' && isProjectsPageOpen)
      return renderProjectsOfOneOrganization();
    if (bigSidebarContent === 'organizations') return renderOrganizations();
    if (bigSidebarContent === 'projects-new')
      return renderOrganizationsByProjects();
  };

  return (
    <div
      className={styles.block}
      style={{
        height: organizations.length === 0 ? '100vh' : 'auto',
      }}
    >
      <aside className={styles.sideBarBlock}>
        <Sider width={76} className={styles.sideBarMini}>
          <div className={styles.sidebarMiniBlock}>
            <div className={styles.topButtonsBlock}>
              {!user.isAllNotAdmin && (
                <Button
                  icon={<OrganizationsIcon />}
                  shape='circle'
                  className={`
                      ${bigSidebarContent === 'organizations' ? styles.activeCircleButton : ''}
                      ${styles.circleButton}
                  `}
                  onClick={() => pushToPage('organizations')}
                />
              )}
              <Button
                icon={<ProjectsIcon />}
                shape='circle'
                className={`
                    ${bigSidebarContent === 'projects-new' ? styles.activeCircleButton : ''}
                    ${styles.circleButton}
                `}
                onClick={() => pushToPage('projects-new')}
              />
            </div>
            <Button
              icon={<LogoutIcon />}
              shape='circle'
              loading={logoutButtonDisabled}
              className={`
                    ${styles.circleButton}
                `}
              onClick={() => {
                setLogoutButtonDisabled(true)
                logOut().finally(() => {
                  setLogoutButtonDisabled(false)
                })
              }}
            />
          </div>
        </Sider>
        <Sider width={280} className={styles.sideBarBig}>
          {renderBigSidebarContent()}
        </Sider>
      </aside>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

export default AppSidebar;
