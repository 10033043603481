import { Button, Empty, Form, Input, Pagination, Spin } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { ReactComponent as CrossIcon } from 'svg/icon/close-black.svg';
import { ReactComponent as SearchIcon } from 'svg/search.svg';
import { ReactComponent as PaginationLeftIcon } from 'svg/pagination-left.svg';
import { ReactComponent as PaginationLeft2Icon } from 'svg/pagination-left-2.svg';
import { ReactComponent as PaginationRightIcon } from 'svg/pagination-right.svg';
import { ReactComponent as PaginationRight2Icon } from 'svg/pagination-right-2.svg';
import { ProductDataList } from '../shared/ProductDataList/ProductDataList';
import { getProductFieldDisplayName, isAutoField } from './services/ProductHelperService';

import styles from './GraphicProductData.module.css';

export default (props) => {
    const indexRef = useRef();
    const [productData, setProductData] = useState([]);
    const [paginationIndex, setPaginationIndex] = useState(1);
    const [form] = Form.useForm();
    const productsTotalCount = props.productsTotalCount;
    const percent =  (paginationIndex) / productsTotalCount * 100;

    useEffect(() => {
      if (props.product) {
        createProductData(props.product);
      }
      }, [props.product]);

    useEffect(() => {
       setPaginationIndex( props.productIndex + 1)
    }, [props.productIndex]);

    const createProductData = product => {
        const productFields = props.productFields ?? {};
        const productData = product ? [...Object.keys(product).filter(
            t => t.toString().indexOf('_changed') === -1 && t.toString() !== 'fresh'
        ).map(t => {
            return {
                name: getProductFieldDisplayName(t),
                value: product[t],
                isAutoField: isAutoField(t),
                id: t,
                type: productFields[t] ?? ''
            }
        })] : [];
        setProductData(productData);
    }

    const setPageNumber = (productIndex) => {
      const pageNumber = Math.floor((productIndex - 1)  / props.pageSize);
      if (pageNumber !== props.pageNumber) {
        props.toFeedPageSelect(pageNumber);
      }
    };

    const clickOnIndex = (e) => {
      if (props.reloadingProducts) return;

      if (e && e.clientX > 0) {
        setProductData([]);
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const productIndex = parseInt(x / indexRef.current.clientWidth * productsTotalCount) + 1;

        setPageNumber(productIndex);
        onPaginationPageChange(productIndex);
      }
    }

    const toSelectProduct = (step = 1) => {
        props.toSelectProduct(step);
    }

    const onPaginationPageChange = (page) => {
        setPageNumber(page);
        setPaginationIndex(page);
        toSelectProduct(page - 1)
    }

    let index = props.productIndex;

    const sortedProductData = productData.sort((a, b) => {
        if (a.id > b.id) {
            return -1;
        }
        if (a.id < b.id) {
            return 1;
        }

        return 0;
    });

    const onSearchSubmit = async ({productId}) => {
        await props.searchProductById(productId);
        form.resetFields();
    };

    const paginationItemRender = (current, type) => {
        switch (type) {
            case 'prev':
                return <Button className={styles.paginationNavButton}><PaginationLeftIcon /></Button>
            case 'next':
                return <Button className={styles.paginationNavButton}><PaginationRightIcon /></Button>
            case 'page':
                return (
                <Button
                    key={index}
                    className={`${styles.paginationNumButton} ${paginationIndex === current ? styles.paginationNumButtonActive : ''}`}
                >
                    {current}
                </Button>
                )
            default:
                return '...'
        }
    };

    const renderHeader = (
      <div className={styles.header}>
        <div className={`${styles.headerText} ${props.inGraphicEditor ? styles.headerTextRight : ''}`}>Product
          data
        </div>
        <Button
          className={styles.headerCloseButton}
          onClick={props.onClose}
          icon={<CrossIcon/>}
          type="text"
          shape="circle"
        />
      </div>
    );


  return (
    <div className={`${styles.main} ${props.inGraphicEditor ? styles.mainRight : ''}`}>

      {productsTotalCount === 0 && !props.reloadingProducts?
        <>
          {renderHeader}
          <div className='centered-container'>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
          </div>
          </>
          : <>
            <div>
              {renderHeader}
              {!!productData.length && (
                <Form
                  form={form}
                  onFinish={onSearchSubmit}
                  className={styles.search}
                >
                  <Form.Item
                    name="productId"
                    rules={[
                      {
                        required: true,
                        message: 'ID of the product is required!',
                      },
                    ]}
                    className={styles.searchItem}
                  >
                    <Input className={styles.searchInput} placeholder="Search by ID" name="productId"/>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className={styles.searchButton}
                      icon={<SearchIcon/>}
                      htmlType="submit"
                      shape="circle"
                      type="text"
                    />
                  </Form.Item>
                </Form>
              )}
            </div>
            {props.reloadingProducts ?
              <div className="loading-container">
                <Spin/>
              </div> :
              <ProductDataList
                productData={sortedProductData}
                isDraggable={props.inGraphicEditor}
                onDraggingElementFinish={props.onDraggingElementFinish}
              />
            }
          {!props.reloadingProducts &&
            <div className={styles.footer}>
            <div
              ref={indexRef}
              onClick={clickOnIndex}
              className={styles.scrollPagination}
            >
              <div className={styles.scrollPaginationInner} style={{ width: `${percent}%` }}/>
            </div>
            <div className={styles.paginationWrapper}>
              <Button
                onClick={() => props.toPrevProduct(10)}
                className={styles.paginationNavButton}
              >
                <PaginationLeft2Icon/>
              </Button>
              <Pagination
                className={styles.pagination}
                total={productsTotalCount}
                current={paginationIndex}
                onChange={onPaginationPageChange}
                pageSize={1}
                showSizeChanger={false}
                showLessItems
                itemRender={paginationItemRender}
                size="small"
              />
              <Button
                onClick={() => props.toNextProduct(10)}
                className={styles.paginationNavButton}
              >
                <PaginationRight2Icon/>
              </Button>
            </div>
          </div>}
          </>}
      </div>
    );
}
