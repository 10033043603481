import React, { useEffect, useRef, useState } from 'react';
import { Button, message } from 'antd';

import { ReactComponent as SearchIcon } from 'svg/search.svg';
import { ReactComponent as CloseIcon } from 'svg/common/close-gray.svg';

import styles from './SearchInput.module.css';

export function SearchInput({
  className = '',
  entity = 'organization',
  entityHelper,
  setEntities,
  onReset,
}) {
  const isOrganization = entity === 'organization';

  const inputRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const [showResetButton, setShowResetButton] = useState(false);

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleSearch = async (event) => {
    const isSearchPressed = event.key === 'Enter' || event === 'search';
    let result;

    if (isSearchPressed && isOrganization) {
      result = entityHelper.filter((org) =>
        org.name.toLowerCase().includes(searchValue.toLowerCase()),
      );
    }

    if (isSearchPressed && !isOrganization) {
      result = entityHelper.filter((org) =>
        org.projects_filtered_by_id.some((project) =>
          project.name.toLowerCase().includes(searchValue.toLowerCase()),
        ),
      );

      result = result.map((org) => ({
        ...org,
        projects_filtered_by_id: org.projects_filtered_by_id.filter((project) =>
          project.name.toLowerCase().includes(searchValue.toLowerCase()),
        ),
      }));
    }

    if (isSearchPressed) {
      if (result.length === 0) {
        handleReset(false);
        return;
      }

      setEntities(result);
      setShowResetButton(true);
    }
  };

  const handleReset = (status = true) => {
    setSearchValue('');
    setShowResetButton(false);

    if (status) {
      onReset();
    } else {
      message.error(
        isOrganization ? 'No organizations found' : 'No projects found',
      );
    }
  };

  useEffect(() => {
    if (searchValue === '') {
      handleReset(true);
    }
  }, [searchValue]);

  return (
    <div className={`${styles.searchWrapper} ${className}`}>
      <div
        onClick={() => inputRef.current.focus()}
        className={`${styles.container} `}
      >
        <SearchIcon className={styles.icon} />
        <input
          className={styles.input}
          ref={inputRef}
          placeholder={
            isOrganization ? 'Search organization' : 'Search project'
          }
          type='text'
          value={searchValue}
          onChange={handleChange}
          onKeyDown={handleSearch}
        />
        {showResetButton && searchValue && (
          <Button
            icon={<CloseIcon className={styles.resetIcon} />}
            className={styles.reset}
            onClick={handleReset}
          />
        )}
      </div>
      <Button
        shape='circle'
        type='text'
        icon={<SearchIcon />}
        className={styles.searchButton}
        disabled={searchValue.length === 0}
        onClick={() => handleSearch('search')}
      />
    </div>
  );
}
