import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';

import SelectControl from '../SelectControl/SelectControl';
import RelativePositionControl from '../RelativePosition/RelativePosition';

import styles from './PositioningControl.module.css';

export default function (props) {
  const [layoutPositioning, setLayoutPositioning] = useState();
  const [attachedTo, setAttachedTo] = useState('x');
  const [attachMode, setAttachMode] = useState('3');
  const [sourceAttachMode, setSourceAttachMode] = useState('1');
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const { group } = props;

  const attachModeDataSource = [
    { id: '1', name: 'top left' },
    { id: '2', name: 'top center' },
    { id: '3', name: 'top right' },
    { id: '4', name: 'middle left' },
    { id: '5', name: 'middle center' },
    { id: '6', name: 'middle right' },
    { id: '7', name: 'bottom left' },
    { id: '8', name: 'bottom center' },
    { id: '9', name: 'bottom right' },
  ];

  useEffect(() => {
    const stage = group.getStage();

    const attachId = group.getAttr('attachId');
    let aid;
    if (attachId || attachId === 0) {
      aid = stage
        .find('.target')
        .find((el) => el.getAttr('attachIds').indexOf(attachId) >= 0);
    }
    const attachSelectId = aid ? aid._id : 'x';
    const positioningValue = attachSelectId === 'x' ? 'absolute' : 'relative';

    setLayoutPositioning(positioningValue);
    setAttachedTo(attachSelectId);
    setAttachMode(group.getAttr('attachMode') || '3');
    setSourceAttachMode(group.getAttr('sourceAttachMode') || '1');
    setOffsetX(parseInt(group.getAttr('attachX') || 0));
    setOffsetY(parseInt(group.getAttr('attachY') || 0));

    setDisabled(group.parent && group.parent.hasName('distributed'));

    if (props.setLayoutPositioning) {
      props.setLayoutPositioning(positioningValue);
    }
  }, [group, props.refreshToggle]);

  const onChangeLayoutPositioning = (e) => {
    const val = e.target.value;
    if (val === 'absolute' && attachedTo !== 'x') {
      props.detachElement(group);
    }

    setLayoutPositioning(val);

    if (props.setLayoutPositioning) {
      props.setLayoutPositioning(val);
    }

    propertyChanged();
  };

  const changeAttachTarget = (val) => {
    if (val !== 'x') {
      const target = group
        .getStage()
        .find('.elementGroup')
        .find((e) => e._id === val);
      props.attachToElement(
        group,
        target,
        attachMode,
        sourceAttachMode,
        offsetX,
        offsetY,
      );

      setAttachedTo(val);
      propertyChanged();
    } else {
      props.detachElement(group);
      setAttachedTo(val);
      propertyChanged();
    }
  };

  const changeAttachPinToMode = (val) => {
    if (val !== 'x') {
      props.detachElement(group);
      const target = group
        .getStage()
        .find('.elementGroup')
        .find((e) => e._id === attachedTo);
      props.attachToElement(
        group,
        target,
        val,
        sourceAttachMode,
        offsetX,
        offsetY,
      );

      setAttachMode(val);
      propertyChanged();

    } else {
      props.detachElement(group);
      setAttachMode(val);
      propertyChanged();
    }
  };

  const changeAttachPinMode = (val) => {
    if (val !== 'x') {
      props.detachElement(group);
      const target = group
        .getStage()
        .find('.elementGroup')
        .find((e) => e._id === attachedTo);
      props.attachToElement(
        group,
        target,
        attachMode,
        val,
        offsetX,
        offsetY,
      );
      setSourceAttachMode(val);

    } else {
      props.detachElement(group);
      setSourceAttachMode(val);
      propertyChanged();
    }
  };

  const changeOffsetX = (value) => {
    const val = parseInt(value);
    if (isNaN(val)) {
      return;
    }

    if (attachMode !== 'x') {
      props.detachElement(group);
      const target = group
        .getStage()
        .find('.elementGroup')
        .find((e) => e._id === attachedTo);
      props.attachToElement(
        group,
        target,
        attachMode,
        sourceAttachMode,
        val,
        offsetY,
      );
      setOffsetX(val);
      propertyChanged();
    }
  };

  const changeOffsetY = (value) => {
    const val = parseInt(value);
    if (isNaN(val)) {
      return;
    }

    if (attachMode !== 'x') {
      props.detachElement(group);
      const target = group
        .getStage()
        .find('.elementGroup')
        .find((e) => e._id === attachedTo);
      props.attachToElement(
        group,
        target,
        attachMode,
        sourceAttachMode,
        offsetX,
        val,
      );
      setOffsetY(val);
      propertyChanged();
    }
  };

  const getViewName = (element) => {
    const elementType = element.getAttr('elementType');
    const elementName = element.getAttr('elementName');

    return elementName || `${elementType} ${element._id}`;
  };

  const propertyChanged = () => {
    if (props.onPropertyChanged) {
      props.onPropertyChanged();
    }
  };
  const generateDataSource = (group) => {
    return group
      .getStage()
      .find('.elementGroup')
      .filter((e) => e !== group)
      .map((e) => {
        return { id: e._id, name: getViewName(e) };
      })
      .concat({ id: 'x', name: 'none' });
  };

  const isRelative = layoutPositioning === 'relative';

  return (
    <div>
      <div>
        <Radio.Group
          disabled={disabled}
          onChange={onChangeLayoutPositioning}
          value={layoutPositioning}
          className={styles.radioBlock}
        >
          <Radio value='absolute' className={styles.radioItem}>
            Absolute
          </Radio>
          <Radio value='relative' className={styles.radioItem}>
            Relative
          </Radio>
        </Radio.Group>
      </div>

      {isRelative && (
        <SelectControl
          disabled={layoutPositioning !== 'relative'}
          label='Relative position to'
          dataSource={generateDataSource(group)}
          onChange={changeAttachTarget}
          value={attachedTo}
        />
      )}

      {isRelative && (
        <div className={styles.relativePositionBlock}>
          <RelativePositionControl
            offsetX={offsetX}
            offsetY={offsetY}
            changeOffsetX={changeOffsetX}
            changeOffsetY={changeOffsetY}
            disabled={attachedTo === 'x'}
            dataSource={attachModeDataSource}
            onPinToChange={changeAttachPinToMode}
            onPinByChange={changeAttachPinMode}
            value1={attachMode}
            value2={sourceAttachMode}
          />
        </div>
      )}
    </div>
  );
}
