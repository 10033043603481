import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {Divider, message} from "antd";
import {useQuery} from "../../../helpers";
import AuthForm from "./AuthForm";
import logo from "../../../svg/common/logo.svg";

import './Login.css';


const RegisterByInvitation = ({getInvitationCode, finishRegistration}) => {
    const [invitationData, setInvitationData] = useState(null);
    const [errors, setErrors] = useState({});

    const history = useHistory();
    const query = useQuery();

    useEffect(() => {
        const queryCode = query.get("code");
        const getAndSaveInvitationCode = async (code) => {
            const codeData = await getInvitationCode(code);
            if (codeData) {
                setInvitationData(codeData);
            } else {
                message.warning({
                    content: 'Looks like the invitation link is not working or expired, please contact the person who invited you.',
                    duration: 5,
                });
            }
        };
        if (queryCode) {
            void getAndSaveInvitationCode(queryCode);
        }
    }, [query]);

    const onFinish = async ({username, password, confirmPassword}) => {
        if (password !== confirmPassword) {
            setErrors((prevState) => ({
                ...prevState,
                confirmPassword: 'Password do not match'
            }));
            return;
        }
        const requestBody = {
            username,
            password,
            organization: invitationData?.id,
            invitation_code: invitationData?.invitation_code,
        };

        await finishRegistration(requestBody, setErrors, history);
    };

    return (
        <div className='login-container'>
            <div className='login-panel'>
                <div className="login-logo-div">
                    <img src={`${logo}`} alt="logo"/>
                    <span className='login-logo-label'>
                        Finish your registration
                    </span>
                </div>
                <AuthForm
                    isRegister
                    onFinish={onFinish}
                    errors={errors}
                    disabled={!invitationData}
                />
                <div className='login-divider'>
                    <Divider/>
                </div>
                <div className='bottom-block'>
                    <Link className='login-link-gray' to="/login">Login</Link>
                </div>
            </div>
        </div>
    );
};

export default RegisterByInvitation;