const mapping = {
    '_ya_price': 'current price',
    '_ya_old_price': 'old price',
    '_discount': 'sale percent',
    '_difference': 'discount'    
};

export const isAutoField = (name) => {
    return name.startsWith('__');
}

export const forceDigitFields = [
    '__discount',
    '__difference'    
];

const getMappingDisplayName = (fieldName) => {    
    for (let item in mapping) {
        if (fieldName.indexOf(item) >= 0) {
            return mapping[item];
        }
    }
    return '';
}

export const getProductFieldTextDisplayName = (fieldName, type, simple = false) => {
    if (!fieldName) {
        return '';
    }

    let name = fieldName ?? '';

    const isAutoField = fieldName.startsWith('__');
    if (isAutoField) {
        const displayName = getMappingDisplayName(name);        
        name = displayName ? displayName : name.replace('__', '').replace('_numeric', '');        
    }

    let displayParts = [];    

    if (isAutoField && !simple) {
        displayParts.push('[auto]');
    }

    if (type) {
        if (type === 'null') {
            const mapping = {
                '__ya_old_price': 'string',
                '__ya_old_price_numeric': 'number',
                '__sale_price_numeric': 'number',
                '__discount': 'number',
                '__difference': 'number',
            }
            if (isAutoField || !simple) {
                displayParts.push(`[${mapping[fieldName] ?? type}]`);
            }
        } else {
            if (isAutoField || !simple) {
                displayParts.push(`[${type}]`);
            }
        }
    }

    displayParts.push(name);

    if (type === 'null' && !simple) {
        displayParts.push('(no data)');
    }

    return displayParts.join(' ');
}

export const getProductFieldDisplayName = (fieldName) => {
    if (!fieldName) {
        return '';
    }

    let name = fieldName ?? '';

    if (isAutoField(fieldName)) {
        const displayName = getDisplayNameByFieldName(name);

        if (!displayName) {
            name = name.replace('__', '').replace('_numeric', '');
        } else {
            name = displayName;
        }
    }

    return name;
}

const getDisplayNameByFieldName = (fieldName) => {
    if (fieldName.startsWith('__')) {        
        for (let item in mapping) {
            if (fieldName.indexOf(item) >= 0) {
                return mapping[item];
            }
        }
    }

    return '';
}