import React, {useState} from 'react';
import { Button, Form, Input, Tooltip } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { shortenString } from '../../helpers';
import { iconsList } from '../../svg/icons-list';

import styles from "./EditableOrganizationName.module.css";

const EditableOrganizationName = ({
                                      value = 'Organizations name',
                                      requiredMessage = 'Organization name is required!',
                                      onSubmit,
                                      isOrganizationCreation = false,
                                      onChange,
                                      nameLoading,
                                      placeholder = '',
                                      isInputOpen = false,
                                      disabled = false
                                  }) => {
    const [isOrgNameChanging, setIsOrgNameChanging] = useState(isInputOpen);
    const maxNameLength = 25;

    const onFinish = (values) => {
        if (isOrganizationCreation) {
            onSubmit(values).then((status) => {
                setIsOrgNameChanging(!status)
            })
        } else {
            setIsOrgNameChanging(false);
            onSubmit(values)
        }
    };

    return (
        <div className={styles.organizationBlock}>
            {!isOrgNameChanging ?
              <Tooltip title={value.length > maxNameLength ? value : ''} >
                <h4>{shortenString(value, maxNameLength)}</h4>
              </Tooltip> : (
                <Form
                  onFinish={onFinish}
                        initialValues={{organizationName: value}}
                        className={styles.organizationNameForm}
                    >
                        <Form.Item
                            name="organizationName"
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (value && value.trim() !== '') {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(requiredMessage);
                                    },
                                },
                            ]}
                        >
                            <Input
                                name="organizationName"
                                variant="borderless"
                                onChange={onChange}
                                className={styles.input}
                                placeholder={placeholder}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                htmlType='submit'
                                shape="circle"
                                icon={<CheckOutlined/>}
                                className={styles.submitButton}
                            />
                        </Form.Item>
                    </Form>
                )}
            {!isOrgNameChanging &&
                <Button
                    type="text"
                    icon={iconsList.editPen}
                    shape="circle"
                    onClick={() => setIsOrgNameChanging(!isOrgNameChanging)}
                    loading={nameLoading}
                    disabled={disabled}
                />}
        </div>
    );
};
export default EditableOrganizationName;