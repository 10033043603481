import React, {useEffect, useState} from 'react';
import {FrownOutlined, SmileOutlined} from '@ant-design/icons';
import {Button, Result, Spin} from 'antd';
import {useQuery} from '../../../helpers';

const RegistrationConfirmation = ({confirmRegistration}) => {
  const query = useQuery();

  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const queryCode = query.get("code");
    const tryToConfirmRegistration = async (code) => {
      const result = await confirmRegistration(code, setLoading);
      setConfirmed(result);
    };
    if (queryCode) {
      void tryToConfirmRegistration(queryCode);
    }
  }, [query]);

  return (
      <div className="centered-container">
        {loading ? <Spin fullscreen/> :
            <Result
            icon={confirmed ?
                <SmileOutlined className="registration-confirm-icon"/> :
                <FrownOutlined className="registration-confirm-icon"/>
            }
            title={confirmed
                ? 'Registration successfully confirmed!'
                : 'Registration confirmation failed'
            }
            subTitle={!confirmed
                ? 'It looks like the registration link has expired'
                : ''}
            extra={
              <Button
                  size="large"
                  type="primary"
                  htmlType="link"
                  href={confirmed ? '/login' : '/register'}
              >
                {confirmed ? 'Login' : 'Try to register again'}
              </Button>
            }
        />}
      </div>
  );
};

export default RegistrationConfirmation;