import React from 'react';

import Appearance from '../EllipseAppearance/EllipseAppearance';
import Conditions from '../ConditionGroup/PropertyConditions';

export default function ({ defaultProps }) {
  return (
    <>
      {defaultProps.activeTab === 1 && (
        <Appearance
          product={defaultProps.product}
          group={defaultProps.group}
          refreshToggle={defaultProps.refreshToggle}
          attachToElement={defaultProps.attachToElement}
          detachElement={defaultProps.detachElement}
          onPropertyChanged={defaultProps.onPropertyChanged}
        />
      )}
      {defaultProps.activeTab === 3 && (
        <Conditions
          product={defaultProps.product}
          productFields={defaultProps.productFields}
          group={defaultProps.group}
          checkCondition={defaultProps.checkCondition}
          reloadingProducts={defaultProps.reloadingProducts}
        />
      )}
    </>
  );
}
