import React, {useState, useEffect} from 'react';
import './ElementPinTypeControl.css';
import { Tooltip } from 'antd';

const styles = {    
    row: {
        display: 'flex',
        flexDirecttion: 'row'
    },
    lineWrapper: {
        width: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    line: {
        borderTop: '2px solid #CCC'
    },
    lineWrapper2: {        
        height: '10px',
        width: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    line2: {
        borderRight: '2px solid #CCC',
        width: '2px'
    },    
    none: {
        width: '10px',
        height: '10px'
    }
}

export default (props) => {
    let [selected, setSelected] = useState();

    const getClass = (id, selectedId) => {        
        return id === selectedId && !props.disabled ? 'circle circle-selected' : 'circle';
    }

    useEffect(() => {
        setSelected(props.value);
    }, [props.value]);

    const class1 = getClass("1", selected);
    const class2 = getClass("2", selected);
    const class3 = getClass("3", selected);
    const class4 = getClass("4", selected);
    const class5 = getClass("5", selected);
    const class6 = getClass("6", selected);
    const class7 = getClass("7", selected);
    const class8 = getClass("8", selected);
    const class9 = getClass("9", selected);

    const onChange = (value) => {
        setSelected(value);
        if (props) {
            props.onChange(value);
        }        
    }

    const sepRow = <div style={styles.row}>
        <div style={styles.lineWrapper2}><div style={styles.line2}></div></div>
        <div style={styles.none}></div>
        <div style={styles.none}></div>
        <div style={styles.none}></div>
        <div style={styles.lineWrapper2}><div style={styles.line2}></div></div>
    </div> 
    return (                
        <div style={{marginTop: '2px'}}>
            <div style={styles.row}>
                <Tooltip title="Top left" mouseEnterDelay="2">
                    <div className={class1} onClick={() => onChange("1")}></div>
                </Tooltip>
                <div style={styles.lineWrapper}><div style={styles.line}></div></div>
                <Tooltip title="Top center" mouseEnterDelay="2">
                    <div className={class2} onClick={() => onChange("2")}></div>
                </Tooltip>
                <div style={styles.lineWrapper}><div style={styles.line}></div></div>
                <Tooltip title="Top right" mouseEnterDelay="2">
                    <div className={class3} onClick={() => onChange("3")}></div>
                </Tooltip>
            </div>
            {sepRow}
            <div style={styles.row}>
                <Tooltip title="Middle left" mouseEnterDelay="2">
                    <div className={class4} onClick={() => onChange("4")}></div>
                </Tooltip>
                <div style={styles.none}></div>
                <Tooltip title="Middle center" mouseEnterDelay="2">
                    <div className={class5} onClick={() => onChange("5")}></div>
                </Tooltip>
                <div style={styles.none}></div>
                <Tooltip title="Middle right" mouseEnterDelay="2">
                    <div className={class6} onClick={() => onChange("6")}></div>
                </Tooltip>
            </div>
            {sepRow}             
            <div style={styles.row}>
                <Tooltip title="Bottom left" mouseEnterDelay="2">
                    <div className={class7} onClick={() => onChange("7")}></div>
                </Tooltip>
                <div style={styles.lineWrapper}><div style={styles.line}></div></div>
                <Tooltip title="Bottom center" mouseEnterDelay="2">
                    <div className={class8} onClick={() => onChange("8")}></div>
                </Tooltip>
                <div style={styles.lineWrapper}><div style={styles.line}></div></div>
                <Tooltip title="Bottom right" mouseEnterDelay="2">
                    <div className={class9} onClick={() => onChange("9")}></div>
                </Tooltip>
            </div>  
        </div>        
    );
};