import React from 'react';
import {Redirect, useLocation, useParams} from "react-router-dom";

const SuccessPayment = () => {
    const {organizationId} = useParams();
    const {pathname} = useLocation();

    return <Redirect to={`/admin/${organizationId}${pathname.startsWith('/payment-method') ? '' : '?status=success' }`}/>
};

export default SuccessPayment;