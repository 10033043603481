import React from 'react';
import { Button, Tooltip } from 'antd';
import {COLORS} from "../../../constants";

import styles from './MyButton.module.css';

const MyButton = ({
                      text,
                      htmlType,
                      bgColor,
                      fontColor,
                      onClick,
                      border,
                      customStyles,
                      className,
                      withIcon = false,
                      disabled,
                      loading,
                      tooltipTitle ='',
                      tooltipPlacement = 'bottom',
                      children
                  }) => {

    const innerStyles = {
        backgroundColor: bgColor || COLORS.mainViolet,
        color: fontColor || '#FFFFFF',
        border: border || 'none',
    };

    return (
      <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
        <Button
            type='primary'
            htmlType={htmlType}
            onClick={onClick}
            className={`${styles.button} ${withIcon ? styles.buttonWithIcon : ''} ${className}`}
            style={{...innerStyles, ...customStyles}}
            disabled={disabled}
            loading={loading}
        >
            {text}
            {children}
        </Button>
      </Tooltip>
    );
};

export default MyButton;