import React, { useEffect, useState } from 'react';
import { InputNumber, Typography } from 'antd';

import ColorEditor from '../../controls/ColorEditor/ColorEditor';
import ShadowControl from '../../controls/ShadowControl/ShadowControl';
import RotationControl from '../../controls/RotationControl/RotationControl';
import PositioningControl from '../../controls/PositioningControl/PositioningControl';
import PositionSizeControl from '../../controls/PositionSizeControl/PositionSizeControl';
import { refreshTransform } from '../PropertiesHelper';

import globalStyles from '../../GlobalGraphicEditor.module.css';

const { Text } = Typography;

export default function (props) {
  const shape = props.group.findOne('.background');

  const [layoutPositioning, setLayoutPositioning] = useState();
  const [borderColor, setBorderColor] = useState();
  const [borderWidth, setBorderWidth] = useState();
  const [refreshSizeToggle, setRefreshSizeToggle] = useState();

  useEffect(() => {
    updateRefreshSizeToggle();
  }, [props.refreshToggle]);

  useEffect(() => {
    setBorderColor(shape.getAttr('stroke'));
    setBorderWidth(shape.getAttr('strokeWidth'));
  }, [shape, props.group, props.refreshToggle]);

  const changeBorderColor = (color) => {
    shape.setAttr('stroke', color);
    setBorderColor(color);
    propertyChanged();
  };

  const changeBorderWidth = (value) => {
    const val = parseInt(value);
    if (val >= 0) {
      shape.setAttr('strokeWidth', val);
      setBorderWidth(value);
      propertyChanged();
    }
  };

  const propertyChanged = () => {
    refreshTransform(props.group);
    if (props.onPropertyChanged) {
      props.onPropertyChanged();
    }
  };

  const updateRefreshSizeToggle = () => {
    setRefreshSizeToggle((refreshSizeToggle) => !refreshSizeToggle);
  };

  const rotationChanged = () => {
    updateRefreshSizeToggle();
    propertyChanged();
  };

  return (
    <div>
      <div
        className={`${globalStyles.bottomBorderDivider} ${globalStyles.bottomBigPaddings}  ${globalStyles.selectBlock} ${globalStyles.bigGapBlock}`}
      >
        <PositionSizeControl
          showH={false}
          element={shape}
          group={props.group}
          layoutPositioning={layoutPositioning}
          refreshToggle={refreshSizeToggle}
          onPropertyChanged={propertyChanged}
          isLine
        />
        <PositioningControl
          group={props.group}
          attachToElement={props.attachToElement}
          detachElement={props.detachElement}
          refreshToggle={props.refreshToggle}
          setLayoutPositioning={setLayoutPositioning}
          onPropertyChanged={propertyChanged}
        />
      </div>

      <div className={globalStyles.selectBlock}>
        <div className={globalStyles.bottomBorderDivider}>
          <div
            className={` ${globalStyles.spaceBetweenBlock} ${globalStyles.openBlock}`}
          >
            <Text className={globalStyles.text}>Border colour:</Text>
            <ColorEditor value={borderColor} onChange={changeBorderColor} />
          </div>
        </div>

        <div className={globalStyles.bottomBorderDivider}>
          <div
            className={` ${globalStyles.spaceBetweenBlock} ${globalStyles.openBlock}`}
          >
            <Text className={globalStyles.text}>Border width:</Text>
            <InputNumber
              size='small'
              min={0}
              value={borderWidth}
              onChange={changeBorderWidth}
              className={globalStyles.input}
            />
          </div>
        </div>

        <div className={globalStyles.bottomBorderDivider}>
          <ShadowControl element={shape} onPropertyChanged={propertyChanged} />
        </div>
        <div className={globalStyles.bottomBorderDivider}>
          <RotationControl
            group={props.group}
            element={shape}
            refreshToggle={props.refreshToggle}
            onPropertyChanged={rotationChanged}
          />
        </div>
      </div>
    </div>
  );
}
