import React, { useEffect, useState } from 'react';
import { Button, InputNumber, Typography } from 'antd';

import ColorEditor from '../ColorEditor/ColorEditor';
import { isColorSet } from '../../properties/PropertiesHelper';
import { ReactComponent as MinusIcon } from 'svg/graphicEditor/minus-big-black.svg';
import { ReactComponent as PlusIcon } from 'svg/graphicEditor/plus-big-black.svg';
import { ReactComponent as BorderSizeIcon } from 'svg/graphicEditor/border-width-small.svg';

import globalStyles from '../../GlobalGraphicEditor.module.css';

const { Text } = Typography;

export default function (props) {
  const [borderColor, setBorderColor] = useState();
  const [borderWidth, setBorderWidth] = useState();
  const [isBorderEnabled, setBorderEnabled] = useState();

  const { element } = props;

  useEffect(() => {
    setBorderColor(element.getAttr('stroke'));
    setBorderWidth(element.getAttr('strokeWidth'));

    setBorderEnabled(
      !!element.getAttr('strokeWidth') && isColorSet(element.getAttr('stroke')),
    );
  }, [element]);

  useEffect(() => {
    if (props.manualEnabled !== undefined) {
      updateState(props.manualEnabled);
    }
  }, [props.manualEnabled]);

  const changeBorderWidth = (value) => {
    const val = parseInt(value);
    if (val >= 0) {
      element.setAttr('strokeWidth', val);
      setBorderWidth(value);
      propertyChanged();
    }
  };

  const changeBorderColor = (color) => {
    element.setAttr('stroke', color);
    setBorderColor(color);
    propertyChanged();
  };

  const changeBorderEnable = () => {
    setBorderEnabled(!isBorderEnabled);
    updateState(!isBorderEnabled);
  };

  const updateState = (isEnabled) => {
    if (!isEnabled) {
      element.setAttr('_lastStateBorder', {
        stroke: borderColor,
        strokeWidth: borderWidth,
      });
      changeBorderWidth(0);
    } else {
      const state = element.getAttr('_lastStateBorder');
      if (state) {
        changeBorderColor(state.stroke);
        changeBorderWidth(state.strokeWidth);
      }
    }
  };

  const propertyChanged = () => {
    if (props.onPropertyChanged) {
      props.onPropertyChanged();
    }
  };

  return (
    <div>
      <div className={globalStyles.spaceBetweenBlock}>
        <Text className={globalStyles.smallTitle}>Border</Text>
        <Button
          shape='circle'
          type='text'
          icon={isBorderEnabled ? <MinusIcon /> : <PlusIcon />}
          onClick={changeBorderEnable}
        />
      </div>
      {isBorderEnabled && (
        <div
          className={`${globalStyles.innerBlock} ${globalStyles.selectBlock} ${globalStyles.gapBlock}`}
        >
          <div className={globalStyles.spaceBetweenBlock}>
            <Text className={globalStyles.text}>Border size:</Text>
            <InputNumber
              size='small'
              prefix={<BorderSizeIcon />}
              value={borderWidth}
              onChange={changeBorderWidth}
              className={globalStyles.input}
            />
          </div>
          <div className={globalStyles.spaceBetweenBlock}>
            <Text className={globalStyles.text}>Border colour:</Text>
            <ColorEditor
              value={borderColor}
              onChange={changeBorderColor}
              type='simple'
            />
          </div>
        </div>
      )}
    </div>
  );
}
