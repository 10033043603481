import React, { useEffect, useState } from 'react';
import { InputNumber, Typography } from 'antd';

import ColorEditor from '../../controls/ColorEditor/ColorEditor';
import BorderControl from '../../controls/BorderControl/BorderControl';
import ShadowControl from '../../controls/ShadowControl/ShadowControl';
import RotationControl from '../../controls/RotationControl/RotationControl';
import PositioningControl from '../../controls/PositioningControl/PositioningControl';
import PositionSizeRadiusScaleControl from '../../controls/PositionSizeControl/PositionSizeRadiusScaleControl';
import { refreshTransform } from '../PropertiesHelper';

import globalStyles from '../../GlobalGraphicEditor.module.css';

const { Text } = Typography;
export default function (props) {
  const shape = props.group.findOne('.background');

  const [layoutPositioning, setLayoutPositioning] = useState();

  const [shapeSides, setShapeSides] = useState();
  const [shapeRadius, setShapeRadius] = useState();
  const [refreshSizeToggle, setRefreshSizeToggle] = useState();

  useEffect(() => {
    updateRefreshSizeToggle();
  }, [props.refreshToggle]);

  useEffect(() => {
    setShapeSides(shape.getAttr('sides'));
    setShapeRadius(Math.round(shape.getAttr('radius')));
  }, [shape, props.group, props.refreshToggle]);

  const changeAndRedraw = (obj, property, val, setter, minVal) => {
    if (minVal === undefined || val >= minVal) {
      obj.setAttr(property, val);
      if (setter) setter(val);
    }
  };

  const changeSides = (value) => {
    changeAndRedraw(shape, 'sides', parseInt(value), setShapeSides, 3);

    const scale = shape.getStage().scaleX();
    const size = shape.getClientRect();

    const offsetX = -size.width / scale / 2;
    shape.offset({ x: offsetX });

    propertyChanged();
  };

  const propertyChanged = () => {
    refreshTransform(props.group);
    if (props.onPropertyChanged) {
      props.onPropertyChanged();
    }
  };

  const updateRefreshSizeToggle = () => {
    setRefreshSizeToggle((refreshSizeToggle) => !refreshSizeToggle);
  };

  const rotationChanged = () => {
    updateRefreshSizeToggle();
    propertyChanged();
  };

  return (
    <div>
      <div
        className={`${globalStyles.bottomBorderDivider} ${globalStyles.bottomBigPaddings}  ${globalStyles.selectBlock} ${globalStyles.bigGapBlock}`}
      >
        <PositionSizeRadiusScaleControl
          element={shape}
          group={props.group}
          layoutPositioning={layoutPositioning}
          refreshToggle={refreshSizeToggle}
          onPropertyChanged={propertyChanged}
        />
        <PositioningControl
          group={props.group}
          attachToElement={props.attachToElement}
          detachElement={props.detachElement}
          refreshToggle={props.refreshToggle}
          setLayoutPositioning={setLayoutPositioning}
          onPropertyChanged={propertyChanged}
        />
      </div>

      <div className={`${globalStyles.selectBlock}`}>
        <div className={globalStyles.bottomBorderDivider}>
          <div
            className={` ${globalStyles.spaceBetweenBlock} ${globalStyles.openBlock}`}
          >
            <Text className={globalStyles.text}>Sides:</Text>
            <InputNumber
              size='small'
              min={0}
              value={shapeSides}
              onChange={changeSides}
              className={globalStyles.input}
            />
          </div>
        </div>
        <div className={globalStyles.bottomBorderDivider}>
          <div
            className={` ${globalStyles.spaceBetweenBlock} ${globalStyles.openBlock}`}
          >
            <Text className={globalStyles.text}>Background colour:</Text>
            <ColorEditor element={shape} onPropertyChanged={propertyChanged} />
          </div>
        </div>
        <div className={globalStyles.bottomBorderDivider}>
          <BorderControl element={shape} onPropertyChanged={propertyChanged} />
        </div>
        <div className={globalStyles.bottomBorderDivider}>
          <ShadowControl element={shape} onPropertyChanged={propertyChanged} />
        </div>
        <div className={globalStyles.bottomBorderDivider}>
          <RotationControl
            group={props.group}
            element={shape}
            refreshToggle={props.refreshToggle}
            onPropertyChanged={rotationChanged}
          />
        </div>
      </div>
    </div>
  );
}
