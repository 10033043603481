import React, { useEffect, useState } from 'react';
import { Button, InputNumber, Typography } from 'antd';

import { isSeparatelyEnabled } from '../../properties/PropertiesHelper';
import { ReactComponent as MinusIcon } from 'svg/graphicEditor/minus-big-black.svg';
import { ReactComponent as PlusIcon } from 'svg/graphicEditor/plus-big-black.svg';
import { ReactComponent as CornerRadiusIcon } from 'svg/graphicEditor/corner-radius-small.svg';
import { ReactComponent as CornerBottomLeft } from 'svg/graphicEditor/left-bottom-corner-small.svg';
import { ReactComponent as CornerTopLeft } from 'svg/graphicEditor/left-top-corner-small.svg';
import { ReactComponent as CornerBottomRight } from 'svg/graphicEditor/right-bottom-corner-small.svg';
import { ReactComponent as CornerTopRight } from 'svg/graphicEditor/right-top-corner-small.svg';

import myStyles from './BackgroundBorderControl.module.css';
import globalStyles from '../../GlobalGraphicEditor.module.css';

const { Text } = Typography;

export default function (props) {
  const [isCornerEnabled, setCornerEnabled] = useState();

  const [cornerTL, setCornerTL] = useState();
  const [cornerTR, setCornerTR] = useState();
  const [cornerBR, setCornerBR] = useState();
  const [cornerBL, setCornerBL] = useState();

  const [isBgBorderSeparatelyEnabled, setBgBorderSeparatelyEnabled] =
    useState();

  const { element } = props;

  useEffect(() => {
    const cornerLt = (element.getAttr('cornerRadius') || [0, 0, 0, 0])[0];
    const cornerTr = (element.getAttr('cornerRadius') || [0, 0, 0, 0])[1];
    const cornerBr = (element.getAttr('cornerRadius') || [0, 0, 0, 0])[2];
    const cornerBl = (element.getAttr('cornerRadius') || [0, 0, 0, 0])[3];
    setCornerTL(cornerLt);
    setCornerTR(cornerTr);
    setCornerBR(cornerBr);
    setCornerBL(cornerBl);

    const isEnabled = !!(cornerLt || cornerTr || cornerBr || cornerBl);
    setCornerEnabled(isEnabled);
    setBgBorderSeparatelyEnabled(
      isSeparatelyEnabled(cornerLt, cornerTr, cornerBr, cornerBl),
    );

    if (props.onEnabledChanged) {
      props.onEnabledChanged(isEnabled);
    }
  }, [element]);

  const changeCornerEnable = () => {
    setCornerEnabled(!isCornerEnabled);
    updateState(!isCornerEnabled);

    if (props.onEnabledChanged) {
      props.onEnabledChanged(!isCornerEnabled);
    }
  };

  const updateState = (isEnabled) => {
    if (!isEnabled) {
      element.setAttr('_lastStateBackground', {
        cornerTL,
        cornerTR,
        cornerBR,
        cornerBL,
      });
      changeCorner(0);
    } else {
      const state = element.getAttr('_lastStateBackground');
      if (state) {
        element.setAttr('cornerRadius', [
          parseInt(state.cornerTL),
          parseInt(state.cornerTR),
          parseInt(state.cornerBR),
          parseInt(state.cornerBL),
        ]);
        setCornerTL(state.cornerTL);
        setCornerTR(state.cornerTR);
        setCornerBR(state.cornerBR);
        setCornerBL(state.cornerBL);
      }
    }
  };

  const changeCornerTL = (value) => {
    if (parseInt(value) >= 0) {
      element.setAttr('cornerRadius', [
        parseInt(value),
        parseInt(cornerTR),
        parseInt(cornerBR),
        parseInt(cornerBL),
      ]);
      setCornerTL(value);
      propertyChanged();
    }
  };

  const changeCornerTR = (value) => {
    if (parseInt(value) >= 0) {
      element.setAttr('cornerRadius', [
        parseInt(cornerTL),
        parseInt(value),
        parseInt(cornerBR),
        parseInt(cornerBL),
      ]);
      setCornerTR(value);
      propertyChanged();
    }
  };

  const changeCornerBR = (value) => {
    if (parseInt(value) >= 0) {
      element.setAttr('cornerRadius', [
        parseInt(cornerTL),
        parseInt(cornerTR),
        parseInt(value),
        parseInt(cornerBL),
      ]);
      setCornerBR(value);
      propertyChanged();
    }
  };

  const changeCornerBL = (value) => {
    if (parseInt(value) >= 0) {
      element.setAttr('cornerRadius', [
        parseInt(cornerTL),
        parseInt(cornerTR),
        parseInt(cornerBR),
        parseInt(value),
      ]);
      setCornerBL(value);
      propertyChanged();
    }
  };

  const changeCorner = (value) => {
    if (parseInt(value) >= 0) {
      element.setAttr('cornerRadius', [
        parseInt(value),
        parseInt(value),
        parseInt(value),
        parseInt(value),
      ]);
      setCornerTL(value);
      setCornerTR(value);
      setCornerBR(value);
      setCornerBL(value);
    }
    propertyChanged();
  };

  const changeBgBorderSeparatelyEnable = () => {
    setBgBorderSeparatelyEnabled(!isBgBorderSeparatelyEnabled);
    changeCorner(cornerTL ?? 0);
  };

  const propertyChanged = () => {
    if (props.onPropertyChanged) {
      props.onPropertyChanged();
    }
  };

  return (
    <div>
      <div className={globalStyles.spaceBetweenBlock}>
        <Text className={globalStyles.smallTitle}>
          {props.label ? props.label : 'Corners'}
        </Text>
        <Button
          shape='circle'
          type='text'
          icon={isCornerEnabled ? <MinusIcon /> : <PlusIcon />}
          onClick={changeCornerEnable}
        />
      </div>
      {isCornerEnabled && (
        <div className={`${globalStyles.innerBlock} ${myStyles.wrapper}`}>
          <div className={globalStyles.spaceBetweenBlock}>
            <Text className={globalStyles.text}>Corner radius:</Text>
            <InputNumber
              size='small'
              disabled={isBgBorderSeparatelyEnabled}
              prefix={<CornerRadiusIcon />}
              value={isBgBorderSeparatelyEnabled ? '' : cornerTL}
              onChange={changeCorner}
              min={0}
              className={globalStyles.input}
            />
          </div>

          <div className={globalStyles.spaceBetweenBlock}>
            <Text className={globalStyles.smallTitle}>Set separately</Text>
            <Button
              type='text'
              shape='circle'
              icon={isBgBorderSeparatelyEnabled ? <MinusIcon /> : <PlusIcon />}
              onClick={changeBgBorderSeparatelyEnable}
            />
          </div>

          {isBgBorderSeparatelyEnabled && (
            <div className={myStyles.cornersBlock}>
              <div className={globalStyles.spaceBetweenBlock}>
                <InputNumber
                  size='small'
                  prefix={<CornerTopLeft />}
                  controls={false}
                  value={cornerTL}
                  onChange={changeCornerTL}
                  min={0}
                  className={globalStyles.input}
                />
                <InputNumber
                  size='small'
                  prefix={<CornerTopRight />}
                  controls={false}
                  value={cornerTR}
                  onChange={changeCornerTR}
                  min={0}
                  className={globalStyles.input}
                />
              </div>

              <div className={globalStyles.spaceBetweenBlock}>
                <InputNumber
                  size='small'
                  prefix={<CornerBottomLeft />}
                  controls={false}
                  value={cornerBL}
                  onChange={changeCornerBL}
                  min={0}
                  className={globalStyles.input}
                />
                <InputNumber
                  size='small'
                  prefix={<CornerBottomRight />}
                  controls={false}
                  value={cornerBR}
                  onChange={changeCornerBR}
                  min={0}
                  className={globalStyles.input}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
