import React from 'react';
import {useHistory} from "react-router-dom";
import {Link} from 'react-router-dom';

import {Col, Divider, Row} from 'antd';
import logo from '../../../svg/common/logo.svg';
import AuthForm from "./AuthForm";

import './Login.css';


const LoginUser = (props) => {
    const history = useHistory();
    const onFinish = async (values) => {
        await props.backend.logIn(
            {
                username: values.username,
                password: values.password,
            },
            history
        );
    };

    return (
        <Row className='login-container'>
            <Col className='login-panel'>
                <div className="login-logo-div">
                    <img src={logo} alt=""/>
                    <span className='login-logo-label'>Log In</span>
                </div>

                <AuthForm onFinish={onFinish}/>

                <div className='login-divider'>
                    <Divider/>
                </div>

                <div className='bottom-block '>
	                <Link className="login-link-gray" to="/register">
			                Register
									</Link>
	                <div className="login-link-gray">/</div>
	                <Link  className="login-link-gray" to="/request-reset-password" >
		                Forgot password?
									</Link>
                </div>
            </Col>
        </Row>
    );
};

export default LoginUser;
