import React from 'react';
import { dateOptions } from '../../../helpers';
import { Button, Typography } from 'antd';

import styles from './MoreDetailedCard.module.css';

const {Text} = Typography;

const MoreDetailedCard = ({log, viewReportClick, lastUpload = false}) => {
  return (
    <div className={`${styles.sessionsCard} ${styles.borderBottom}`}>
      <div className={`${styles.sessionsBlock} ${styles.sessionsBlockSchedule}`}>
        <Text className={styles.scheduleTitle}>
          {lastUpload ? 'Last upload' : 'Scheduled'}
        </Text>
        <Text className={styles.scheduleDate}>
          {new Date(log.created).toLocaleString('en-US', dateOptions)}
        </Text>
      </div>
      <div className={styles.sessionsBlock}>
        <Text className={styles.scheduleTitle}>
          Items
        </Text>
        <Text className={`${styles.sessionValue}`}>
          {log.row_count}
        </Text>
      </div>
      <div className={styles.sessionsBlock}>
        <Text className={styles.scheduleTitle}>
          Images made
        </Text>
        <Text
          className={`${styles.sessionValue}`}
          type='success'
        >
          {log.processed}
        </Text>
      </div>
      <div className={styles.sessionsBlock}>
        <Text className={styles.scheduleTitle}>
          Images failed
        </Text>
        <Text
          className={`${styles.sessionValue}`}
          type='danger'
        >
          {log.failed}
        </Text>
      </div>
      <div className={styles.sessionsButton}>
        <Button onClick={() => viewReportClick(log.id)}>View report</Button>
      </div>
    </div>

  );
};

export default MoreDetailedCard;