import React, {useEffect, useState} from 'react';
import {Button, Form, Input, message} from "antd";
import UserManagementTable from "../UserManagementTable/UserManagementTable";
import CustomPopConfirm from "../../../shared/CustomPopConfirm/CustomPopConfirm";
import MyButton from "../../../shared/MyButton/MyButton";
import {checkRole} from "../../../../helpers";
import {deleteUsersFromOrganizations, editUsersRoleAndAccess, inviteUser} from "../UserManagementApi/UserManagementApi";

import {ReactComponent as DeleteIcon} from "../../../../svg/icon/trash-red.svg";
import {ReactComponent as SendIcon} from "../../../../svg/icon/send-plane.svg";

import styles from './UserManagementBlock.module.css';

const UsersManagementBlock = ({organization, updateSelectedListByMembers}) => {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [openPopConfirm, setOpenPopConfirm] = useState(false);
    const [userTableState, setUserTableState] = useState([]);
    const [userInviteLoading, setUserInviteLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        const usersTableData = organization.members.map((member) => ({
            ...member,
            key: member.id,
            role: checkRole(member.role),
        }));

        setUserTableState(usersTableData)

    }, [organization?.members]);

    const onUsersSelect = (users) => {
        setSelectedUsers(users)
    };
    const showPopConfirm = () => {
        setOpenPopConfirm(true);
    };

    const handleOkConfirm = async () => {
        setOpenPopConfirm(false);
        await deleteUsersFromOrganizations(organization.id, {users:selectedUsers});
        const updatedMemberList = organization.members.filter(item => !selectedUsers.includes(item.id));
        updateSelectedListByMembers(organization.id, updatedMemberList);
        setSelectedUsers([]);

        message.success('User deleted!');
    };

    const handleCancelConfirm = () => {
        setOpenPopConfirm(false);
    };

    const onUserInviteSubmit = async (values) => {
        const requestBody = {
            organization: organization.id,
            emails: [values.email],
        };
        await inviteUser(requestBody, setUserInviteLoading);
        form.resetFields();
    };

    const updateOrganizationMembers = (updatedOrganization) => {
        const index = organization.members.findIndex(org => org.id === updatedOrganization.id);
        if (index !== -1) {
            organization.members[index] = updatedOrganization;
        }
        return organization.members;
    };

    const updateUsersRoleAndAccess = async (userId, requestBody) => {
        const result = await editUsersRoleAndAccess( organization.id,userId, requestBody);
        const updatedMemberList = organization.members.map((member) => {
            if (member.id === result.id) {
                return {...result}
            }
            return member;
        });
        updateSelectedListByMembers(organization.id, updatedMemberList);
        if (result.name) {
            message.error({
                content: 'User update failed, try again',
                duration: 3
            });
            return false;
        }
    };

    const renderDeleteButton = (
        <CustomPopConfirm
            title='Are you sure you want to delete this?'
            open={openPopConfirm}
            onConfirm={handleOkConfirm}
            onCancel={handleCancelConfirm}
            placement='bottomLeft'
            okText='Delete'
        >
            <Button
                type='text'
                danger
                className={styles.deleteUsersButton}
                onClick={showPopConfirm}
            >
                Delete ({selectedUsers.length})
                <DeleteIcon/>
            </Button>
        </CustomPopConfirm>
    );

    return (
        <div className={styles.mainBlock}>
            <div className={styles.topBlock}>
                {
                    !!selectedUsers.length && renderDeleteButton
                }
                <div className={styles.addUserBlock}>
                    <Form
                        form={form}
                        className={styles.addUserForm}
                        onFinish={onUserInviteSubmit}
                        disabled={!organization.generation_allowed || userInviteLoading}
                    >
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    message: 'Email is required'
                                }
                            ]}
                            name="email"
                        >
                            <Input
                                name="email"
                                className={styles.addUserInput}
                                placeholder='Add user’s email'
                            />
                        </Form.Item>
                        <Form.Item>
                            <MyButton htmlType='submit' loading={userInviteLoading}>
                                <div className={styles.sendButton}>
                                    Send invite <SendIcon/>
                                </div>
                            </MyButton>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <UserManagementTable
                selectedUsers={selectedUsers}
                onUsersSelect={onUsersSelect}
                initialTableData={userTableState}
                projects={organization.projects}
                updateUsersRoleAndAccess={updateUsersRoleAndAccess}
                isAllowed={!organization.generation_allowed}
            />
        </div>
    );
};

export default UsersManagementBlock;