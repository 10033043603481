import React from 'react';
import {useHistory} from "react-router-dom";
import {Button, message, Popconfirm} from "antd";
import {ReactComponent as BackIcon} from '../../svg/sidebar/aside-back.svg';
import {ReactComponent as GraphTemplatesIcon} from '../../svg/sidebar/aside-graph-templates.svg';
import {ReactComponent as DataPreviewIcon} from '../../svg/sidebar/aside-data-preview.svg';
import {ReactComponent as NodesSaveIcon} from '../../svg/sidebar/nodes-save.svg';
import {ReactComponent as NodesRunIcon} from '../../svg/sidebar/nodes-run.svg';
import {ReactComponent as StopProjectIcon} from 'svg/icon/stop-project.svg'
import {
  isOutputsReadyToRun,
  isProjectButtonsDisabled,
  isProjectOrOutputStopping,
  isProjectRunning,
} from 'components/Project/StatusBlock/StatusBlock';
import {runProject, stopProject} from '../Project/ProjectsApi/projectsApi';
import { useWebSocket } from 'contexts/SocketContext';
import { useQuery } from '../../helpers';

import styles from  './Dnd.module.css';

const SidebarButton = ({icon, onClick, projectLoading, isActive = false, loading = false})=> {
    return (
        <Button
            loading={loading}
            className={styles.sidebarButton + (isActive ? ' ' + styles.sidebarButtonActive : '') }
            onClick={onClick}
            disabled={projectLoading}
        >
        {!loading && icon}
        </Button>
    )
}

export default (props) => {
    const history = useHistory();
    const query = useQuery();
    const { data: socketData } = useWebSocket();

    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    const isRunButtonDisabled = isProjectButtonsDisabled(socketData[props.projectId]?.status) || !props.organization?.generation_allowed

    const save = async () => {
        const result =  await props.save_func();
				if (result) {
					message.success('Successfully saved')
				}
    };

    const handleRun = async () => {
      const saveResult = await props.save_func();

			if (saveResult) {
				await runProject(props.projectId);
				message.success('Project is running!')
			}
    };

    const goBack = () => {
      const queryCode = query.get("organization");
      const organizationPath = queryCode ? `/${queryCode}` : '';
      history.push(`/projects-new${organizationPath}`);
    };

		const isRunning = isProjectRunning(socketData[props.projectId]?.status);
		const isStopping = isProjectOrOutputStopping(socketData[props?.projectId]);
    const isOutputRunning = isOutputsReadyToRun(socketData[props?.projectId]?.outputs);
    const isSaveButtonDisabled = !props.organization?.generation_allowed || props.projectLoading || isRunning || isOutputRunning;

		return (
        <>
        <aside className={styles.aside}>
            <SidebarButton onClick={goBack} icon={<BackIcon />} />
            <div className={styles.asideButtons}>
                <SidebarButton
                    loading={props.isAsideItemLoading}
                    isActive={props.isTemplatesSideBarOpen}
                    icon={<GraphTemplatesIcon />}
                    onClick={props.showTemplatesSideBar}
                    projectLoading={props.projectLoading}
                />
                <SidebarButton
                    isActive={props.isShowDataPreviewSideBar}
                    icon={<DataPreviewIcon />}
                    onClick={props.showDataPreviewSideBar}
                    projectLoading={props.projectLoading}
                />
            </div>
        </aside>
        <div className={styles.panel}>
            <div className={styles.panelNodes}>
                <div className={styles.node}
                     onDragStart={(event) => onDragStart(event, 'filter')}
                     draggable={props.organization?.generation_allowed || !props.projectLoading}
                >
                    <div className={`${styles.nodeHeader} ${styles.nodeHeaderFilter}`}>Filter</div>
                </div>
                <div className={styles.node}
                     onDragStart={(event) => onDragStart(event, 'graphic')}
                     draggable={props.organization?.generation_allowed || !props.projectLoading}
                >
                    <div className={`${styles.nodeHeader} ${styles.nodeHeaderGraphic}`}>Graphic</div>
                </div>
                <div className={styles.node}
                     onDragStart={(event) => onDragStart(event, 'export')}
                     draggable={props.organization?.generation_allowed || !props.projectLoading}
                >
                    <div className={`${styles.nodeHeader} ${styles.nodeHeaderExport}`}>Export</div>
                </div>
                <div className={`${styles.node} ${styles.nodeNote}`}
                     onDragStart={(event) => onDragStart(event, 'note')}
                     draggable={props.organization?.generation_allowed || !props.projectLoading}
                >
                    <div className={styles.nodeHeader}>Note</div>
                </div>
            </div>
            <div className={styles.panelButtons}>
                <Button
                    className={`${styles.panelButton} ${styles.panelButtonSave}`}
                    onClick={save}
                    disabled={isSaveButtonDisabled}
                >
                    <span>Save</span>{<NodesSaveIcon />}
                </Button>
                {props.organization?.generation_allowed && (
                    <>
                        {isRunning ?
                            <Button
                                className={`${styles.panelButton} ${styles.panelButtonRun} ${socketData[props.projectId]?.status === 'S' ? styles.panelButtonDisabled : ''}`}
                                disabled={isStopping}
                                onClick={() => stopProject(props.projectId)}
                            >
                                <span>Stop</span><StopProjectIcon />
                            </Button> :
                            <Popconfirm
                                title="Are you sure you want to save and RUN project?"
                                onConfirm={handleRun}
                                okText="Yes, RUN after save!"
                                cancelText="No"
                            >
                                <Button
                                    className={`${styles.panelButton} ${styles.panelButtonRun} ${isRunButtonDisabled ? styles.panelButtonDisabled : ''}`}
                                    disabled={props.projectLoading}
                                >
                                    <span>Run</span>{<NodesRunIcon />}
                                </Button>
                            </Popconfirm>
                        }
                    </>
                )}
            </div>
        </div>
        </>
    );
};
