import React from 'react';
import {Button, Typography} from "antd";
import {ReactComponent as Download} from '../../../../svg/icon/invoice-link.svg';
import CustomTable from "../../../shared/CustomTable/CustomTable";

import styles from './BillingHistoryTable.module.css';

const {Text} = Typography;
const BillingHistoryTable = ({history}) => {

    const getStatus = (status) => {
        const statusColors = {
            canceled: {
                bg: '#FCE1E1',
                font: '#C76767',
                message: 'Unpaid'
            },
            succeeded: {
                bg: '#E2FCE1',
                font: '#5EB067',
                message: 'Paid'
            },
            default: {
                bg: '#ffeccf',
                font: '#FFB74B',
                message: 'In process'

            }
        }
        return statusColors[status] ?? statusColors.default;
    };


    const columns = [
            {
                title: 'Month',
                dataIndex: 'month',
                align: 'center',
                width: '25%',
                render: (record) => (
                    <Text>{record}</Text>
                )
            },
            {
                title: 'Invoice date',
                dataIndex: 'payment_date',
                align: 'center',
                width: '25%',
                render: (invoiceDate) => (
                    <Text>{invoiceDate}</Text>
                )
            },
            {
                title: 'Status',
                dataIndex: 'payment_status',
                align: 'center',
                width: '25%',
                render: (status) => (
                    <div
                        className={styles.status}
                        style={{
                            backgroundColor: getStatus(status).bg,
                            color: getStatus(status).font,
                        }}
                    >
                        {getStatus(status).message}
                    </div>
                )
            },
            {
                title: 'Invoice',
                dataIndex: 'invoice',
                align: 'center',
                width: '25%',
                render: (invoice, {payment_status}) => (
                    <Button
                        type='link'
                        href={invoice}
                        target='_blank'
                        disabled={payment_status !== 'succeeded'}
                        className={`${styles.invoice} ${!invoice ? styles.disabled : ''}`}
                        icon={<Download/>}
                    />
                )
            },
        ]
    ;


    return (
        <CustomTable
            columns={columns}
            data={history}
        />
    );
};

export default BillingHistoryTable;