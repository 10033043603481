import React, {useEffect, useState} from 'react';
import {InputNumber} from 'antd';
import {getAbsoluteElementPosition, getParentPosition, refreshParentGroup} from '../../../services/PropertiesService';

import styles from './PositionSizeControl.module.css';
import globalStyles from '../../GlobalGraphicEditor.module.css';

export default (props) => {
    const [positionX, setPositionX] = useState();
    const [positionY, setPositionY] = useState();

    const [elementWidth, setElementWidth] = useState();
    const [elementHeight, setElementHeight] = useState();

    const group = props.group;
    const element = props.element;
    const layoutPositioning = props.layoutPositioning;

    const showH = props.showH === undefined || props.showH === true;
    const showW = props.showW === undefined || props.showW === true;

    useEffect(() => {
        const stage = props.group.getStage();
        const canvasPosition = stage.findOne('#canvas_bg').position();
        const position = getAbsoluteElementPosition(group, canvasPosition);

        setPositionX(position.x);
        setPositionY(position.y);
        if (element) {
            setElementWidth(parseInt(element.getAttr('width')));
            setElementHeight(parseInt(element.getAttr('height')));

            //ToDo: bad solution. fast fix for lines;
            if (props.isLine) {
                setElementWidth(parseInt(element.getAttr('points')[2]));
            }
        }
    }, [element, group, props.refreshToggle]);

    const changePositionX = (value) => {
        const val = parseInt(value)
        if (isNaN(val)) {
            return;
        }

        const parentPosition = getParentPosition(group);
        group.x(val + group.getStage().findOne('#canvas_bg').x() - parentPosition.x)
        refreshParentGroup(group);
        setPositionX(val);
        propertyChanged();
    };

    const changePositionY = (value) => {
        const val = parseInt(value)
        if (isNaN(val)) {
            return;
        }

        const parentPosition = getParentPosition(group);
        group.y(val + group.getStage().findOne('#canvas_bg').y() - parentPosition.y)
        refreshParentGroup(group);
        setPositionY(val)
        propertyChanged();
    };

    const changeAndRedraw = (obj, property, val, setter, minVal) => {
        if (props.changeAndRedraw) {
            props.changeAndRedraw(obj, property, val, setter, minVal);
        } else {
            if (minVal === undefined || val >= minVal) {
                obj.setAttr(property, val)
                if (setter) setter(val);
            }
        }
    };

    const changeWidth = (value) => {
        if (parseInt(value) > 0) {
            if (!props.isLine) {
                changeAndRedraw(element, 'width', parseInt(value), setElementWidth, 1);
                if (props.calculateOffSet) {
                    element.setAttr('offset', {x: -value / 2, y: -elementHeight / 2});
                }
            } else {
                element.setAttr('points', [0, 0, parseInt(value), 0]);
                setElementWidth(parseInt(value));
            }

            propertyChanged();
        }
    };

    const changeHeight = (value) => {
        changeAndRedraw(element, 'height', parseInt(value), setElementHeight, 1);
        if (props.calculateOffSet) {
            element.setAttr('offset', {x: -elementWidth / 2, y: -value / 2});
        }
        propertyChanged();
    };

    const propertyChanged = () => {
        if (props.onPropertyChanged) {
            props.onPropertyChanged();
        }
    };

    return (
        <div className={styles.block}>
            <div className={styles.dataBlock}>
                <div className={styles.inputsBlock}>
                    <div className={styles.label}>X</div>
                    <InputNumber
                        className={`${styles.input} ${globalStyles.input}`}
                        size="small"
                        value={positionX}
                        onChange={changePositionX}
                        disabled={layoutPositioning !== 'absolute'}
                    />
                </div>
                <div className={styles.inputsBlock}>
                    <div className={styles.label}>Y</div>
                    <InputNumber
                        className={`${styles.input} ${globalStyles.input}`}
                        size="small"
                        value={positionY}
                        onChange={changePositionY}
                        disabled={layoutPositioning !== 'absolute'}
                    />
                </div>
            </div>
            <div className={styles.dataBlock}>
                {showW &&
                    <div className={styles.inputsBlock}>
                        <div className={styles.label}>W</div>
                        <InputNumber
                            className={`${styles.input} ${globalStyles.input}`}
                            size="small"
                            value={elementWidth}
                            onChange={changeWidth}
                        />
                    </div>
                }
                {showH &&
                    <div className={styles.inputsBlock}>
                        <div className={styles.label}>H</div>

                        <InputNumber
                            className={`${styles.input} ${globalStyles.input}`}
                            size="small"
                            value={elementHeight}
                            onChange={changeHeight}
                        />

                    </div>
                }
            </div>
        </div>
    );
}