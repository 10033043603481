import {axiosApi} from "../../../../axios-api";
import {message} from "antd";

export const deleteUsersFromOrganizations = async (organizationId, users) => {
    try {
        await axiosApi.delete(`v2/accounts/organizations/${organizationId}/users/`, {data: users});
    } catch (error) {
        if (error.isAxiosError) {
            return error;
        }
        throw new Error(error);
    } finally {
    }
}
export const editUsersRoleAndAccess = async (organizationId, userId, requestBody) => {
    try {
        const {data} = await axiosApi.patch(`v2/accounts/organizations/${organizationId}/${userId}/rights/`, requestBody);
        return data;
    } catch (error) {
        if (error.isAxiosError) {
            return error;
        }
        throw new Error(error);
    }
};

export const inviteUser = async (requestBody, setLoading) => {
    try {
        setLoading(true);
        await axiosApi.post('v2/accounts/invite/', requestBody);
        message.success(`Invitation to ${requestBody.emails[0]} successfully sent`);
    } catch (error) {
        if (error.isAxiosError) {
            message.warning({
                content: 'Inviting user failed, try again in a while',
                duration: 3,
            });
        } else {
            throw new Error(error);
        }
    } finally {
        setLoading(false);
    }
};