import React, {useEffect, useState} from 'react';
import {Tooltip} from "antd";

import zoomInIcon from '../../../svg/editor/controls/zoom-in.svg';
import zoomOutIcon from '../../../svg/editor/controls/zoom-out.svg';
import prevProductIcon from '../../../svg/editor/controls/prev-product.svg';
import nextProductIcon from '../../../svg/editor/controls/next-product.svg';
import undoIcon from '../../../svg/editor/controls/undo.svg';
import redoIcon from '../../../svg/editor/controls/redo.svg';

import './ControlsPanel.css';

export default (props) => {
    const zoomOut = () => {
        if (props.onZoomOut) {
            props.onZoomOut();
        }
    }

    const zoomIn = () => {
        if (props.onZoomIn) {
            props.onZoomIn();
        }
    }

    const prevProduct = () => {
        if (props.onPrevProduct) {
            props.onPrevProduct();
        }
    }

    const nextProduct = () => {
        if (props.onNextProduct) {
            props.onNextProduct();
        }
    }

    const undo = () => {
        if (props.onHistoryUndo) {
            props.onHistoryUndo();
        }
    }

    const redo = () => {
        if (props.onHistoryRedo) {
            props.onHistoryRedo();
        }
    }

    return (
        <div className='control-panel-main'>
            <Tooltip title='Zoom in'>
                <button onClick={zoomOut} className="control-panel-button">
                    <img src={zoomOutIcon} alt=""/>
                </button>
            </Tooltip>
            <Tooltip title='Zoom out'>
                <button onClick={zoomIn} className="control-panel-button">
                    <img src={zoomInIcon} alt=""/>
                </button>
            </Tooltip>
            <Tooltip title='Previous Product'>
                <button onClick={prevProduct} className="control-panel-button">
                    <img src={prevProductIcon} alt=""/>
                </button>
            </Tooltip>
            <Tooltip title='Next Product'>
                <button onClick={nextProduct} className="control-panel-button">
                    <img src={nextProductIcon} alt=""/>
                </button>
            </Tooltip>
            <Tooltip title='Undo changes or press Ctrl + Z'>
                <button disabled={props.history.index < 1}  onClick={undo} className="control-panel-button">
                    <img src={undoIcon} alt="" />
                </button>
            </Tooltip>
            <Tooltip title='Redo changes or press Ctrl + Y'>
                <button disabled={props.history.index === (props.history.items.length - 1)}  onClick={redo} className="control-panel-button">
                    <img src={redoIcon} alt="" />
                </button>
            </Tooltip>
        </div>
    );
};
