import React, {useEffect, useRef, useState} from 'react';
import {Button, Input, Popconfirm} from 'antd';
import {shortenString} from "../../../../helpers";
import {ReactComponent as RectangleIcon} from "../../../../svg/graphicEditor/rectangle-small.svg";
import {ReactComponent as EllipseIcon} from "../../../../svg/graphicEditor/ellipse-small.svg";
import {ReactComponent as PolygonIcon} from "../../../../svg/graphicEditor/polygon-small.svg";
import {ReactComponent as StarIcon} from "../../../../svg/graphicEditor/star-small.svg";
import {ReactComponent as LineIcon} from "../../../../svg/graphicEditor/line-small.svg";
import {ReactComponent as TextIcon} from "../../../../svg/graphicEditor/text-small.svg";
import {ReactComponent as ImageIcon} from "../../../../svg/graphicEditor/image-small.svg";
import {ReactComponent as EditIcon} from "../../../../svg/graphicEditor/edit-small.svg";
import {ReactComponent as CopyIcon} from "../../../../svg/graphicEditor/copy-small.svg";
import {ReactComponent as DeleteIcon} from "../../../../svg/graphicEditor/delete-small.svg";
import {ReactComponent as EyeIcon} from "../../../../svg/graphicEditor/eye-small.svg";
import {ReactComponent as EyeClosedIcon} from "../../../../svg/graphicEditor/eye-closed-small.svg";
import {ReactComponent as DownIcon} from "../../../../svg/icon/down-arrow.svg";

import styles from './GraphicLayersPanel.module.css';

export const LayerElement = (props) => {
    const [item, setItem] = useState(props.item);
    const [name, setName] = useState();
    const [isSelected, setIsSelected] = useState(props.isSelected);
    const [isEditing, setIsEditing] = useState(false);
    const [editName, setEditName] = useState();
    const ref = useRef();
    let timer;

    useEffect(() => {
        setItem(props.item)
    }, [props.item]);

    useEffect(() => {
        setIsSelected(props.isSelected);
    }, [props.isSelected]);

    useEffect(() => {
        if (item) {
            setName(props.item.title);
        }
    }, [item]);

    useEffect(() => {
        if (isEditing && ref.current) {
            ref.current.focus();
            if (editName === item.defaultName) {
                ref.current.select();
            }
        }
    }, [ref.current, isEditing]);

    const getElementIcon = (type) => {
        const iconsByType = {
            rect: <RectangleIcon/>,
            ellipse: <EllipseIcon/>,
            polygon: <PolygonIcon/>,
            star: <StarIcon/>,
            line: <LineIcon/>,
            image: <ImageIcon/>,
            text: <TextIcon/>,
            default: null,
        }
        return iconsByType[type] ?? iconsByType.default;
    };


    const editElementName = (e) => {
        setEditName(item.title);
        setIsEditing(true);
        e.stopPropagation();
    };

    const endEditElementName = (cancel = false) => {
        if (editName && !cancel && editName !== item.defaultName) {
            item.title = editName;
            setName(item.title);

            if (props.onNameChanged) {
                props.onNameChanged(item.key, item.title);
            }
        }
        setEditName('');
        setIsEditing(false);
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 27) {
            e.preventDefault();
            endEditElementName(true);
        }
        if (e.keyCode === 13) {
            e.preventDefault();
            endEditElementName();
        }
    };

    const copyElement = (e) => {
        if (props.onCopyElement) {
            props.onCopyElement(item);
        }
        e.stopPropagation();
    };

    const deleteElement = (e) => {
        if (props.onDeleteElement) {
            props.onDeleteElement(item);
        }
        e.stopPropagation();
    };

    const selectElement = (e) => {
        if (props.onSelectElement) {
            props.onSelectElement(e, item.key);
        }
    };

    const hideElement = (e) => {
        e.stopPropagation();
        props.onElementHide(item);
    };

    const toggleCollapse = (e, key, isCollapsed) => {
        e.stopPropagation();
        props.collapseToggle(key, isCollapsed);
    };

    const onClickHandler = e => {
        clearTimeout(timer);
        if (e.detail === 1) {
            let event = {...e};
            timer = setTimeout(() => {
                selectElement(event)
            }, 200)
        } else if (e.detail === 2) {
            editElementName(e)
        }
        e.stopPropagation();
    };

    return (
        <div
            id={props.item._id}
            onClick={selectElement}
            className={`
                        ${styles.layersElement}
                        ${isSelected || props.selectedByRelative ? styles.selectedElement : ''}
                        ${isSelected && props.selectedByRelative ? styles.selectedElementInGroup : ''}
                        ${item.parentKey ? styles.childrenElement : ''}
                        ${item.collapsed ? styles.elementCollapsed : ''}
                      `}
        >
            <div className={styles.iconBlock}>
                {getElementIcon(item.elementType)}
                {!!item?.children.length &&
                    <Button
                        icon={<DownIcon className={`${styles.arrowDown} ${item.parentCollapsed ? styles.arrowUp : ''}`}/>}
                        shape='circle'
                        type='text'
                        onClick={(e) => toggleCollapse(e, item.key, !item.parentCollapsed)}
                    />
                }
            </div>

            <div className={styles.elementBlock}>
                <div className={styles.nameElement}>
                    {!isEditing &&
                        <div onClick={onClickHandler}>
                            {name && shortenString(name, 15)}
                        </div>
                    }
                    {isEditing &&
                        <Input
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                            ref={ref}
                            onKeyDown={onKeyDown}
                            value={editName} size="small"
                            onChange={(e) => {
                                setEditName(e.target.value)
                            }}
                            onBlur={() => endEditElementName(false)}
                            className={styles.input}
                        />
                    }
                </div>
                <div className={styles.buttonsBlock}>
                    {!isEditing &&
                        <Button
                            type='text'
                            icon={<EditIcon/>}
                            onClick={editElementName}
                            className={`${styles.editButton} ${styles.sideButton}`}
                        />
                    }
                    <Button
                        type='text'
                        icon={item.field.attrs.opacity === 0 ? <EyeClosedIcon/> : <EyeIcon/>}
                        className={styles.sideButton}
                        onClick={hideElement}
                    />
                    <Button
                        type='text'
                        icon={<CopyIcon/>}
                        onClick={copyElement}
                        className={styles.sideButton}
                    />
                    <Popconfirm
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        title={`Delete element "${item.title}"?`}
                        onConfirm={deleteElement}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <Button
                            type='text'
                            icon={<DeleteIcon/>}
                            className={styles.sideButton}

                        />
                    </Popconfirm>
                </div>
            </div>

        </div>
    );
};