import React, {useState} from 'react';
import {Form, Input} from "antd";
import MyButton from "../../shared/MyButton/MyButton";

import './Login.css';

const AuthForm = ({onFinish, isRegister = false, disabled = false, errors = null}) => {
    const [isDisabled, setIsDisabled] = useState(false);

    return (
        <div>
            <Form
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={(values) => {
                    setIsDisabled(true);
                    onFinish(values).finally(() => setIsDisabled(false));
                }}
                requiredMark={false}
                disabled={disabled}
            >
                <Form.Item
                    autoComplete="off"
                    className='login-input-label'
                    label="Login / e-mail"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                    validateStatus={errors?.username ? "error" : ""}
                    help={errors?.username}
                >
                    <Input className="login-input-field"/>
                </Form.Item>
                <Form.Item
                    className='login-input-label'
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                    validateStatus={errors?.password ? "error" : ""}
                    help={errors?.password}
                >
                    <Input.Password className="login-input-field"/>
                </Form.Item>
                {isRegister &&
                    <Form.Item
                        className='login-input-label'
                        label="Confirm password"
                        name="confirmPassword"
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                        ]}
                        validateStatus={errors?.confirmPassword ? "error" : ""}
                        help={errors?.confirmPassword}
                    >
                        <Input.Password className="login-input-field"/>
                    </Form.Item>
                }
                <div className='login-button-block'>
                    <Form.Item>
                        <MyButton
                            htmlType="submit"
                            className='login-button'
                            text={isRegister ? 'Register' : 'Login'}
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </div>

            </Form>
        </div>
    );
};

export default AuthForm;
