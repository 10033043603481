import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message, Input, Select, Button, Typography, Spin, Checkbox } from 'antd';
import { axiosApi } from '../../axios-api';
import {
  getProductFieldDisplayName,
  getProductFieldTextDisplayName,
} from '../services/ProductHelperService';
import {
  validateMapping,
  validateXmlMapping,
  changeFieldsDisplayNameWithId,
  changeFieldsIdWithDisplayName,
} from './services/ValidateService';
import { useQuery } from '../../helpers';
import MappingControl from './components/MappingControl';
import { ReactComponent as BackIcon } from 'svg/icon/left-arrow-short.svg';
import facebookIcon from 'svg/common/feeds/fb.png';
import googleIcon from 'svg/common/feeds/google.png';
import yaIcon from 'svg/common/feeds/ya.png';
import mytargetIcon from 'svg/common/feeds/mytarget.png';
import { FrownOutlined } from '@ant-design/icons';

import styles from './MappingPage.module.css';
const { Text } = Typography;

export default (props) => {
    const selectIcon = {
        width: '16px',
        height: '16px',
        marginRight: '8px',
    };

    const urlHistory = useHistory();
    const query = useQuery().get('organization');
    const organizationQuery = query ? `?organization=${query}` : '';

    const api = props.backend;
    const projectId = props.match.params.project_id;
    const mappingId = props.match.params.mapping_id === 'new' ? undefined : props.match.params.mapping_id;

    const [name, setName] = useState();
    const [project, setProject] = useState();
    const [ruleId, setRuleId] = useState();
    const [productFields, setProductFields] = useState();
    const [mappings, setMappings] = useState([]);
    const [feedTypes, setFeedTypes] = useState([]);
    const [formatType, setFormatType] = useState();
    const [formatTypes, setFormatTypes] = useState([]);
    const [selectedFeedTypeId, setFeedTypeId] = useState();
    const [clickedOnSave, setClickedOnSave] = useState(false);
    const [feedTemplate, setFeedTemplate] = useState();
    const [mappingData, setMappingData] = useState(null);
    const [loading, setLoading] = useState(false);
    const feedTemplateText = 'Map the fields between the data source and output feed you want.';
    const unsupportedFeedTemplateText = 'Given feed template is not supported. Please try to choose another feed type from the select field.';

    const [showCheckbox, setShowCheckbox] = useState(false); // State variable for checkbox visibility
    const [checkboxValue, setCheckboxValue] = useState(false); // State variable for checkbox value

  const changeName = (value) => {
        setName(value);
    }

    const init = () => {
        loadFeedTypes();
        loadProject(projectId);
    }

    const createNewFeedMappingFields = (feed, formatType) => {
        if (!feed) {
            return;
        }

        if (feed.fields) {
            const mappings = feed.fields.filter(t => t.required === true).map(t => {
                const defaultValues = t.default ?? [];
                let defaultValue = '';
                for (var i = 0; i < defaultValues.length; i++) {
                    const value = defaultValues[i];
                    const existField = productFields.find(field => field.id === value);
                    if (existField) {
                        defaultValue = changeFieldsIdWithDisplayName(`{{${existField.id}}}`, productFields);
                        break;
                    }
                }
                
                return {
                    feedFieldId: t.name,
                    value: defaultValue,
                    required: true,
                    readonly: t.readonly && defaultValue,
                    error: '',
                    type: 'field'
                }
            });

            mappings.push({
                feedFieldId: undefined,
                value: '',
                required: false,
                readonly: false,
                error: '',
                type: 'field'
            });
            setMappings(mappings);
        }
    }

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (feedTypes && feedTypes.length && !mappingId) {
            setFeedTypeId(feedTypes[0].id);
        }

        if (feedTypes && feedTypes.length && mappingId && productFields) {
            loadMapping();
        }
    }, [feedTypes, productFields, mappingId]);

    useEffect(() => {
        if (project && project.feed?.feed_fields) {
            const fields = JSON.parse(project.feed.feed_fields);
            const array = Object.keys(fields).filter(f => f.indexOf('_changed') === -1 && f !== 'fresh').map(f => {
                return {
                    name: getProductFieldDisplayName(f, fields[f]),
                    displayName: getProductFieldTextDisplayName(f, fields[f], true), 
                    id: f,
                    type: fields[f]
                }
            })
            
            array.push(createAdditionalfield('new_image_link', '[new] First image', '[new] First image'));
            if (!isXmlFormat()) {
                array.push(createAdditionalfield('new_additional_image_link', '[new] Other images', '[new] Other images'));
            }
            array.push(createAdditionalfield('new_image_link_[N]', '[new] Image', '[new] Image 1', true));
            setProductFields(array);
        }
    }, [project]);
    useEffect(() => {
        if (selectedFeedTypeId && feedTypes) {
          const feed = feedTypes.find(type => type.id === selectedFeedTypeId);

          if (feed) {
            setFeedTemplate(feed);
            const formatTypes = feed.formatTypes;
            const formatType = formatTypes[0];
            setFormatTypes(formatTypes);
            setFormatType(formatType);
            if (!mappingId || selectedFeedTypeId !== mappingData.preset_name) {
              createNewFeedMappingFields(feed, formatType);
            }
            } else {
            setFormatType('Not supported');
          }

          if (["yandex_market_simple",
             "yandex_market_advanced",
             "mytarget_xml_simple",
             "mytarget_xml_advanced"].indexOf(selectedFeedTypeId) > -1) {
                setShowCheckbox(true);
            } else {
                setShowCheckbox(false);
            }
        }
    }, [selectedFeedTypeId]);

    const changeFormatType = (value) => {
        setFormatType(value);
    }

    const createAdditionalfield = (id, name, displayName, dynamics = false, type = 'url') => {
        return {
            id: id,            
            name: name,
            displayName: displayName,            
            type: type,
            dynamics: dynamics
        }
    }

    const loadMapping = async () => {
        try {
            setLoading(true);
            const result = await api.fetchMapping(projectId, mappingId);
            if (result) {
                setName(result.name);
                setMappingData(result);
                const mappingFields = JSON.parse(result.mapping_json) ?? {};
                const feed = feedTypes.find(type => type.id === result.preset_name);
                setFeedTypeId( result.preset_name);
                setCheckboxValue(result?.add_params);

              const fields = [];

                Object.keys(mappingFields).forEach(mapping => {
                    if (mapping === 'param') {
                        Object.keys(mappingFields[mapping]).forEach(attr => {
                            fields.push({
                                feedFieldId: 'param',
                                value: mappingFields[mapping][attr],
                                attribute: attr,
                                required: false,
                                readonly: false,
                                error: '',
                                type: 'param'
                            });
                        })
                    } else {
                        const feedField = feed?.fields.find(item => item.name === mapping);
                        const fieldValue = mappingFields[mapping] ?? '';
                        const defaultValues = feedField?.default ?? [];

                        if (feedField && feedField.multiUse && Array.isArray(fieldValue)) {
                            for (let i = 0; i < fieldValue.length; i++) {
                                const value = changeFieldsIdWithDisplayName(fieldValue[i], productFields);
                                fields.push({
                                    feedFieldId: mapping,
                                    value: value,
                                    required: !!feedField.required === true && i === 0,
                                    readonly: !!feedField.readonly === true && defaultValues.map(value => `{{${value}}}`).includes(fieldValue),
                                    error: '', type: 'field'
                                });
                            }
                        } else {
                            const value = changeFieldsIdWithDisplayName(fieldValue, productFields);
                            fields.push({
                                feedFieldId: mapping,
                                value: value,
                                required: !!feedField?.required === true,
                                readonly: !!feedField?.readonly === true && defaultValues.map(value => `{{${value}}}`).includes(fieldValue),
                                error: '', type: 'field'
                            });
                        }
                    }
                });

                setMappings(fields);
            }
        } catch (error) {
            message.error(error);
        } finally {
          setLoading(false);
        }
    };

    const loadProject = async () => {
        try {
          setLoading(true);
          const result = await api.fetchProject(projectId);
            setProject(result);
            setRuleId(result.rule.id);
        } catch (err) {
            props.history.push('/');
            message.error(err.message);
        } finally {
          setLoading(false);
        }
    };

    const loadFeedTypes = async () => {
        try {
            const result = await api.fetchFeedTypes();
            setFeedTypes(result);
        } catch (err) {
            message.error(err.message);
        }
    };


    const changeFeedType = (value) => {
        setFeedTypeId(value);
        console.log(value)

        // Update showCheckbox based on certain options
        if (["yandex_market_simple",
             "yandex_market_advanced",
             "mytarget_xml_simple",
             "mytarget_xml_advanced"].indexOf(value) > -1) {
            setShowCheckbox(true);
        } else {
            setShowCheckbox(false);
            setCheckboxValue(false); // Reset checkbox value when hiding it
        }
    }

    const onMappingValueChange = (mapping, value) => {
        if (mapping) {
            mapping.value = value;
        }
    }

    const getIcon = (iconType) => {
        switch (iconType) {
            case 'google':
                return <img style={selectIcon} alt="" src={googleIcon}/>;
            case 'facebook':
                return <img style={selectIcon} alt="" src={facebookIcon}/>;
            case 'yandex':
                return <img style={selectIcon} alt="" src={yaIcon}/>;
            case 'mytarget':
                return <img style={selectIcon} alt="" src={mytargetIcon}/>;
            default:
                return null;
        }
    }

    const validateAndSetMappings = (mappings, clickedOnSave) => {
        mappings.forEach(t => {
            t.error = t.type === 'param' ? validateXmlMapping(t, productFields ?? []) : validateMapping(t, productFields ?? [], clickedOnSave);
        })
        setMappings([...mappings]);
    }

    const addNewMapping = (type = 'field') => {
        mappings.push({
            feedFieldId: undefined,
            value: '',
            required: false,
            type: type
        });

        //validateAndSetMappings([...mappings], clickedOnSave);
        setMappings([...mappings]);
    }

    const isXmlFormat = () => {        
        return formatType === 'yml';
    }

    const deleteMapping = (mapping) => {
        const newMapping = mappings.filter(t => t !== mapping);
        validateAndSetMappings([...newMapping], clickedOnSave);
    }
    
    const exit = () => {
      urlHistory.push( `/project/${projectId}/rule/${ruleId}${organizationQuery}`);
    }

    const saveMapping = async () => {
        try {
            setClickedOnSave(true);
            validateAndSetMappings([...mappings], true);

            if (!name) {
                message.error('Enter feed name!');
                return;
            }

            if (!selectedFeedTypeId) {
                message.error('Enter feed template!');
                return;
            }

            if (!!mappings.find(t => !!t.error)) {
                message.error('Mapping fields have errors!');
                return;
            }

            const mappingFields = createTransferObject();

            const url = `/projects/${projectId}/mappings/${mappingId ? `${mappingId}/` : ''}`;
            const method = mappingId ? 'PUT' : 'POST';

           const {data} = await axiosApi({
                method,
                url,
                data: mappingFields,
            });
            message.success("Saved!");
            if (!mappingId ) {
              urlHistory.push(`/project/${projectId}/mapping/${data.id}${organizationQuery}`);
            }

            init();
        } catch (error) {
            message.error(error);
        }
    };

    const createTransferObject = () => {    
        const obj = {
            name: name,
            preset_name: selectedFeedTypeId,
            format_type: formatType ?? 'csv',
            mapping_json: {}
        };
        const mapping = {};
        mappings.length && mappings.forEach(t => {
            if (t.type !== 'param') {
                if (t.feedFieldId) {                    
                    const feedField = feedTemplate.fields.find(f => f.name === t.feedFieldId);
                    const fieldValue = changeFieldsDisplayNameWithId(t.value, productFields);
                    if (feedField.multiUse) {
                        const exist = mapping[t.feedFieldId];
                        if (!exist) {
                            mapping[t.feedFieldId] = [];
                        }
                        mapping[t.feedFieldId].push(fieldValue);
                    } else {
                        mapping[t.feedFieldId] = fieldValue;
                    }
                }
            } else if (t.attribute !== '' && t.value !== '') {
                if (!mapping.param) {
                    mapping.param = {};
                }
                mapping.param[t.attribute] = t.value;
            }
        });

        obj.add_params = showCheckbox ? checkboxValue : false;

        obj.mapping_json = JSON.stringify(mapping);

        return obj;
    }

  return (
    <div className={`scroll-panel scroll-panel-standart ${styles.container}`}>
      {loading && !feedTemplate ?
        <div className='centered-container'>
          <Spin size='large'/>
        </div>
        : <>
        <div className={styles.header}>
          <Button shape="circle" type="text" onClick={exit} icon={<BackIcon/>}/>
          <h3 className={styles.title}>Mappings</h3>
        </div>
        <div className={styles.main}>
          <div
            className={`${styles.flexContainer} ${styles.upperInputsBlock}`}
          >
            <Input
              value={name}
              onChange={(e) => {
                changeName(e.target.value);
              }}
              placeholder="Feed template name"
              className={`${styles.input} ${styles.centeredInput}`}
            />
            <Select
              value={selectedFeedTypeId}
              onChange={changeFeedType}
              className={`${styles.input} ${styles.centeredInput} ${styles.select} ${!feedTemplate ? styles.errorSelect : ''}`}
            >
              {feedTypes.map((feed) => (
                  <Select.Option key={feed.id} value={feed.id}>
                    <div className={styles.flexContainer}>
                      {getIcon(feed.icon)}
                      <span>{feed.name}</span>
                    </div>
                  </Select.Option>
                ))}
            </Select>

            <Select
              value={formatType}
              onChange={changeFormatType}
              disabled={formatTypes.length <= 1}
              className={`${styles.input} ${styles.centeredInput} ${styles.select}`}
            >
              {formatTypes.length && formatTypes.map((format, i) => {
                return (
                  <Select.Option key={`field-${i}`} value={format}>
                    {format}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          <Text className={styles.description}>
            {feedTemplate ? feedTemplateText : unsupportedFeedTemplateText}
          </Text>

          {showCheckbox && ( // Conditionally render the checkbox
              <Checkbox
                checked={checkboxValue}
                onChange={(e) => setCheckboxValue(e.target.checked)}
                className={`${styles.input} ${styles.centeredInput}`}
                style={{ marginTop: '20px', height: 'inherit !important', maxWidth: '600px', border: '0' }}
              >
                Auto add original params (or rest of params If some are used in feed template)
              </Checkbox>
          )}

          {feedTemplate ? (
            <MappingControl
              isXmlFormat={isXmlFormat()}
              onChange={onMappingValueChange}
              mappings={mappings}
              feedFields={feedTemplate.fields}
              productFields={productFields}
              paramAttributes={feedTemplate.paramAttributes}
              clickedOnSave={clickedOnSave}
              addNewMapping={addNewMapping}
              deleteMapping={deleteMapping}
              save={saveMapping}
            />
          ) : <FrownOutlined className={styles.emptyFeedTemplateIcon}/>}
        </div>
      </>}
    </div>
  );
};
