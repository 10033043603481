import React from "react";
import {useHistory} from "react-router-dom";
import {SmileOutlined} from "@ant-design/icons";
import MyButton from "../../shared/MyButton/MyButton";

import styles from './NotFound.module.css';

export default function NotFound() {
    const history = useHistory();
    return (
        <div className={styles.block} >
            <SmileOutlined style={{fontSize: 20}}/>
            <p> Not Found</p>
            <MyButton
                text='Return to home page'
                onClick={() => history.push('/')}
                className={styles.backButton}
            />
        </div>
    );
}