import React, {useState, useEffect, useRef} from 'react';
import {AutoComplete, Select, Tag} from "antd";
import { isAutoField } from '../../services/ProductHelperService';
import { v4 as uuidv4 } from 'uuid';

import styles from '../MappingPage.module.css';

const { Option } = AutoComplete;

const SelectorTypes = {
    frendly: 0,
    simple: 1,
    simpleWithBrackets: 2    
};

const selectorType = SelectorTypes.frendly;

export default (props) => {
    const [options, setOptions] = useState([]);
    const [ruleString, setRuleString] = useState('');
    const [fields, setFields] = useState([]);

    useEffect(() => {
        setFields(props.fields);
    }, [props.fields]);

    useEffect(() => {
        setRuleString(props.value);        
    }, [props.value]);

    const id = uuidv4();
    const input = useRef(id);

    const onSearch = (searchString) => {
        if (!searchString && !ruleString) {
            return;
        }
        const caret = getCaret();

        const leftString = searchString.substring(0, caret);
        const startIndex = leftString.lastIndexOf('{{');

        const searchText = startIndex !== -1 && caret !== -1 && startIndex < caret && leftString.substring(startIndex + 2, leftString.length);

        if (startIndex >= 0) {
            if (selectorType === SelectorTypes.simple) {
                setOptions([...fields.map(t => t.id).filter(t => t.indexOf(searchText) >= 0).map(t => t)]);
            } else if (selectorType === SelectorTypes.simpleWithBrackets) {
                setOptions([...fields.map(t => t.id).filter(t => t.indexOf(searchText) >= 0).map(t => `{{${t}}}`)]);
            } else {
                setOptions([...fields.filter(t => t.name.indexOf(searchText) >= 0 || t.id.indexOf(searchText) >= 0)]);
            }
        } else {
            setOptions([]);
        }

        setRuleString(searchString);
        props.onChange(searchString);
    }

    const getCaret = () => {
        const element = document.getElementById('inputElementId' + input.current);
        return element.selectionEnd;
    }

    const setCaret = (value) => {
        const element = document.getElementById('inputElementId' + input.current);
        setTimeout(t => {
            element.selectionStart = value;
            element.selectionEnd = value;
        });
    }

    const onSelect = (value) => {
        const caret = getCaret();

        const stringBeforeCaret = ruleString.substring(0, caret);

        let leftString = ruleString.substring(0, stringBeforeCaret.lastIndexOf('{{'));
        let rightString = ruleString.substring(caret, ruleString.length);

        const rightStringOpen = rightString.indexOf('{{');
        const rightStringClose = rightString.indexOf('}}');
        const rightIndex = rightStringClose === -1 ? -1 : (rightStringClose < rightStringOpen || rightStringOpen === -1) ? rightStringClose : -1;
        if (rightIndex >= 0) {
            rightString = rightString.substring(rightIndex + 2, rightString.length);
        }

        const field = fields.find(t => t.id === value);        

        let valueWithBrackets = `${field.displayName}}}`;                
        if (!leftString.endsWith('{{')) {
            valueWithBrackets = `{{${valueWithBrackets}`;
        }
        const resultValue = leftString + valueWithBrackets + rightString;

        setRuleString(resultValue);        
        setCaret(resultValue.indexOf(valueWithBrackets) + valueWithBrackets.length);

        setOptions([]);
        props.onChange(resultValue);
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 27) {            
            e.preventDefault();            
        }
    }

    const inputElementId = `inputElementId${input.current}`;

    return (        
        <AutoComplete
            disabled={props.disabled}
            className={`${styles.column} ${styles.sourceField} ${styles.centeredInput}`}
            value={ruleString}
            onSearch={onSearch}
            onKeyUp={() => onSearch(ruleString)}
            onSelect={onSelect}
            size="small"
            id={inputElementId}
            onKeyDown={onKeyDown}
            >            
            {(selectorType === SelectorTypes.simple || selectorType === SelectorTypes.simpleWithBrackets) &&
                options.map((option) => (
                <Option key={option} value={option}>
                    {option}
                </Option>
            ))}
            {selectorType === SelectorTypes.frendly && options.map((option, i) => (
                 <Select.Option key={`field-${i}`} value={option.id}>
                   <div className={styles.flexContainer}>
                     {isAutoField(option.id) &&
                         <Tag color="gold" className={styles.tag}>auto</Tag>
                     }
                     {!!option.type && <Tag color="cyan">{option.type}</Tag>}
                     <div>{option.name}</div>
                     {option.dynamics === true && <strong>&nbsp;N</strong>}
                   </div>
                 </Select.Option>
            ))}
        </AutoComplete>
    );
};
