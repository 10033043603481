import React, { useEffect, useState } from 'react';
import { Button, InputNumber, Typography } from 'antd';

import { ReactComponent as MarginTopIcon } from 'svg/graphicEditor/alone-margin-top.svg';
import { ReactComponent as MarginBottomIcon } from 'svg/graphicEditor/alone-margin-bottom.svg';
import { ReactComponent as MarginLeftIcon } from 'svg/graphicEditor/alone-margin-left.svg';
import { ReactComponent as MarginRightIcon } from 'svg/graphicEditor/alone-margin-right.svg';
import { ReactComponent as PlusIcon } from 'svg/graphicEditor/plus-big-black.svg';
import { ReactComponent as MinusIcon } from 'svg/graphicEditor/minus-big-black.svg';

import styles from './DynamicBoxControl.module.css';
import globalStyles from '../../GlobalGraphicEditor.module.css';

const { Text } = Typography;

export default function (props) {
  const [toggleSubstrate, setToggleSubstrate] = useState(false);
  const [substrateTop, setSubstrateTop] = useState(0);
  const [substrateRight, setSubstrateRight] = useState(0);
  const [substrateBottom, setSubstrateBottom] = useState(0);
  const [substrateLeft, setSubstrateLeft] = useState(0);

  const { element } = props;

  useEffect(() => {
    setToggleSubstrate(!!element.getAttr('substrateToggle'));
    setSubstrateTop(element.getAttr('substrateTop'));
    setSubstrateRight(element.getAttr('substrateRight'));
    setSubstrateBottom(element.getAttr('substrateBottom'));
    setSubstrateLeft(element.getAttr('substrateLeft'));
  }, [element]);

  const changeToggleSubstrate = () => {
    if (props.changeAndRedraw) {
      props.changeAndRedraw(
        element,
        'substrateToggle',
        !toggleSubstrate,
        setToggleSubstrate,
      );
    }
  };

  const changeSubstrateTop = (value) => {
    value = parseInt(value);
    if (
      props.changeAndRedraw &&
      value + substrateBottom < props.background.height()
    ) {
      props.changeAndRedraw(element, 'substrateTop', value, setSubstrateTop, 0);
    }
  };

  const changeSubstrateRight = (value) => {
    value = parseInt(value);
    if (
      props.changeAndRedraw &&
      value + substrateLeft < props.background.width()
    ) {
      props.changeAndRedraw(
        element,
        'substrateRight',
        value,
        setSubstrateRight,
        0,
      );
    }
  };

  const changeSubstrateBottom = (value) => {
    value = parseInt(value);
    if (
      props.changeAndRedraw &&
      value + substrateTop < props.background.height()
    ) {
      props.changeAndRedraw(
        element,
        'substrateBottom',
        value,
        setSubstrateBottom,
        0,
      );
    }
  };

  const changeSubstrateLeft = (value) => {
    value = parseInt(value);
    if (
      props.changeAndRedraw &&
      value + substrateRight < props.background.width()
    ) {
      props.changeAndRedraw(
        element,
        'substrateLeft',
        value,
        setSubstrateLeft,
        0,
      );
    }
  };

  return (
    <div>
      <div className={globalStyles.spaceBetweenBlock}>
        <Text className={globalStyles.smallTitle}>Dynamic Box</Text>
        <Button
          shape='circle'
          type='text'
          icon={toggleSubstrate ? <MinusIcon /> : <PlusIcon />}
          onClick={changeToggleSubstrate}
        />
      </div>
      {toggleSubstrate && (
        <div
          className={`${globalStyles.innerBlock} ${globalStyles.selectBlock} ${globalStyles.gapBlock}`}
        >
          <Text className={globalStyles.text}>Set padding:</Text>
          <div
            className={`${globalStyles.selectBlock} ${styles.dynamicBoxBlock}`}
          >
            <div
              className={`${globalStyles.spaceBetweenBlock} ${styles.dynamicBoxBlock}`}
            >
              <InputNumber
                size='small'
                prefix={<MarginTopIcon />}
                value={substrateTop}
                onChange={changeSubstrateTop}
                min={0}
                className={`${globalStyles.input}`}
              />
              <InputNumber
                size='small'
                prefix={<MarginBottomIcon />}
                value={substrateBottom}
                onChange={changeSubstrateBottom}
                min={0}
                className={`${globalStyles.input}`}
              />
            </div>

            <div
              className={`${globalStyles.spaceBetweenBlock} ${styles.dynamicBoxBlock}`}
            >
              <InputNumber
                size='small'
                prefix={<MarginLeftIcon />}
                value={substrateLeft}
                onChange={changeSubstrateLeft}
                min={0}
                className={`${globalStyles.input}`}
              />
              <InputNumber
                size='small'
                prefix={<MarginRightIcon />}
                value={substrateRight}
                onChange={changeSubstrateRight}
                min={0}
                className={`${globalStyles.input}`}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
