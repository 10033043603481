import { formatNumbers } from '../../../../helpers';
export const updateBillingPlansFeatures = (givenBillingPlan) => {
  return givenBillingPlan.map((billing) => {
    const features = [];
    if (billing.daily_updates) features.push('Daily updates');
    if (billing.premium_support) features.push('Premium support');
    features.push(
      `Up to ${formatNumbers(billing.img_count)} image generations per month`,
    );
    return { ...billing, features };
  });
};
