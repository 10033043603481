import React from 'react';
import EditableOrganizationName from "../EditableOrganizationName/EditableOrganizationName";
import ManagerToggle from "../ManagerToggle/ManagerToggle";
import {ReactComponent as Arrow} from '../../svg/icon/arrow.svg';
import MyButton from "../shared/MyButton/MyButton";
import {Typography} from "antd";

import styles from "./ManagersNavigationBar.module.css"

const {Text} = Typography
const ManagersNavigationBar = ({
                                   isManagementSelected,
                                   setIsManagementSelected,
                                   organizationName,
                                   submitOrganizationName,
                                   isOrganizationCreation,
                                   isOrganizationNameOpen,
                                   organizationNamePlaceholder,
                                   handleClick,
                                   disabled,
                                   generationAllowed,
                                   notice_code,
                                   nameLoading,
                               }) => {


    return (
        <div className={styles.block}>
            <EditableOrganizationName
                value={organizationName}
                onSubmit={submitOrganizationName}
                isOrganizationCreation={isOrganizationCreation}
                isInputOpen={isOrganizationNameOpen}
                placeholder={organizationNamePlaceholder}
                disabled={generationAllowed}
                nameLoading={nameLoading}
            />
            <div className={styles.rightBlock}>
                <ManagerToggle
                    selected={isManagementSelected}
                    toggleSelected={setIsManagementSelected}
                    disabled={notice_code === 3 || notice_code === 0 || disabled}
                />
                <MyButton
                    customStyles={{
                        backgroundColor: notice_code === 0 || disabled ? '#9E9E9E' : '#5EB067',
                        maxWidth: '178px',
                        padding: '0 23px',
                        height: '44px'
                    }}
                    onClick={handleClick}
                    disabled={notice_code === 0 || disabled}
                >
                    <div className={styles.buttonBlock}>
                        <Text className={styles.goButton}>Go to projects</Text>
                        <Arrow/>
                    </div>
                </MyButton>
            </div>
        </div>
    );
};

export default ManagersNavigationBar;