import React, { createContext, useState, useEffect, useContext } from 'react';

const WS_URL = `wss://${process.env.REACT_APP_BACKEND_HOST}/ws/generation/`;

export const SocketContext = createContext({
  socket: null,
  projectIds: [],
  data: null,
  metaData: []
});

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [projectIds, setProjectIds] = useState([]);
  const [data, setData] = useState({});
  const [metaData, setMetaData] = useState([]);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const maxReconnectAttempts = 2;

  useEffect(() => {
    const projectIdsLength = projectIds.length;

    if (projectIdsLength === 0) return;

    const connectSocket = () => {
      const newSocket = new WebSocket(WS_URL);

      newSocket.onopen = () => {
        setSocket(newSocket);
      };

      newSocket.onmessage = (event) => {
        const message = Object.entries(JSON.parse(event.data));

        setData((prev) => {
          const newData = message.reduce((acc, [key, value]) => {
            if (key in prev) {
              acc[key] = { ...value, outputs: { ...prev[key].outputs, ...value.outputs } };
            } else {
              acc[key] = value;
            }
            return acc;
          }, {});

          return { ...prev, ...newData };
        });
      };

      newSocket.onclose = () => {
        if (reconnectAttempts < maxReconnectAttempts) {
          setReconnectAttempts((prev) => prev + 1);
          setTimeout(connectSocket, 5000);
        }
      };

      newSocket.onerror = () => {
        newSocket.close();
      };
    };
    connectSocket();

    return () => socket?.close();
  }, [projectIds]);

  useEffect(() => {
    if (socket && socket.readyState === WebSocket.OPEN && projectIds.length !== 0) {
      socket.send(JSON.stringify({ project_ids: projectIds }));
    }
  }, [socket, projectIds]);

  const updateSocketMetaData = (metaData) => {
    const projectIds = metaData.flatMap(organization => organization.projects_filtered_by_id).map(project => project.id);

    setMetaData(metaData);
    setProjectIds(projectIds);
  };

  return (
    <SocketContext.Provider value={{ socket, projectIds, data, metaData, setProjectIds, updateSocketMetaData }}>
      {children}
    </SocketContext.Provider>
  );
};

export const SocketInitializer = ({ children, organizations, selectedProject = null }) => {
  const { updateSocketMetaData, setProjectIds } = useWebSocket();
  
  useEffect(() => {
    const result = organizations.map(organization => ({
      id: organization.id,
      name: organization.name,
      projects_filtered_by_id: organization.projects,
    }));

    updateSocketMetaData(result);

  }, [organizations]);

  useEffect(() => {
    if (selectedProject) {
      setProjectIds([selectedProject]);
    }
  }, [selectedProject]);

  return children;
};

export const useWebSocket = () => {
  return useContext(SocketContext);
};
