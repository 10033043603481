import React from 'react';
import {Empty, Popconfirm} from 'antd';

import Header from '../properties/PropertiesHeader/PropertiesHeader';
import { shortenString } from '../../../../helpers';
import { ReactComponent as RemoveIcon } from 'svg/icon/trash-small.svg';

import styles from './ImageGallery.module.css';

export default function (props) {
  const imageLoadedHandler = (event) => {
    const image = event.target;
    if (image && image.width && image.height) {
      const ration = image.height / image.width;
      if (ration > 1) {
        image.style.width = '100%';
        image.style.height = 'auto';
      } else {
        image.style.width = 'auto';
        image.style.height = '100%';
      }

      image.style.opacity = '1';
    }
  };

  const removeImage = (event, id, url) => {
    event.stopPropagation();
    props.deleteImage(id, url);
  };

  const userImages = [...props.userImages];

  return (
    <div className={styles.main}>
      <Header onClose={props.onClose} caption='Image Gallery' />
      <div className={`${styles.imageList} scroll-panel`}>
        {userImages.length ? userImages.map((image) => (
          <div
            onClick={() => props.setLibraryImage(image.id)}
            key={image.id}
            className={styles.imageBlock}
            role='presentation'
          >
            <div className={styles.imageContainer}>
              <div className={styles.imageWrapper}>
                <img src={image.image} onLoad={imageLoadedHandler} alt='' />
              </div>
              <Popconfirm
                title={
                  <div className={styles.popOver}>
                    You will not be able to download or use in ANY projects this
                    image with link {image.name} ({image.id}).
                    <br />
                    Are you sure you want to delete this image?
                  </div>
                }
                onClick={(e) => e.stopPropagation()}
                onConfirm={(e) => {
                  removeImage(e, image.id, image.url);
                }}
                onCancel={(e) => e.stopPropagation()}
                okText='Yes'
                cancelText='No'
              >
                <div className={styles.deleteButton}>
                  <RemoveIcon />
                </div>
              </Popconfirm>
            </div>
            <div className={styles.imageName}>
              {shortenString(image.name, 10)} ({image.id})
            </div>
          </div>
        )):
            <div className={`loading-container ${styles.emptyBlock}`}>
              <Empty description='There are no saved images, please press "upload" button'/>
            </div>
        }
      </div>
    </div>
  );
}
