import React from 'react';

import Appearance from '../GroupAppearance/GroupAppearance';
import Conditions from '../ConditionGroup/PropertyConditions';

export default ({
  defaultProps,
  distributeGroup,
  destroyDistributedGroup,
  scaleText,
}) => {
  return (
    <>
      {defaultProps.activeTab === 1 && (
        <Appearance
          group={defaultProps.group}
          product={defaultProps.product}
          refreshToggle={defaultProps.refreshToggle}
          detachElement={defaultProps.detachElement}
          attachToElement={defaultProps.attachToElement}
          onPropertyChanged={defaultProps.onPropertyChanged}
          scaleText={scaleText}
          distributeGroup={distributeGroup}
          destroyDistributedGroup={destroyDistributedGroup}
        />
      )}
      {defaultProps.activeTab === 3 && (
        <Conditions
          group={defaultProps.group}
          product={defaultProps.product}
          productFields={defaultProps.productFields}
          checkCondition={defaultProps.checkCondition}
          reloadingProducts={defaultProps.reloadingProducts}
        />
      )}
    </>
  );
};
