import {iconsList} from "./svg/icons-list";

export const MANAGER_TABS = [
    {
        text: 'Billing',
        icon: iconsList.dollar,
    },
    {
        text: 'User management',
        icon: iconsList.users
    }
];

export const COLORS = {
    mainViolet: '#6651BD',
    disabledViolet: '#a195d6',
    white: '#FFFFFF',
    fontBlack: '#181038',
    grayBorder: '#E9E9E9',
    grayBackground: '#9892AA',
    orange: '#FDAE74',
    lightGreen: '#72e0b2',
    bluredBlue: '#83A4F7',
    bluredYellow: '#FDF7D6',
};

export const EMAILS = ['edgard.gomes@dynamicads.ru', 'alexandr.kuchin@dynamicads.ru'];


export const PROJECTS_PATH = /^\/projects-new\/\d+$/;

export const NoticeCode = {
    'ABSENT': 0,
    'SHORT_NOTICE': 2,
    'EXPIRED': 3,
    'CANCELLED': 4,
    'TRIAL': 5,
}

export const Roles = {
    'ADMIN': 1,
    'EDITOR': 2,
    'VIEWER': 3
}

export const GenerationStatus = {
	'NEVER_DONE': 'N',
	'PARSING':  'R',
	'PARSED': 'L',
	'INIT': 'I',
	'IN_PROGRESS': 'P',
	'DONE': 'D',
	'STOPPING': 'S',
	'STOPPED': 'T',
	'ERROR': 'E',
  'WAITING': 'W',
}

export const GENERATIONS_LIMIT_MESSAGE = 'Monthly generation has reached';

export const TERMS_LINKS = [
  {
    href: "https://app.getterms.io/view/1kSb8/privacy/en-us",
    text: "Privacy Policy",
  },
  {
    href: "https://app.getterms.io/view/1kSb8/cookie/en-us",
    text: "Cookie Policy",
  },
  {
    href: "https://app.getterms.io/view/1kSb8/aup/en-us",
    text: "Acceptable Use Policy",
  },
  {
    href: "https://app.getterms.io/view/1kSb8/tos/en-us",
    text: "Terms of Service",
  },
];

export const paymentTypes = {
  CARD: 'card',
  LINK: 'link'
};

export const paymentBrands = {
  VISA: 'visa',
  MASTERCARD: 'MASTERCARD'
}

export const transformerAnchors = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];
export const transformerAnchorsFull = ['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right'];
