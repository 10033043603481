import React, { useRef, useState } from 'react'
import {Input} from "antd";

import styles from './Nodes.module.css'

export const NoteNode = (props) => {
  const textAreaRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const onChange = (event) => {
    props.data.text = event.target.value;
  }

  const onDoubleClick = () => {
    textAreaRef.current.focus()
    setIsFocused(true)
  }

  return (
    <div
      style={{
          cursor: isFocused ? 'text' : 'grab',
          pointerEvents: isFocused ? 'none' : 'auto',
      }}
      onDoubleClick={onDoubleClick}
    >
      <Input.TextArea
        placeholder='Double click to enter text'
        defaultValue={props.data.text}
        ref={textAreaRef}
        className={styles.note}
        maxLength={200}
        onChange={onChange}
        onBlur={() => setIsFocused(false)}
        style={{
          pointerEvents: isFocused ? 'auto' : 'none',
          height: 160,
          width: 160,
          resize: 'none',
        }}
      />
    </div> 
  )
}