import { message, Spin } from "antd";
import React, { useEffect, useRef, useState } from 'react';
import ReactFlow, {
  Controls,
  ReactFlowProvider,
  addEdge,
  removeElements,
  MiniMap,
} from 'react-flow-renderer';

import background from '../../svg/node/bg.svg';
import GraphicProductData from '../graphicEditor/GraphicProductData';
import Sidebar from './DnDSidebar';
import CustomNode from './Nodes/CustomNode';
import ExportNode from "./Nodes/ExportNode";
import FeedNode from "./Nodes/FeedNode";
import FilterNode from "./Nodes/FilterNode";
import GraphicNode from "./Nodes/GraphicNode";
import {NoteNode} from "./Nodes/NoteNode";
import TemplatesSideBar from './components/TemplatesSideBar';
import { COLORS } from '../../constants';

import styles from './Dnd.module.css';

const sortNodesByType = (arr, type) => {
    return arr.sort((a, b) => {
        if (a.type === 'note') return -1;
        if (b.type === 'note') return 1;
        return 0;
    });
}
  
const nodeTypes = {
    custom: CustomNode,
    feed: FeedNode,
    filter: FilterNode,
    graphic: GraphicNode,
    export: ExportNode,
    note: NoteNode
};

const {
  mainViolet,
  orange,
  lightGreen,
  bluredBlue,
  bluredYellow,
  grayBackground
} = COLORS;

const initialRule = {
  position: [150, 150],
  zoom: 1,
};

const mapColors = {
  export: lightGreen,
  filter: orange,
  graphic: mainViolet,
  feed: bluredBlue,
  note: bluredYellow,
  default: grayBackground,
};

let id = 0;
const getId = () => `dndnode_${id++}`;

const DnDFlow = (props) => {
    const reactFlowWrapper = useRef(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [elements, setElements] = useState([]);
    const organization = props?.app?.project?.organization;
    const [isAsideItemLoading, setIsAsideItemLoading] = useState(false);

    const onConnect = (params) => setElements((els) => addEdge(params, els));
    const onElementsRemove = async (elementsToRemove) =>  {
      if (elementsToRemove.some((element) => element.type === 'feed')) return;
      setElements((els) => removeElements(elementsToRemove, els));
    };

    const [productFields, setProductFields] = useState({});
    const [isTemplatesSideBarOpen, setTemplatesSideBarOpen] = useState(false);
    const [isDataPreviewSideBarOpen, setDataPreviewSideBarOpen] = useState(false);
    const [feedPageIndex, setFeedPageIndex] = useState(0);
    const [productIndex, setProductIndex] = useState(0);
    const [product, setProduct] = useState();
    const [designTemplates, setDesignTemplates] = useState([]);
    const [rule, setRule] = useState(initialRule);

    const onLoad = (_reactFlowInstance) => {
        setReactFlowInstance(_reactFlowInstance);
        props.app.setNodesRf(_reactFlowInstance);
    };

    const onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    const onDrop = (event) => {
        event.preventDefault();

        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const type = event.dataTransfer.getData('application/reactflow');

        if (!type) {
            console.log("Empty node element type");
            console.log(event);
            return;
        }

        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        });
        const newNode = {
            id: getId(),
            type,
            position,
            data: {project: props.app.project, save: props.app.downloadStage, feedTypes: props.app.feedTypes},
        };

        setElements((es) => {
            if(newNode.type === 'note') {
                return [newNode, ...es];
            }

            return es.concat(newNode)
        });
    };

    useEffect(() => {
        if (!!props.app.project?.rule?.rule_json) {
            const rule = JSON.parse(props.app.project.rule.rule_json);
            setRule({
              position: rule.position,
              zoom: rule.zoom,
            });
            let oldIds = {};
            id = 0

            for (let el of rule.elements) {
                if (el.type !== 'default') {
                    el.data = {
                        ...el.data,
                        project: props.app.project,
                        save: props.app.downloadStage,
                        feedTypes: props.app.feedTypes
                    }
                    oldIds[el.id] = `dndnode_${id}`
                    el.id = `dndnode_${id}`
                    id++
                }
            }
            for (let el of rule.elements) {
                if (el.type === 'default') {
                    el.data = {...el.data, project: props.app.project}
                    el.id = `dndnode_${id}`
                    el.source = oldIds[el.source]
                    el.target = oldIds[el.target]
                    id++
                }
                if(el.type === 'graphic') {
                    el.data.loadProject = props.loadProject
                    el.data.loadDesigns = props.loadDesigns
                    el.data.loadTemplates = loadTemplates
                }
            }

            const sortedNotes = sortNodesByType(rule.elements.slice(), 'note')

            setElements(sortedNotes);

            props.app.setRuleFetched();
        } else {
            setElements([
                {
                    id: 'dndnode_0',
                    type: 'feed',
                    data: {project: props.app.project},
                    position: {x: 250, y: 5},
                },
            ]);
            id = 1;
        }

        if (props.app.project && props.app.project.feed?.feed_fields) {
            setProductFields(JSON.parse(props.app.project.feed.feed_fields));
        }
    }, [props.app.project]);

    const changeProduct = () => {
        const feedPage = props.feedPage;
        let currentIndex = productIndex % feedPage?.page_size;
        if (feedPage?.products.length && currentIndex < feedPage.products.length) {
            setProduct(feedPage.products[currentIndex])
        }
    }

    useEffect(()=>{
        changeProduct();
    }, [props.feedPage, productIndex])


    useEffect(()=>{
        props.loadFeed(feedPageIndex)
    }, [feedPageIndex, props.feedId])

    const loadTemplates = async () => {
        const designs = await props.loadDesigns(true);
        setDesignTemplates(designs)
        return designs
    };

    const showTemplatesSideBar = async () => {
        setDataPreviewSideBarOpen(false)
        if (!isTemplatesSideBarOpen) {
           setIsAsideItemLoading(true);
           await loadTemplates().finally(() => setIsAsideItemLoading(false));
        }
        setTemplatesSideBarOpen(!isTemplatesSideBarOpen);
    };

    const showDataPreviewSideBar = () => {
        setDataPreviewSideBarOpen(!isDataPreviewSideBarOpen);
        setTemplatesSideBarOpen(false)
    }

    const deleteTemplate = async (id) => {
        try {
            const success = await props.deleteTemplate(id);

            if (!success) {
                return message.error(`Error deleting template (${success})`);
            }

            if (elements) {
                const updatedElements = elements.map(element => {
                    if (element.type === 'graphic') {
                        element.data.selected = element.data.selected.filter(item => item.toString() !== id.toString());
                    }
                    return element;
                });

                setElements(updatedElements);
                saveStage();
            }

        } catch (error) {
            message.error(`Error deleting template (${error})`);
        }
    };

    const saveStage = () => {
        props.app.downloadStage();
    }

    const toNextProduct = (step = 1) => {
        const feedPage = props.feedPage;

        const nextProduct = productIndex + step
        const nextPage = parseInt(nextProduct / feedPage.page_size);
        if (nextProduct >= feedPage.total_products) {
            setFeedPageIndex(0);
            setProductIndex(0);
            props.app.updateLoadingProductData(true);
        } else if (nextPage > feedPageIndex) {
            setFeedPageIndex(nextPage);
            setProductIndex(nextProduct);
            props.app.updateLoadingProductData(true);
        } else {
            setProductIndex(nextProduct);
        }
    }

    const toPrevProduct = (step = 1) => {
        const feedPage = props.feedPage;

        const prevProduct = productIndex - step
        const prevPage = parseInt(prevProduct / feedPage.page_size);
        if (prevProduct < 0) {
            setFeedPageIndex(feedPage.max_pages);
            setProductIndex(feedPage.total_products - 1);
            props.app.updateLoadingProductData(true);
        } else if (prevPage < feedPageIndex) {
            setFeedPageIndex(prevPage);
            setProductIndex(prevProduct);
            props.app.updateLoadingProductData(true);
        } else {
            setProductIndex(prevProduct);
        }
    }

    const toSelectProduct = (index) => {
        setProductIndex(index)
    }
    const toFeedPageSelect = (page) => {
      setFeedPageIndex(page);
    };

    const searchProductById = async (productId) => {
        const foundProduct = await props.api.getProductById(props.feedId, productId, props.app.updateLoadingProductData);
        if (!foundProduct) return;
        setProduct(foundProduct);
    };

    const onSelectChange = (selectedElements) => {
        const elementsArray = [];
        for (const element of elements) {
            if (element.data) {
                element.data.nodeSelected = selectedElements?.[0].id === element.id;
            }
            elementsArray.push(element)
        }
        setElements(elementsArray)
    };

    const getMiniMapNodesColor = (type) => mapColors[type] ?? mapColors.default;

    return (
        <div className={styles.dndflow}>
            <Sidebar
                save_func={props.app.downloadStage}
                run_func={props.app.run}
                showTemplatesSideBar={showTemplatesSideBar}
                isTemplatesSideBarOpen={isTemplatesSideBarOpen}
                showDataPreviewSideBar={showDataPreviewSideBar}
                isShowDataPreviewSideBar={isDataPreviewSideBarOpen}
                organization={organization}
                projectId={props.projectId}
                isAsideItemLoading={isAsideItemLoading}
                projectLoading={props.loading}
            />
            <ReactFlowProvider>
                {isTemplatesSideBarOpen && <TemplatesSideBar
                    onClose={showTemplatesSideBar}
                    loadTemplates={loadTemplates}
                    designTemplates={designTemplates}
                    deleteTemplate={deleteTemplate}
                    projectId={props.projectId}
                    save_func={props.app.downloadStage}
                    loadProject={props.loadProject}
                    organization={organization}
                />}
                {isDataPreviewSideBarOpen && <GraphicProductData
                    readOnly={true}
                    style={{left: 'calc(168px + 2rem - 10px)'}}
                    product={product}
                    productFields={productFields}
                    productsTotalCount={props.feedPage?.total_products ?? 0}
                    productIndex={productIndex}
                    pageSize={props.feedPage?.page_size}
                    pageNumber={feedPageIndex}
                    toPrevProduct={toPrevProduct}
                    toNextProduct={toNextProduct}
                    toSelectProduct={toSelectProduct}
                    toFeedPageSelect={toFeedPageSelect}
                    onClose={showDataPreviewSideBar}
                    searchProductById={searchProductById}
                    reloadingProducts={props.app.productDataLoading}
                />

                }
                <div className={styles.reactflowWrapper} ref={reactFlowWrapper} style={{backgroundImage: `url(${background})`}}>
                    {props.loading ?
                        <div className='loading-container'>
                            <Spin size='large' />
                        </div> :
                        <ReactFlow
                            defaultPosition={rule?.position}
                            defaultZoom={rule?.zoom}
                            elements={elements}
                            nodeTypes={nodeTypes}
                            onConnect={onConnect}
                            onElementsRemove={onElementsRemove}
                            onLoad={onLoad}
                            onDrop={onDrop}
                            onDragOver={onDragOver}
                            selectNodesOnDrag
                            elementsSelectable
                            onSelectionChange={(selectedElements) => onSelectChange(selectedElements)}
                    >
                          <MiniMap
                            nodeBorderRadius={10}
                            nodeColor={(node) => getMiniMapNodesColor(node.type)}
                            className={styles.miniMap}
                          />
                        <Controls />
                    </ReactFlow>
                    }
                </div>
            </ReactFlowProvider>
        </div>
    );
};

export default DnDFlow;