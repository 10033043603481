import React, { useState, useEffect } from 'react';
import { Button, Skeleton } from 'antd';
import ConditionGroup from './ConditionGroup';
import {
  types,
  conditionTypes,
  createViewModel,
  createModel,
  validateCondition,
} from '../../../services/ConditionService';

import styles from './ConditionGroup.module.css';
import globalStyles from '../../GlobalGraphicEditor.module.css';

export default (props) => {
  const [rootCondition, setRootCondition] = useState();
  const [fields, setFields] = useState([]);

  const typeDataSource = types;
  const conditionTypeDataSource = conditionTypes;

  useEffect(() => {
    if (props.product) {
      const newFields = Object.keys(props.product).map(productKey => ({ id: productKey, name: productKey }));
      setFields(newFields);
    }
  }, [props.product]);

  useEffect(() => {
    if (props.group) {
      props.checkCondition(props.group);
    }
  }, [props.group, rootCondition]);

  const templateRootFieldCondition = {
    group: true,
    type: typeDataSource[0]?.id,
    conditions: [
      {
        fieldId: fields[0]?.id,
        conditionType: '',
        value: '',
      },
    ],
  };

  const templateRootConditionGroup = {
    group: true,
    type: typeDataSource[0].id,
    conditions: [
      {
        group: true,
        type: typeDataSource[0]?.id,
        conditions: [],
      },
      {
        group: true,
        type: typeDataSource[0]?.id,
        conditions: [],
      },
    ],
  };

  useEffect(() => {
    const viewModel = createViewModel(props.group.getAttr('condition'));
    setRootCondition(viewModel);
  }, [props.group]);

  const createFieldCondition = () => {
    const data = { ...templateRootFieldCondition };
    setRootCondition(data);
    saveCondition(data);
  };

  const createConditionGroup = () => {
    const data = { ...templateRootConditionGroup };
    setRootCondition(data);
    saveCondition(data);
  };

  const onUpdate = () => {
    if (rootCondition && !rootCondition.conditions.length) {
      setRootCondition(undefined);
      saveCondition(undefined);
    } else {
      saveCondition(rootCondition);
    }
  };

  const saveCondition = (value) => {
    props.group.setAttr('condition', createModel(value));
    checkCondition(value);
  };

  const checkCondition = (value) => {
    value && validateCondition(value, fields)
      ? props.group.addName('condition')
      : props.group.removeName('condition');
    props.checkCondition(props.group);
  };

  return (
    props.product && !props.reloadingProducts ?
      <div>
        {rootCondition && (
          <ConditionGroup
            level={1}
            onUpdate={onUpdate}
            condition={rootCondition}
            product={props.product}
            conditionTypeDataSource={conditionTypeDataSource}
            typeDataSource={typeDataSource}
            fields={fields}
            productFields={props.productFields}
          />
        )}
        {!rootCondition && (
          <div
            className={`${globalStyles.gapBlock} ${globalStyles.selectBlock} ${globalStyles.bottomBorderDivider} ${globalStyles.bottomBigPaddings}`}
          >
            <Button
              type='text'
              className={styles.addConditionButton}
              onClick={createFieldCondition}
            >
              Add field condition
            </Button>

            <Button
              type='text'
              className={styles.addConditionButton}
              onClick={createConditionGroup}
            >
              Add condition group
            </Button>
          </div>
        )}
      </div> :
      <div className={`${globalStyles.selectBlock} ${globalStyles.bottomBigPaddings}`}>
        <Skeleton paragraph={{ rows: 10 }} />
      </div>
  );
};
