import React, { useEffect, useState } from 'react';
import { Select, Tag } from 'antd';
import {
  getProductFieldDisplayName,
  isAutoField,
  filterProductFieldsForSelect,
} from '../../../services/ProductHelperService';
import { ReactComponent as DownIcon } from 'svg/icon/arrow-down-small-gray.svg';

import globalStyles from '../../GlobalGraphicEditor.module.css';

export default (props) => {
    const [value, setValue] = useState(props.value);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const dataSource = filterProductFieldsForSelect([...Object.keys(props.product).map(t => {
        return {
            id: t, name: t, displayName: getProductFieldDisplayName(t)
        }
    })]);

    const fields = props.fields;
    const tagStyle = {margin: '0 5px 0 0'};

    const hasTypeField = (field) => {
        return !!getTypeName(field);
    };

    const getTypeName = (field) => {
        return fields[field.id] ?? '';
    }

    const realValue = dataSource.find(t => t.id === value);
    const sorted = dataSource.sort((a, b) => b.id - a.id);
    const onSelectClick = () => {
        setIsOpen(!isOpen);
    };

  return (
    <div>
      <div className={globalStyles.selectBlock}>
        <Select
          disabled={props.disabled}
          value={realValue?.id}
          onClick={onSelectClick}
          onChange={props.onChange}
          className={`${!props.isRoundSelect ? globalStyles.select : ''} ${(value && realValue?.id !== value) || props.hasErrors ? globalStyles.selectError : ''} ${props.style || ''}`}
          suffixIcon={
            <DownIcon
              className={
                !props.disabled && isOpen ? globalStyles.activeIcon : ''
              }
            />
          }
        >
          {sorted.map((element, index) => {
            return (
              <Select.Option key={`field-${index}`} value={element.id}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {isAutoField(element.name) && (
                    <Tag color='gold' style={tagStyle}>
                      auto
                    </Tag>
                  )}
                  {hasTypeField(element) && (
                    <Tag color='cyan'>{getTypeName(element)}</Tag>
                  )}
                  <div>{element.displayName}</div>
                </div>
              </Select.Option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};
