import React from 'react';
import { Handle } from 'react-flow-renderer';

import {ReactComponent as NodeIcon} from '../../../svg/node/feed-node-label.svg';

import styles from './Nodes.module.css';

export default ( props ) => {
    let prettyUrl = '';

    if (props.data.project?.feed?.feed_url) {
        prettyUrl = props.data.project.feed.feed_url
            .replace('http://', '')
            .replace('https://', '')
        let urlParts = prettyUrl.split('/')
        prettyUrl = prettyUrl.slice(0, 8).concat('.../').concat(urlParts[urlParts.length-1])
    }

    return (
        <div className={styles.node}>
            <div className={`${styles.nodeHead} ${styles.feedNodeHead}`}>
                <NodeIcon />
                <span>Feed</span>
            </div>
            <div className={`${styles.nodeBody} ${styles.feedNodeBody}`}>
                <div className={styles.nodeData}>{prettyUrl}</div>
            </div>
            <Handle
                type="source"
                position="right"
                id="a"
                isConnectable={props.isConnectable}
                className={`${styles.nodeHandle} ${styles.nodeHandleRight}`}
            />
        </div>
    );
};
