import React from 'react'
import {Select} from 'antd'

import {ReactComponent as SelectArrow} from '../../../svg/node/select-arrow.svg'

import styles from './Nodes.module.css'

export const NodeSelect = ({options, className = '', defaultValue, onChange, disabled}) => {
  return (
    <div className={styles.nodeSelectWrapper}>
      <Select
        className={className}
        style={{padding: '0 0 0 16px'}}
        value={defaultValue}
        onChange={onChange}
        disabled={disabled}
        suffixIcon={<SelectArrow />}
        options={options}
      />
    </div>
  )
}
