import React, {useEffect, useState} from 'react';
import {InputNumber} from 'antd';
import ElementPinTypeControl from '../ElementPinTypeControl';

import styles from './RelativePosition.module.css'
import globalStyles from '../../GlobalGraphicEditor.module.css';

export default (props) => {
    const [disabled, setDisabled] = useState(props.disabled);

    useEffect(() => {
        setDisabled(props.disabled);
    }, [props.disabled]);

    const measureBlock = <div className={styles.measureBlock}>px</div>;
    const selected1 = props.value1;
    const selectedPosition = props.dataSource.find(t => t.id === selected1);

    const selected2 = props.value2;

    return (
        <div className={styles.wrapper}>
            {disabled && <div className={styles.disabledStyles}/>}
            <div className={styles.title}>
                {selectedPosition && !props.disabled ?
                    'Relative position calculates from the {selectedPosition.name} of the parent layer'
                    :
                    'Select parent layer point to pin to and set desired offset if needed:'
                }
            </div>
            <div className={styles.positionsBlock}>
                <div className={styles.controlsBlock}>
                    <div>
                        <div className={styles.smallLabel}>pin to</div>
                        <ElementPinTypeControl
                            disabled={props.disabled}
                            value={selected1}
                            onChange={props.onPinToChange}
                        />
                    </div>
                    <div>
                        <div className={styles.smallLabel}>pin by</div>
                        <ElementPinTypeControl
                            disabled={props.disabled}
                            value={selected2}
                            onChange={props.onPinByChange}
                        />
                    </div>
                </div>
                <div>
                    <div className={styles.inputsBlock}>
                        <div className={styles.measureTitle}>X</div>
                        <div>
                            <InputNumber
                                size="small"
                                value={props.offsetX}
                                onChange={props.changeOffsetX}
                                className={`${globalStyles.input} ${styles.input}`}
                            />
                        </div>
                        {measureBlock}
                    </div>
                    <div className={styles.inputsBlock}>
                        <div className={styles.measureTitle}>Y</div>
                        <div>
                            <InputNumber
                                size="small"
                                value={props.offsetY}
                                onChange={props.changeOffsetY}
                                className={`${globalStyles.input} ${styles.input}`}
                            />
                        </div>
                        {measureBlock}
                    </div>
                </div>
            </div>
        </div>
    );
};