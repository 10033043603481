export const nodeConditionTypes = [
    {id: '$eq', name: '=', bool: true},
    {id: 'NOT', name: 'NOT', bool: true},
    {id: '$gt', name: '>', number: true, string: false},
    {id: '$gte', name: '>=', number: true, string: false},
    {id: '$lt', name: '<', number: true, string: false},
    {id: '$lte', name: '<=', number: true, string: false},
    {id: 'regex', name: 'regex', number: false, string: true},
    {id: 'not_regex', name: 'not regex', number: false, string: true}
];

const numberValues = ['number', 'integer', 'float', 'double']

export const hasFieldConditionType = (field, conditionType) => {
    if (!field) {
        return true;
    }

    return 
}

export const getFilterConditionTypes = (field) => {
    const type = field?.type;
    
    let filtered = nodeConditionTypes;
    if (type === 'bool') {
        filtered = nodeConditionTypes.filter(t => t.bool);        
    } else if (!field || !numberValues.find(t => t === type)) {
        filtered = nodeConditionTypes.filter(t => t.string === undefined || t.string === true);        
    } else {
        filtered = nodeConditionTypes.filter(t => t.number === undefined || t.number === true);        
    }

    return [...filtered.map(t => t.name)];
}