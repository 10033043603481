import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import Nestable from 'react-nestable';

import Header from '../../components/properties/PropertiesHeader/PropertiesHeader';
import { LayerElement } from './LayerElement';

import styles from './GraphicLayersPanel.module.css';
import 'react-nestable/dist/styles/index.css';

const Layers = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    refresh() {
      initFields();
    },
  }));

  const [rootGroupFields, setRootGroupFields] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedRelatives, setSelectedRelatives] = useState([]);
  const maxDepth = props.maxDepth ?? 3;

  useEffect(() => {
    initFields();
  }, [props.stage]);

  useEffect(() => {
    setSelectedItem();
  }, [props.refreshToggle, rootGroupFields]);

  const createElement = (
    field,
    parentKey,
    addChildren = false,
    parentCollapsed = false,
    collapsed = false,
  ) => {
    return {
      title: getElementName(field),
      defaultName: getElementDefaultName(field),
      key: field._id,
      children: addChildren ? addChildrenElements(field) : [],
      draggable: true,
      field,
      elementType: field.attrs.elementType,
      parentKey,
      collapsed,
      parentCollapsed,
    };
  };

  const addChildrenElements = (field) => {
    if (!field || !field.children) {
      return [];
    }
    const children = [...field.children];
    children.sort((a, b) => b.zIndex() - a.zIndex());

    return children.map((childrenItem) => {
      if (rootGroupFields.length) {
        for (let i = 0; i < rootGroupFields.length; i++) {
          if (rootGroupFields[i]?.children.length) {
            const foundChildrenRoot = rootGroupFields[i].children.find(
              (group) => group?.field?._id === childrenItem._id,
            );
            return createElement(
              childrenItem,
              field._id,
              childrenItem.attrs.elementType === 'group',
              false,
              foundChildrenRoot?.collapsed,
            );
          }
          return createElement(
            childrenItem,
            field._id,
            childrenItem.attrs.elementType === 'group',
          );
        }
      } else {
        return createElement(
          childrenItem,
          field._id,
          childrenItem.attrs.elementType === 'group',
        );
      }
    });
  };

  const getElementName = (field) => {
    if (field.getAttr('elementName')) {
      return field.getAttr('elementName');
    }
    return getElementDefaultName(field);
  };

  const getElementDefaultName = (field) => {
    const { elementType } = field.attrs;
    const elementsName = {
      rect: 'Rectangle',
      default: elementType.charAt(0).toUpperCase() + elementType.slice(1),
    };

    return `${elementsName[elementType] ?? elementsName.default} (${field._id})`;
  };

  const initFields = () => {
    const { stage } = props;
    const result = [];
    if (stage) {
      const layer = stage.findOne('#canvas');
      const groupElements = layer.children.filter((el) =>
        el.hasName('elementGroup'),
      );
      groupElements.sort((a, b) => b.zIndex() - a.zIndex());

      if (groupElements && groupElements.length) {
        for (const el of groupElements) {
          const foundRootGroup = rootGroupFields.find(
            (group) => group?.field?._id === el._id,
          );
          const item = createElement(
            el,
            undefined,
            el.attrs.elementType === 'group',
            foundRootGroup?.parentCollapsed,
          );
          result.push(item);
        }
      }
    }
    setRootGroupFields(result);
  };

  const findRelativeNodes = (allItems, keys) => {
    const nodes = allItems
      .filter((t) => keys.includes(t.key))
      .map((t) => t.field);

    const relativeNodes = nodes.flatMap((node) => {
      if (node.parent.attrs.elementType === 'group') {
        if (node?.parent?.children.length) {
          return [
            node.parent._id,
            ...node.parent.children.map((child) => child._id),
          ];
        }
        return [node.parent._id];
      }

      if (node?.children.length) {
        return [node._id, ...node.children.map((child) => child._id)];
      }

      return [];
    });

    setSelectedRelatives(relativeNodes);
  };

  const collapseToggle = (key, isCollapse) => {
    const rootGroupFieldsCopy = rootGroupFields.map((group) => ({ ...group }));

    rootGroupFieldsCopy.forEach((group) => {
      if (group.key === key) {
        group.children = group.children.map((child) => ({
          ...child,
          collapsed: isCollapse,
        }));
        group.parentCollapsed = isCollapse;
      }
    });
    setRootGroupFields(rootGroupFieldsCopy);
  };

  const setSelectedItem = () => {
    let keys = [];
    const allItems = getAllItems();
    const selectedNodes = props.stage.findOne('Transformer').nodes();
    if (selectedNodes) {
      keys = selectedNodes.map((o) => o._id);
    }
    setSelectedKeys(keys);
    findRelativeNodes(allItems, keys);
  };

  const isNodeChild = (key) => {
    return !rootGroupFields.map((o) => o.key).includes(key);
  };

  const getParentNode = (key) => {
    return getAllItems().find((o) =>
      o.children.map((c) => c.key).includes(key),
    );
  };

  const onDrop = (info) => {
    const { relativeNode } = info;
    const { dragNode } = info;
    const { field } = relativeNode;
    const dragField = dragNode.field;

    if (isNodeChild(dragNode.key)) {
      if (isNodeChild(relativeNode.key)) {
        const parent = getParentNode(relativeNode.key).field;

        dragField.draggable(false);
        dragField.moveTo(parent);
      } else {
        const layer = field.getLayer();
        const parent = getParentNode(dragNode.key).field;

        dragField.x(layer.attrs.clipX);
        dragField.y(layer.attrs.clipY);
        dragField.moveTo(layer);

        if (parent.hasName('distributed')) {
          dragField.position(layer.findOne('#canvas_bg').position());
        }
        dragField.draggable(true);
        if (!parent.children.length) {
          props.destroyGroup(parent);
        }
      }
    } else if (isNodeChild(relativeNode.key)) {
      const parent = getParentNode(relativeNode.key).field;

      dragField.x(0);
      dragField.y(0);
      dragField.draggable(false);
      dragField.moveTo(parent);
    }
    dragField.zIndex(field.zIndex());
    initFields();

    const layer = field.getLayer();
    const stage = layer.getParent();

    if (layer) {
      layer.find('.distributed').forEach((el) => props.reDistributeGroup(el));
    }
    props.saveHistory(stage);
  };

  const getAllItems = () => {
    let found = true;

    let currentItems = [...rootGroupFields];
    let result = [...currentItems];
    while (found) {
      const children = currentItems.map((t) => t.children).flat();
      if (children.length) {
        result = [...result, ...children];
        currentItems = children;
      } else {
        found = false;
      }
    }

    return result;
  };

  const onSelect = (e, key) => {
    const isMetaPressed = e.shiftKey || e.ctrlKey || e.metaKey;

    const allItems = getAllItems();
    const item = allItems.find((t) => t.key === key);

    if (!item) {
      return;
    }

    let newKeys = [key];

    if (isMetaPressed) {
      if (selectedKeys.find((t) => t === key)) {
        newKeys = selectedKeys.filter((t) => t !== key);
      } else {
        const selected = allItems.filter((t) => selectedKeys.includes(t.key));
        if (selected.length) {
          const first = selected[0];
          if (first.parentKey === item.parentKey) {
            newKeys = [...selectedKeys, key];
          }
        }
      }
    }

    setSelectedKeys(newKeys);

    const nodes = allItems
      .filter((t) => newKeys.includes(t.key))
      .map((t) => t.field);

    findRelativeNodes(allItems, newKeys);

    props.onSelectField(nodes);
  };

  const confirmChange = ({ dragItem, destinationParent }) => {
    return !destinationParent || destinationParent.elementType === 'group';
  };

  const compareGroups = (previousGroup, newGroup) => {
    if (previousGroup === newGroup) return true;
    if (typeof previousGroup !== 'object' || typeof newGroup !== 'object')
      return false;

    const keys1 = Object.keys(previousGroup);
    const keys2 = Object.keys(newGroup);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      if (
        !keys2.includes(key) ||
        !compareGroups(previousGroup[key], newGroup[key])
      ) {
        return false;
      }
    }
    return true;
  };

  const onDragFinished = (data) => {
    const { items } = data;
    const { dragItem } = data;
    const { targetPath } = data;

    let item = null;
    let parent = null;
    for (let i = 0; i < targetPath.length; i++) {
      const levelIndex = targetPath[i];
      parent = item;
      item =
        item && item.children ? item.children[levelIndex] : items[levelIndex];
    }

    const currLevelItems = parent ? parent.children : items;
    const indexOfDragItem = currLevelItems.indexOf(dragItem);

    const prevGroup = getParentNode(dragItem.key)?.children ?? rootGroupFields;
    const prevIndex = prevGroup.indexOf(
      prevGroup.find((t) => t.key === dragItem.key),
    );

    if (compareGroups(prevGroup, currLevelItems)) return;

    const prevNode = currLevelItems[indexOfDragItem - 1];
    const nextNode = currLevelItems[indexOfDragItem + 1];

    const relativeNode = indexOfDragItem > prevIndex ? prevNode : nextNode;

    if (dragItem) {
      onDrop({
        dragNode: dragItem,
        relativeNode: relativeNode ?? prevNode ?? nextNode,
      });
    }
  };

  const onNameChanged = (key, title) => {
    const allItems = getAllItems();
    const item = allItems.find((t) => t.key === key);
    if (item) {
      item.field.setAttr('elementName', title);
      item.title = title;
      if (props.onChangeName) {
        props.onChangeName();
      }
    }
  };

  const deleteElement = (item) => {
    if (props.onDeleteElement) {
      props.onDeleteElement(item?.field);
    }
  };

  const copyElement = (item) => {
    if (props.onCopyElement) {
      props.onCopyElement(item?.field);
    }
  };

  const onElementHide = (item) => {
    props.hideElement(item.field);
    if (item.elementType === 'group' && item.children.length) {
      item?.children.forEach((children) => {
        props.hideElement(children.field);
      });
      collapseToggle(item.key, item.field.attrs.opacity === 0);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Header onClose={props.onClose} caption='Layers' />
      </div>
      <Nestable
        confirmChange={confirmChange}
        onChange={onDragFinished}
        idProp='key'
        items={rootGroupFields}
        maxDepth={maxDepth + 1}
        threshold={5}
        renderItem={({ item }) => (
          <LayerElement
            onCopyElement={copyElement}
            onDeleteElement={deleteElement}
            onNameChanged={onNameChanged}
            item={item}
            onSelectElement={onSelect}
            collapseToggle={collapseToggle}
            onElementHide={onElementHide}
            isSelected={!!(selectedKeys ?? []).find((t) => t === item.key)}
            selectedByRelative={
              !!(selectedRelatives ?? []).find((t) => t === item.key)
            }
          />
        )}
      />
    </div>
  );
});

export default Layers;
