import React, { useEffect, useState } from 'react';
import { Button, InputNumber, Typography } from 'antd';

import { isColorSet } from '../../properties/PropertiesHelper';
import ColorEditor from '../ColorEditor/ColorEditor';
import { ReactComponent as PlusIcon } from 'svg/graphicEditor/plus-big-black.svg';
import { ReactComponent as MinusIcon } from 'svg/graphicEditor/minus-big-black.svg';
import { ReactComponent as SpreadIcon } from 'svg/graphicEditor/spread-box-small.svg';

import styles from './ShadowControl.module.css';
import globalStyles from '../../GlobalGraphicEditor.module.css';

const { Text } = Typography;

export default function (props) {
  const [isShadowEnabled, setShadowEnabled] = useState();

  const [shadowColor, setShadowColor] = useState();
  const [shadowOffsetX, setShadowOffsetX] = useState();
  const [shadowOffsetY, setShadowOffsetY] = useState();
  const [shadowBlur, setShadowBlur] = useState();

  const { element } = props;

  useEffect(() => {
    const shadowColor = element.getAttr('shadowColor');
    const shadowOffsetX = element.getAttr('shadowOffsetX');
    const shadowOffsetY = element.getAttr('shadowOffsetY');
    const shadowBlur = element.getAttr('shadowBlur');

    setShadowColor(shadowColor);
    setShadowOffsetX(shadowOffsetX);
    setShadowOffsetY(shadowOffsetY);
    setShadowBlur(shadowBlur);

    setShadowEnabled(
      (isColorSet(shadowColor) && !!shadowOffsetX && !!shadowOffsetY) ||
        !!shadowBlur,
    );
  }, [element]);

  const changeShadowColor = (color) => {
    element.setAttr('shadowColor', color);
    setShadowColor(color);
    propertyChanged();
  };

  const changeShadowX = (value) => {
    const val = parseInt(value);
    element.setAttr('shadowOffsetX', val);
    setShadowOffsetX(value);
    propertyChanged();
  };

  const changeShadowY = (value) => {
    const val = parseInt(value);
    element.setAttr('shadowOffsetY', val);
    setShadowOffsetY(value);
    propertyChanged();
  };

  const changeShadowBlur = (value) => {
    const val = parseInt(value);
    if (val >= 0) {
      element.setAttr('shadowBlur', val);
      setShadowBlur(value);
      propertyChanged();
    }
  };

  const changeShadowEnable = () => {
    setShadowEnabled(!isShadowEnabled);
    updateState(!isShadowEnabled);
  };

  const updateState = (isEnabled) => {
    if (!isEnabled) {
      element.setAttr('_lastStateShadow', {
        shadowColor,
        shadowOffsetX,
        shadowOffsetY,
        shadowBlur,
      });
      changeShadowX(0);
      changeShadowY(0);
      changeShadowBlur(0);
    } else {
      const state = element.getAttr('_lastStateShadow');
      if (state) {
        changeShadowColor(state.shadowColor);
        changeShadowX(state.shadowOffsetX);
        changeShadowY(state.shadowOffsetY);
        changeShadowBlur(state.shadowBlur);
      }
    }
  };

  const propertyChanged = () => {
    if (props.onPropertyChanged) {
      props.onPropertyChanged();
    }
  };

  return (
    <div>
      <div className={globalStyles.spaceBetweenBlock}>
        <Text className={globalStyles.smallTitle}>Shadow</Text>
        <Button
          shape='circle'
          type='text'
          icon={isShadowEnabled ? <MinusIcon /> : <PlusIcon />}
          onClick={changeShadowEnable}
        />
      </div>
      {isShadowEnabled && (
        <div
          className={`${globalStyles.selectBlock} ${globalStyles.gapBlock} ${globalStyles.innerBlock}`}
        >
          <div className={globalStyles.spaceBetweenBlock}>
            <div
              className={`${globalStyles.spaceBetweenBlock} ${styles.shadowBlock}`}
            >
              <Text>X</Text>
              <InputNumber
                size='small'
                value={shadowOffsetX}
                onChange={changeShadowX}
                className={globalStyles.input}
              />
            </div>
            <div
              className={`${globalStyles.spaceBetweenBlock} ${styles.shadowBlock}`}
            >
              <Text>Y</Text>
              <InputNumber
                size='small'
                value={shadowOffsetY}
                onChange={changeShadowY}
                className={globalStyles.input}
              />
            </div>
          </div>
          <div className={globalStyles.spaceBetweenBlock}>
            <Text className={globalStyles.text}>Spread:</Text>
            <InputNumber
              size='small'
              prefix={<SpreadIcon />}
              value={shadowBlur}
              onChange={changeShadowBlur}
              className={globalStyles.input}
            />
          </div>
          <div className={globalStyles.spaceBetweenBlock}>
            <Text className={globalStyles.text}>Shadow colour:</Text>
            <ColorEditor
              type='simple'
              value={shadowColor}
              onChange={changeShadowColor}
            />
          </div>
        </div>
      )}
    </div>
  );
}
