import React from 'react';
import {Button} from "antd";
import {iconsList} from "../../svg/icons-list";
import {MANAGER_TABS} from "../../constants";
import styles from './ManagerToggle.module.css'
const ManagerToggle = ({ selected, toggleSelected, disabled=false }) => {
    return (
        <Button className={styles.toggleContainer} disabled={disabled} onClick={ toggleSelected}>
            <div className={styles.underToggleWrapper}>
                {MANAGER_TABS.map((tab)=> (
                    <div className={`${styles.underToggle} ${disabled ? styles.disabled : ''}`} key={tab.text}>
                        {tab.icon}
                        <span>{tab.text}</span>
                    </div>
                ))}
            </div>
            <div className={`${styles.dialogButton} ${selected ? '' : styles.active} ${disabled ? styles.disabledBackground : ''}` }>
                {!selected ?
                    <>
                        {iconsList.dollar}
                        <span>Billing</span>
                    </>
                 :
                    <>
                        {iconsList.users}
                        <span>User management</span>
                    </>
                }
            </div>
        </Button>
    );
};

export default ManagerToggle;