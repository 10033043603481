import React from 'react';
import {Button} from "antd";
import {ReactComponent as AddRectangle} from "../../../../svg/graphicEditor/rectangle-small.svg"
import {ReactComponent as AddEllipse} from "../../../../svg/graphicEditor/ellipse-small.svg"
import {ReactComponent as AddPolygon} from "../../../../svg/graphicEditor/polygon-small.svg"
import {ReactComponent as AddStar} from "../../../../svg/graphicEditor/star-small.svg"
import {ReactComponent as AddLine} from "../../../../svg/graphicEditor/line-small.svg"
import styles from "./GraphicEditorHeader.module.css";


const ShapesMenu = ({addRect, addEllipse, addPolygon, addStar, addLine}) => {
    const shapesList = [
        {
            title: 'Rectangle',
            icon: <AddRectangle/>,
            onClick: addRect,
        },
        {
            title: 'Ellipse',
            icon: <AddEllipse/>,
            onClick: addEllipse,

        },
        {
            title: 'Polygon',
            icon: <AddPolygon/>,
            onClick: addPolygon,

        },
        {
            title: 'Star',
            icon: <AddStar/>,
            onClick: addStar,

        },
        {
            title: 'Line',
            icon: <AddLine/>,
            onClick: addLine,

        }
    ];

    return (
        <div className={styles.shapesWrapper}>
            {
                shapesList.map((shape) => (
                    <Button
                        type='text'
                        key={shape.title}
                        icon={shape.icon}
                        className={styles.shapesButton}
                        onClick={shape.onClick}
                    >
                        {shape.title}
                    </Button>
                ))
            }
        </div>
    );
};

export default ShapesMenu;