import React from 'react'
import { Progress, Tooltip } from 'antd'
import { GenerationStatus } from '../../../constants';

import { ReactComponent as ThickIcon } from 'svg/icon/tick.svg';

import styles from './StatusBlock.module.css'

const {
	DONE,
	NEVER_DONE,
	PARSED,
	INIT,
	ERROR,
	IN_PROGRESS,
	STOPPING,
  PARSING,
	STOPPED,
  WAITING,
} = GenerationStatus;

const colors = {
	violetStroke: '#6651BD',
	violetTrailColor: '#EAE1FC',
	greenStroke: '#6FBA78',
	greenTrailColor: '#cbe8cf'
};

const validStatuses = [DONE, STOPPED, ERROR];

export const isProjectButtonsDisabled = (status, feed = false) => {
  return !(status === DONE || status === ERROR || status === NEVER_DONE || status === PARSED|| feed);
}

export const isProjectRunning = (status) => {
  return status === INIT || status === IN_PROGRESS || status === STOPPING || status === PARSING || status === WAITING;
}

export const isProjectOrOutputStopping = (project) => {
	if (!project) return false;
	if (project.status === STOPPING) return true;

	for (const key in project.outputs) {
		if (project.outputs[key].status === STOPPING) {
			return true;
		}
	}
	return false;
};

export const isOutputsReadyToRun = (outputs) => {
  if (!outputs || Object.keys(outputs).length === 0) return false;

  for (const key in outputs) {
    if (!validStatuses.includes(outputs[key].status)) {
      return true;
    }
  }

  return false;
};

export const StatusBlock = ({ status = NEVER_DONE, progress = null, type = 'Project' }) => {
  const  statusMessages = {
    'N': 'Never done',
    'R': 'Parsing...',
    'L': 'Parsed !',
    'I': 'Initializing',
    'P': 'In progress...',
    'D': <><span>{type === 'Project' ? 'Done' : 'Ready'}</span> <ThickIcon /></>,
    'S': 'Cancellation in progress...',
    'E': 'Error !',
	  'T': 'Cancelled',
    'W': 'Preparing',
  }

	const statusHelperMessages = {
		'R': `The project cannot be stopped on the "Parsing" status`,
		'S': 'The output feed can be run after full cancellation',
		'E': 'Generation finished with error, but you can try to run again',
		'T': 'Generation was cancelled, but you can try to run again',
    'W': 'Waiting for parsing to complete',
	};

	const getStatusMessage = (status) => {
    if (type === 'Output' && status === PARSING) return statusHelperMessages[WAITING];
		return statusHelperMessages[status] || 'The output feed is ready to run';
	};

  const getContent = () => {
    let style = ''
    let label =  statusMessages[status]

    switch (type) {
      case 'Project':
        style = styles.project
        break
      case 'Output':
        style = styles.output
        break
    }

    switch (status) {
      case DONE:
        style += ' ' + styles.success;
        break
      case ERROR:
        style += ' ' + styles.error;
        break
      default:
        style += ' ' + styles.default;
    }

    if (type === 'Output' && status === PARSING) {
      label = statusMessages[WAITING];
    }

    return { style: style + ' ' + styles.statusBlock, label };
  }

  const { style, label } = getContent();

  // if project and status === INIT -> show progress
  // if output and status === IN_PROGRESS -> show progress
  // if output's status === PARSING -> show messages for WAITING status
  // if status === NEVER_DONE -> show nothing
  // in other cases -> show label

  if((type === 'Output' && status === IN_PROGRESS) || (type === 'Output' && status === INIT)) {

		const progressBar = {
			'I': {
				title: 'generating queues...',
				strokeColor:  colors.greenStroke,
				trailColor: colors.greenTrailColor,
				class: styles.percentInit,
			},
			'P': {
				title: 'generating output feed...',
				strokeColor:  colors.violetStroke,
				trailColor: colors.violetTrailColor,
				class: styles.percentInProgress
			}
		};

    return (
      <Tooltip
        title={progressBar[status].title}
        placement='bottom'
      >
        <Progress
          percent={Math.trunc(progress) ?? 0}
          strokeColor={progressBar[status].strokeColor}
          trailColor={progressBar[status].trailColor}
          className={progressBar[status].class}
          format={percent => percent === 100 ? '99.9%' : `${percent}%`}
        />
      </Tooltip>
    )
  }

  if(type === 'Project' && status === NEVER_DONE) return null;

  return (
	  <Tooltip title={getStatusMessage(status)} placement='bottom'>
		  <div className={style}>{label}</div>
	  </Tooltip>
  )
}
