import React, { useEffect, useState } from 'react';
import { Button, InputNumber, Slider, Typography } from 'antd';

import { prepareOpacityValue } from '../../../GraphicEditorHelpers';
import { ReactComponent as OpacityIcon } from 'svg/graphicEditor/opacity-small.svg';
import { ReactComponent as MinusIcon } from 'svg/graphicEditor/minus-big-black.svg';
import { ReactComponent as PlusIcon } from 'svg/graphicEditor/plus-big-black.svg';

import globalStyles from '../../GlobalGraphicEditor.module.css';

const { Text } = Typography;

export default function (props) {
  const [isOpacityEnabled, setOpacityEnabled] = useState();
  const [opacity, setOpacity] = useState();

  const { element } = props;

  useEffect(() => {
    setOpacityEnabled(parseInt(element.getAttr('opacity')) !== 1);
    setOpacity(prepareOpacityValue(element.getAttr('opacity')));
  }, [element]);

  const changeOpacity = (opacity) => {
    const val = parseInt(opacity);
    if (val >= 0 && val <= 100) {
      element.setAttr('opacity', opacity / 100);
      setOpacity(val);
    }
  };

  const changeOpacityEnable = () => {
    setOpacityEnabled(!isOpacityEnabled);
    updateState(!isOpacityEnabled);
  };

  const updateState = (isEnabled) => {
    if (!isEnabled) {
      element.setAttr('_lastStateOpacity', { opacity });
      changeOpacity(100);
    } else {
      const state = element.getAttr('_lastStateOpacity');
      if (state) {
        changeOpacity(state.opacity);
      }
    }
  };

  return (
    <div>
      <div className={globalStyles.spaceBetweenBlock}>
        <Text className={globalStyles.smallTitle}>Opacity</Text>
        <Button
          shape='circle'
          type='text'
          icon={isOpacityEnabled ? <MinusIcon /> : <PlusIcon />}
          onClick={changeOpacityEnable}
        />
      </div>
      {isOpacityEnabled && (
        <div
          className={`${globalStyles.innerBlock} ${globalStyles.selectBlock} ${globalStyles.gapBlock}`}
        >
          <div className={globalStyles.spaceBetweenBlock}>
            <Text className={globalStyles.text}>Opacity value:</Text>
            <InputNumber
              size='small'
              prefix={<OpacityIcon />}
              value={opacity}
              onChange={changeOpacity}
              min={0}
              max={100}
              step={1}
              className={globalStyles.input}
            />
          </div>
          <Slider
            min={0}
            max={100}
            value={opacity}
            step={1}
            onChange={changeOpacity}
          />
        </div>
      )}
    </div>
  );
}
