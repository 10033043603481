import React, { useEffect, useState } from 'react';
import { Button, message, Dropdown } from 'antd';
import { Handle } from 'react-flow-renderer';
import {useHistory} from "react-router-dom";
import NodeConditionSwitch from '../components/NodeConditionSwitch';
import { NodeSelect } from './NodeSelect'
import { copyDesignTemplate } from '../copyDesignService/copyDesignService';
import { useWebSocket } from '../../../contexts/SocketContext';
import { isOutputsReadyToRun, isProjectRunning } from '../../Project/StatusBlock/StatusBlock';
import { ReactComponent as CreateNewIcon } from 'svg/node/create-new-node.svg';
import { ReactComponent as EditIcon } from 'svg/node/edit-new.svg';
import { ReactComponent as NodeIcon } from 'svg/node/graphic-node-label.svg';
import { ReactComponent as DeleteIcon } from 'svg/sidebar/trash.svg';
import { ReactComponent as MoreIcon } from 'svg/node/more.svg';
import { ReactComponent as CopyIcon } from 'svg/node/copy-node.svg';

import styles from './Nodes.module.css';

export default (props) => {
    const [rerender, setRerender] = useState(false);
    const [condition, setCondition] = useState(props.data.condition ?? 'OR');
    const organization = props?.data?.project?.organization;
    const history = useHistory();
    const { data: socketData } = useWebSocket();

    const projectId = props.data.project?.id;
    const isRunning = isProjectRunning(socketData[projectId]?.status);
    const isOutputRunning = isOutputsReadyToRun(socketData[projectId]?.outputs);
    const isButtonShouldDisable =  !organization.generation_allowed || isRunning || isOutputRunning;

  useEffect(() => {
        setRerender(false)
    }, [rerender]);

    if (props.data.selected !== undefined) {
        props.data.selected = Array.isArray(props.data.selected) ? props.data.selected : [props.data.selected]
    } else {
        props.data.selected = []
    }

    if (!props.data.condition) props.data.condition = 'OR'

    const saveAndGo = (to) => {
        props.data.save();
        history.push({pathname: to, search: window.location.search});
    }

    const conditionChanged = (value) => {
        props.data.condition = value;
        setCondition(value);
    }

    const onAddTemplateClick = () => {
        if (props.data.selected.length < props.data.project?.designs.length) {
            props.data.selected.push(
                props.data.project?.designs.filter(
                    dataItem => props.data.selected.indexOf(dataItem.id) < 0
                )[0]['id']
            );
            setRerender(!rerender);
        } else {
            message.warning(`Only ${props.data.project?.designs.length} designs in project exist`);
        }
    };

    const onCopyTemplate = async (template) => {
        const {id} = template
        const designs = await props.data.loadDesigns(true)
        const design = designs.find(template => template.id === id);
        
        if (design) {
          await copyDesignTemplate(design, props.data.project.id, props.data.loadProject, props.data.loadTemplates)
        }
    }

    return (
        <div className={`${styles.node} ${styles.nodeGraphic}`}>
            <div className={`${styles.nodeHead} ${styles.graphicNodeHead}`}>
                <NodeIcon />
                <span>Graphic</span>
                <NodeConditionSwitch
                    condition={condition}
                    onConditionChanged={conditionChanged}
                    disabled={isButtonShouldDisable}
                />
            </div>
            <div className={`${styles.nodeBody} ${styles.graphicNodeBody}`}>
                {props.data.selected.map((selectItem, selectIndex) => (
                    <div className={`${styles.nodeBodyRow} ${styles.nodeBodyRowGraphic}`} key={`select_${selectIndex}`}>
                        <NodeSelect
                            className={`${styles.nodeData} ${styles.nodeDataGraphic}`}
                            defaultValue={selectItem}
                            onChange={(value) => {
                                props.data.selected[selectIndex] = value;
                                setRerender(true)
                            }}
                            disabled={isButtonShouldDisable}
                            options={props.data.project.designs.map((design) => ({value: design.id, label: design.name}))}
                        />
                        <div className={styles.nodeButtonList}>
                            <Button
                                onClick={() => {
                                    saveAndGo(`/project/${props.data.project.id}/design/${selectItem}`)
                                }}
                                disabled={isButtonShouldDisable}
                                className={styles.nodeButtonIcon}
                            >
                                <EditIcon />
                            </Button>
                            <Button
                                onClick={() => onCopyTemplate(props.data.project.designs.find(design => design.id === selectItem))}
                                className={styles.nodeButtonIcon}
                                disabled={isButtonShouldDisable}
                            >
                                <CopyIcon />
                            </Button>
                            <Dropdown
                                className={styles.dropdown}
                                overlayClassName={styles.dropdownOverlay}
                                menu={
                                    {
                                        items: [
                                            {
                                                key: 'delete',
                                                label: (
                                                    <Button
                                                        onClick={() => {
                                                            props.data.selected.splice(selectIndex, 1);
                                                            setRerender(!rerender)
                                                        }}
                                                        disabled={isButtonShouldDisable}
                                                        className={`${styles.dropdownButton} ${styles.dropdownButtonGraphic}`}
                                                    >
                                                        <span>Delete</span> <DeleteIcon />
                                                    </Button>
                                                )
                                            }
                                        ]
                                    }
                                }
                            >
                                <Button>
                                    <MoreIcon />
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                ))}
                <div className={styles.nodeFooterGraphic}>
                    <Button
                        className={`${styles.nodeButton} ${styles.nodeButtonGraphic}`}
                        onClick={() => {
                            saveAndGo(`/project/${props.data.project.id}/design/new`)
                        }}
                        disabled={isButtonShouldDisable}
                    >
                        <span>Create new</span>
                        <CreateNewIcon />
                    </Button>
                    <Button
                        className={`${styles.nodeButton} ${styles.nodeButtonGraphic}`}
                        onClick={onAddTemplateClick}
                        disabled={isButtonShouldDisable}
                    >
                        <span>Add template</span>
                        <CreateNewIcon />
                    </Button>
                </div>
            </div>
            <div id='copy-template'/>
            <Handle
                type="target"
                position="left"
                className={`${styles.nodeHandle} ${styles.nodeHandleLeft}`}
                isConnectable={props.isConnectable}
            />
            <Handle
                type="source"
                position="right"
                id="a"
                className={`${styles.nodeHandle} ${styles.nodeHandleRight}`}
                isConnectable={props.isConnectable}
            />
        </div>
    );
};
