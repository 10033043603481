import { Empty, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isAxiosError } from 'axios';

import { useQuery } from '../../../helpers';
import ManagersNavigationBar from '../../ManagersNavigationBar/ManagersNavigationBar';
import BillingPlansBlock from '../Billing/BillingPlansBlock/BillingPlansBlock';
import CurrentPlansPage from '../Billing/CurrentPlansPage/CurrentPlansPage';
import UsersManagementBlock from '../UserManagement/UsersManagementBlock/UsersManagementBlock';
import { SearchInput } from 'components/shared/SearchInput/SearchInput';
import { axiosApi } from 'axios-api';

import styles from './AdminManagementPage.module.css';
import { getPricingPlans } from 'api';

function AdminManagementPage({
  getOrganizations,
  selectedOrganizationsList,
  loadingOrganizations,
  selectOrganizationsList,
  totalOrganizations,
  cancelSubscription,
  switchSubscription,
  defaultBillingPlans,
  getBillingPlan,
  getPaymentLink,
  getLinkForPaymentMethod,
  changeDefaultPaymentMethod,
  onDeletingPaymentMethod,
  updateOrganizationName,
  isAddingNewOrganization,
  onAddingOrganizationFinish,
  clearProjectsList,
  toggleIsManagementSelected,
  organizationsByProjects
  }) {
  const history = useHistory();
  const { organizationId } = useParams();
  const query = useQuery();

  const [organizations, setOrganizations] = useState([]);
  const [newOrganizationName, setNewOrganizationsName] = useState('');
  const [organizationUpdating, setOrganizationUpdating] = useState(false);
  const [changingOrganization, setChangingOrganization] = useState(null);
  const [isOrganizationCreateSuccess, setIsOrganizationCreateSuccess] = useState(true);
  const [createdOrganization, setCreatedOrganization] = useState(null);
  const [additionalPlans, setAdditionalPlans] = useState([]);

  useEffect(() => {
    getOrganizations();
    clearProjectsList();
  }, []);

  useEffect(() => {
    void getPricingPlans().then((plans) => setAdditionalPlans(plans));
  }, [])

  useEffect(() => {
    const scrollToOrganization = (id) => {
      const element = document.getElementById(`organization=${id}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    };

    if (organizationId && !!organizations.length) {
      scrollToOrganization(organizationId);
    }
  }, [organizationId, organizations]);

  useEffect(() => {
    setOrganizations(selectedOrganizationsList);
  }, [selectedOrganizationsList]);

  const goToOrganizationsProjects = (organizationId) => {
    history.push(`/projects-new/${organizationId}`);
  };

  const updateSelectedListByMembers = (id, newMembersArray) => {
    const updatedOrganizationsList = organizations.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          members: newMembersArray,
        };
      }
      return item;
    });
    selectOrganizationsList(updatedOrganizationsList);
  };

  const choosePlanAndCreateOrganization = async (plan) => {
    if (newOrganizationName.length === 0) {
      return message.error({
        content:
          'The organization name is required to start billing process. Please firstly enter the organization name',
        duration: 5,
      });
    }

    const link = await getPaymentLink(createdOrganization.id, plan);

    if (link && link.payment_link) {
      window.open(link.payment_link);
      onAddingOrganizationFinish();
    } else {
      message.error({
        content: 'Something went wrong, try again in a while',
        duration: 3,
      });
    }
  };

  const submitOrganizationsName = async (value) => {
    setNewOrganizationsName(value.organizationName);

    try {
      const { data } = await axiosApi.post('v2/accounts/organizations/', {
        name: value.organizationName, 
      })
    
      message.info({
        content: "Organization has been successfully created",
        duration: 5,
      })

      setIsOrganizationCreateSuccess(false)

      setCreatedOrganization({
        id: data.id,
        name: value.organizationName,
      })

      return true
    } catch (error) {
      if(isAxiosError(error)) {
        message.error({ content: error.response.data.name[0], duration: 3 });
      } else {
        message.error({ content: 'Something went wrong when creating organization', duration: 3 });
      }

      setIsOrganizationCreateSuccess(true)

      return false
    }
  };

  const submitOrganizationNameChange = async (id, name) => {
    setChangingOrganization(id);
    await updateOrganizationName(id, name, setOrganizationUpdating);
  };

  const searchOrganizations = (list) => {
    setOrganizations(list);
    selectOrganizationsList(list);
  };

  const resetOrganizationsSearch = () => {
    setOrganizations(organizationsByProjects);
    selectOrganizationsList(organizationsByProjects);
  };

  const renderUsersBlock =
      <>
        <SearchInput
          entity='organization'
          entityHelper={organizationsByProjects}
          setEntities={searchOrganizations}
          onReset={resetOrganizationsSearch}
        />
        {isAddingNewOrganization && (
          <div className='inner-custom-container'>
            <div className={styles.innerBlockHeader}>
              <ManagersNavigationBar
                disabled
                isOrganizationNameOpen
                organizationName={newOrganizationName}
                submitOrganizationName={submitOrganizationsName}
                isOrganizationCreation
                organizationNamePlaceholder="Please enter the organization's name"
              />
            </div>
            <BillingPlansBlock
              isCreating
              getBillingPlan={getBillingPlan}
              getPaymentLink={choosePlanAndCreateOrganization}
              isOrganizationCreateSuccess={isOrganizationCreateSuccess}
              billingPlansDisabled={!newOrganizationName}
              defaultBillingPlans={defaultBillingPlans}
            />
          </div>
        )}

        {organizations.length > 0
          && organizations.map((organization) => (
              <div
                className='inner-custom-container'
                key={organization.id}
                id={`organization=${organization.id}`}
              >
                <div className={styles.innerBlockHeader}>
                  <ManagersNavigationBar
                    organizationName={organization.name}
                    submitOrganizationName={(value) =>
                      submitOrganizationNameChange(
                        organization.id,
                        value.organizationName,
                      )
                    }
                    isManagementSelected={organization.isManagementSelected}
                    setIsManagementSelected={() =>
                      toggleIsManagementSelected(organization)
                    }
                    organizationId={organization.id}
                    handleClick={() =>
                      goToOrganizationsProjects(organization.id)
                    }
                    generationAllowed={!organization.generation_allowed}
                    notice_code={organization.notice_code}
                    nameLoading={
                      changingOrganization === organization.id &&
                      organizationUpdating
                    }
                  />
                </div>
                {organization.isManagementSelected ? (
                  <UsersManagementBlock
                    organization={organization}
                    updateSelectedListByMembers={updateSelectedListByMembers}
                  />
                ) : (
                  <CurrentPlansPage
                    organization={organization}
                    cancelSubscription={cancelSubscription}
                    switchSubscription={switchSubscription}
                    defaultBillingPlans={defaultBillingPlans}
                    additionalPlans={additionalPlans}
                    getBillingPlan={getBillingPlan}
                    getPaymentLink={getPaymentLink}
                    getLinkForPaymentMethod={getLinkForPaymentMethod}
                    changeDefaultPaymentMethod={changeDefaultPaymentMethod}
                    onDeletingPaymentMethod={onDeletingPaymentMethod}
                    isAllowed={organization.generation_allowed}
                    query={query}
                    isOrganizationParamsMatch={
                      parseInt(organizationId) === organization.id
                    }
                  />
                )}
              </div>
            ))
           }

        {!loadingOrganizations &&
          selectOrganizationsList.length === 0 &&
          organizations.length === 0 &&  (
          <Empty
            className='centered-container'
            description={
              totalOrganizations.length > 0
                ? 'No organizations has been selected'
                : 'There are no organizations to display'
            }
          />
        )}
        {loadingOrganizations && (organizations.length === 0 || selectedOrganizationsList.length === 0) && (
          <div className='centered-container'>
            <Spin size='large' fullscreen/>
          </div>
        )}
      </>;

  return (
    <div className={`custom-container ${styles.userManagementBlock}`}>
      {renderUsersBlock}
    </div>
  );
}

export default AdminManagementPage;
