import { Button, message, Typography } from 'antd';
import React, { useEffect, useState } from 'react';

import { ReactComponent as Down } from '../../../../svg/icon/down-arrow.svg';
import { ReactComponent as Plus } from '../../../../svg/icon/plus.svg';
import BillingHistoryTable from '../BillingHistoryTable/BillingHistoryTable';
import { updateBillingPlansFeatures } from '../BillingPlanHelpers/BillingPlanHelpers';
import BillingPlansBlock from '../BillingPlansBlock/BillingPlansBlock';
import CurrentPlan from '../CurrentPlan/CurrentPlan';
import PaymentMethodCard from '../PaymentMethodCard/PaymentMethodCard';
import { NoticeCode } from '../../../../constants';

import styles from './CurrentPlansPage.module.css';

const { Text } = Typography;
function CurrentPlansPage({
  organization,
  cancelSubscription,
  switchSubscription,
  defaultBillingPlans,
  additionalPlans,
  getBillingPlan,
  getPaymentLink,
  getLinkForPaymentMethod,
  changeDefaultPaymentMethod,
  onDeletingPaymentMethod,
  isAllowed,
  query,
  isOrganizationParamsMatch,
}) {
  const [isBillingHistoryOpen, setIsBillingHistoryOpen] = useState(false);
  const [isBillingPlansOpen, setIsBillingPlanOpen] = useState(false);
  const [selectedBillingPlan, setSelectedBillingPlan] = useState(null);
  const [features, setFeatures] = useState([]);
  const [historyTableData, setHistoryTableData] = useState([]);

  useEffect(() => {
    if (organization && organization?.monthly_report?.plan) {
      const result = updateBillingPlansFeatures([
        organization.monthly_report.plan,
      ]);

      setFeatures(result[0].features);
    }
  }, [organization]);

  useEffect(() => {
    if (
      organization &&
      organization.payment_logs &&
      organization.payment_logs.length > 0
    ) {
      const historyData = organization.payment_logs.map((payment, index) => ({
        ...payment,
        key: `${payment.payment_date}-${index}`,
      }));

      setHistoryTableData(historyData);
    }
  }, [organization]);

  const addPaymentMethod = async (organizationId) => {
    const link = await getLinkForPaymentMethod(organizationId);

    if (link && link.payment_method_link) {
      window.open(link.payment_method_link);
    } else {
      message.error({
        content: 'Something went wrong, try again in a while',
        duration: 3,
      });
    }
  };

  const onPlanSelect = async (plan) => {
    if (!organization) return;
    await getPaymentLink(organization.id, plan).then((link) => {
      if (link.payment_link) {
        window.open(link.payment_link);
      } else {
        message.success({
          content: 'Custom plan has been successfully chosen. Please reload the page to apply the changes',
          duration: 10,
        })
      }
    }).catch((error) => {
      message.error({
        content: 'Something went wrong, try again in a while',
        duration: 3,
      });
    });
  };

  const switchPlan = async (plan) => {
    setSelectedBillingPlan(plan);
    await switchSubscription(organization.id, plan);
    setIsBillingPlanOpen(false);
    setSelectedBillingPlan(null);
  };

  const setDefaultPaymentMethod = async (paymentMethod, setLoading) => {
    if (paymentMethod.default) return;

    await changeDefaultPaymentMethod(
      organization.id,
      paymentMethod.id,
      setLoading,
    );
  };

  const monthlyReport = organization.monthly_report || []

  const isPlanCustom = !monthlyReport?.plan?.corporate_agreement

  return (
    <div>
      {isBillingPlansOpen || organization.notice_code === NoticeCode.ABSENT ? (
        <BillingPlansBlock
          additionalPlans={additionalPlans}
          selectedOrganization={organization}
          defaultBillingPlans={defaultBillingPlans}
          getBillingPlan={getBillingPlan}
          getPaymentLink={onPlanSelect}
          getLinkForPaymentMethod={getLinkForPaymentMethod}
          addPaymentMethod={addPaymentMethod}
          switchPlan={switchPlan}
          billingPlanLoading={selectedBillingPlan}
          closeBlock={()=> setIsBillingPlanOpen(false)}
        />
      ) : (
        <>
          <div className={styles.topBlock}>
            <div className={styles.topInnerBlock}>
              <CurrentPlan
                organizationId={organization.id}
                monthlyReport={monthlyReport}
                isPlanCustom={isPlanCustom}
                openBillingPlans={() => setIsBillingPlanOpen(true)}
                features={features}
                isAllowed={isAllowed}
                noticeCode={organization.notice_code}
                noticeDeadline={organization.notice_code === NoticeCode.TRIAL ? organization.trial_expires_at : organization.subscription_expires_at}
                query={query}
                isOrganizationParamsMatch={isOrganizationParamsMatch}
                cancelSubscription={cancelSubscription}
              />
                {isPlanCustom && (
                  <div className={`${styles.paymentsBlock} ${organization.notice_code === NoticeCode.TRIAL ? styles.paymentsBlockDisabled : ''}` }>
                    <Text className={styles.blockTitle}>Payment methods</Text>
                    <div className={styles.paymentsCardsWrapper}>
                      {organization.payment_methods &&
                        !!organization.payment_methods.length &&
                        organization.payment_methods.map((payment) => (
                          <PaymentMethodCard
                            key={payment.id}
                            payment={payment}
                            organization={organization}
                            onSelect={setDefaultPaymentMethod}
                            onDelete={onDeletingPaymentMethod}
                          />
                        ))}
                    </div>
                    <Button
                      onClick={() => addPaymentMethod(organization.id)}
                      className={styles.addPaymentButton}
                      type='text'
                    >
                      Add payment method <Plus />
                    </Button>
                  </div>
                )}
            </div>
          </div>
          {
            organization.notice_code !== NoticeCode.TRIAL && isPlanCustom && (
              <div className={styles.bottomInnerBlock}>
                <Button
                  type='text'
                  className={`${styles.billingToggleButton} ${styles.blockTitle}`}
                  onClick={() => setIsBillingHistoryOpen(!isBillingHistoryOpen)}
                  disabled={
                    organization.notice_code === NoticeCode.CANCELLED || organization.notice_code === NoticeCode.EXPIRED
                  }
                >
                  Billing history
                  <Down className={`${isBillingHistoryOpen && styles.downIcon}`} />
                </Button>
                {isBillingHistoryOpen && (
                  <BillingHistoryTable history={historyTableData} />
                )}
              </div>
            )
          }
        </>
      )}
    </div>
  );
}

export default CurrentPlansPage;
