import React from 'react';
import Appearance from '../TextAppearance/TextAppearance';
import Conditions from '../ConditionGroup/PropertyConditions';

export default ({ defaultProps, scaleText, fonts, uploadFont }) => {
  return (
    <>
      {defaultProps.activeTab === 1 && (
        <Appearance
          product={defaultProps.product}
          productFields={defaultProps.productFields}
          group={defaultProps.group}
          refreshToggle={defaultProps.refreshToggle}
          attachToElement={defaultProps.attachToElement}
          detachElement={defaultProps.detachElement}
          onPropertyChanged={defaultProps.onPropertyChanged}
          scaleText={scaleText}
          fonts={fonts}
          uploadFont={uploadFont}
        />
      )}
      {defaultProps.activeTab === 3 && (
        <Conditions
          product={defaultProps.product}
          productFields={defaultProps.productFields}
          group={defaultProps.group}
          checkCondition={defaultProps.checkCondition}
          reloadingProducts={defaultProps.reloadingProducts}
        />
      )}
    </>
  );
};
