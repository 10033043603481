import React from 'react';

import { ReactComponent as AlignLeftIcon } from 'svg/graphicEditor/alignment-left-small.svg';
import { ReactComponent as AlignRightIcon } from 'svg/graphicEditor/alignment-right-small.svg';
import { ReactComponent as AlignTopIcon } from 'svg/graphicEditor/alignment-top-small.svg';
import { ReactComponent as AlignBottomIcon } from 'svg/graphicEditor/alignment-bottom-small.svg';
import { ReactComponent as AlignVerticalCenterIcon } from 'svg/graphicEditor/alignment-vertical-center-small.svg';
import { ReactComponent as AlignHorizontalCenterIcon } from 'svg/graphicEditor/alignment-horizontal-center-small.svg';

export const distributionDirectionsDataSource = [
  {
    id: 'left',
    name: 'Left',
    icon: <AlignLeftIcon />,
  },
  {
    id: 'right',
    name: 'Right',
    icon: <AlignRightIcon />,
  },
  {
    id: 'up',
    name: 'Up',
    icon: <AlignTopIcon />,
  },
  {
    id: 'down',
    name: 'Down',
    icon: <AlignBottomIcon />,
  },
];

export const distributionAlignVerticalDataSource = [
  {
    id: '1',
    name: 'Top',
    icon: <AlignTopIcon />,
  },
  {
    id: '2',
    name: 'Middle',
    icon: <AlignVerticalCenterIcon />,
  },
  {
    id: '3',
    name: 'Bottom',
    icon: <AlignBottomIcon />,
  },
];

export const distributionAlignHorizontalDataSource = [
  {
    id: '1',
    name: 'Left',
    icon: <AlignLeftIcon />,
  },
  {
    id: '2',
    name: 'Center',
    icon: <AlignHorizontalCenterIcon />,
  },
  {
    id: '3',
    name: 'Right',
    icon: <AlignRightIcon />,
  },
];
