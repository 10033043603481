import React, {useEffect, useState, useRef} from 'react';

export default (props) => {    
    const div = useRef();
    const [loaded, setLoaded] = useState(false);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (div.current && div.current.children && div.current.children.length) {            
            div.current.removeChild(div.current.children[0]);
        }

        const image = new window.Image();
        image.src = props.src;   
        image.style.width = '90%';
        image.style.margin = '5px';
        image.onload = () => {
            setLoaded(true);

            if (div.current) {
                const height = div.current.clientHeight;
                if (height > 0 && height < 500) {
                    setHeight(height);
                }
            }
        }
        image.onerror = () => props.checkImageAvailable(props.productId);
        div.current.appendChild(image);
        setLoaded(false);
    }, [props.src]);

    return (
        <div>
            <div ref={div} style={{display: loaded ? 'block' : 'none'}}></div>
            {!loaded && <div style={{width: '90%', height: height}}>&nbsp;</div>}
        </div>
    );
};