export const isAutoField = (name) => {
    return name.startsWith('__');
}

export const getProductFieldDisplayName = (fieldName) => {
    if (!fieldName) {
        return '';
    }

    let name = fieldName ?? '';

    if (isAutoField(fieldName)) {
        const displayName = getDisplayNameByFieldName(name);

        if (!displayName) {
            name = name.replace('__', '').replace('_numeric', '');
            // const parts = name.split('_');
            // name = parts.join(' ');
        } else {
            name = displayName;
        }
    }

    return name;
}

const mapping = {
    '_ya_price': 'current price',
    '_ya_old_price': 'old price',
    '_discount': 'sale percent',
    '_difference': 'discount'
};

const getDisplayNameByFieldName = (fieldName) => {
    if (fieldName.startsWith('__')) {        
        for (let item in mapping) {
            if (fieldName.indexOf(item) >= 0) {
                return mapping[item];
            }
        }
    }

    return '';
}

export const filterProductFieldsForSelect = (dataSource) => {    
    return dataSource.filter(t => t.name.indexOf('_changed') === -1);
}

export const cleanProductImageGroup = (groupChildren) => {
  return groupChildren.reduce((acc, item) => {
    const isElementBackground = item.attrs.name === "element background";
    const isDynamicField = item.attrs.dynamicField;

    const hasElementBackground = acc.some(obj => obj.attrs.name === "element background");
    const hasDynamicField = acc.some(obj => obj.attrs.dynamicField);
    if (isElementBackground && !hasElementBackground) {
      acc.push(item);
    } else if (isDynamicField && !hasDynamicField) {
      acc.push(item);
    }

    return acc;
  }, []);
};
