import React, { useEffect, useState } from 'react';
import { InputNumber, Typography } from 'antd';

import ColorEditor from '../../controls/ColorEditor/ColorEditor';
import BorderControl from '../../controls/BorderControl/BorderControl';
import ShadowControl from '../../controls/ShadowControl/ShadowControl';
import RotationControl from '../../controls/RotationControl/RotationControl';
import PositioningControl from '../../controls/PositioningControl/PositioningControl';
import PositionSizeRadiusScaleControl from '../../controls/PositionSizeControl/PositionSizeRadiusScaleControl';
import { refreshTransform } from '../PropertiesHelper';

import globalStyles from '../../GlobalGraphicEditor.module.css';

const { Text } = Typography;

export default function (props) {
  const shape = props.group.findOne('.background');

  const [layoutPositioning, setLayoutPositioning] = useState();

  const [shapeNumPoints, setShapeNumPoints] = useState();
  const [shapeInnerRadius, setShapeInnerRadius] = useState();
  const [shapeOuterRadius, setShapeOuterRadius] = useState();
  const [refreshSizeToggle, setRefreshSizeToggle] = useState();

  useEffect(() => {
    updateRefreshSizeToggle();
  }, [props.refreshToggle]);

  useEffect(() => {
    setShapeNumPoints(shape.getAttr('numPoints'));
    setShapeInnerRadius(shape.getAttr('innerRadius'));
    setShapeOuterRadius(shape.getAttr('outerRadius'));
  }, [shape, props.group, props.refreshToggle]);

  const changeAndRedraw = (obj, property, val, setter, minVal) => {
    if (minVal === undefined || val >= minVal) {
      obj.setAttr(property, val);
      if (setter) setter(val);
      propertyChanged();
    }
  };

  const changeShapeNumPoints = (value) => {
    changeAndRedraw(shape, 'numPoints', parseInt(value), setShapeNumPoints, 3);
  };

  const changeShapeInnerRadius = (value) => {
    changeAndRedraw(
      shape,
      'innerRadius',
      parseInt(value),
      setShapeInnerRadius,
      1,
    );
  };

  const changeShapeOuterRadius = (value) => {
    const val = parseInt(value);
    if (isNaN(val)) {
      return;
    }

    shape.setAttr('offset', { x: -val, y: -val });
    changeAndRedraw(
      shape,
      'outerRadius',
      parseInt(value),
      setShapeOuterRadius,
      1,
    );
    updateRefreshSizeToggle();
  };

  const propertyChanged = () => {
    refreshTransform(props.group);
    if (props.onPropertyChanged) {
      props.onPropertyChanged();
    }
  };

  const updateRefreshSizeToggle = () => {
    setRefreshSizeToggle((refreshSizeToggle) => !refreshSizeToggle);
  };

  const rotationChanged = () => {
    updateRefreshSizeToggle();
    propertyChanged();
  };

  return (
    <div>
      <div
        className={`${globalStyles.bottomBorderDivider} ${globalStyles.bottomBigPaddings}  ${globalStyles.selectBlock} ${globalStyles.bigGapBlock}`}
      >
        <PositionSizeRadiusScaleControl
          element={shape}
          group={props.group}
          layoutPositioning={layoutPositioning}
          refreshToggle={refreshSizeToggle}
          onPropertyChanged={propertyChanged}
          radiusName='outerRadius'
        />
        <PositioningControl
          group={props.group}
          attachToElement={props.attachToElement}
          detachElement={props.detachElement}
          refreshToggle={props.refreshToggle}
          setLayoutPositioning={setLayoutPositioning}
          onPropertyChanged={propertyChanged}
        />
      </div>

      <div className={globalStyles.selectBlock}>
        <div className={globalStyles.bottomBorderDivider}>
          <div
            className={`${globalStyles.spaceBetweenBlock} ${globalStyles.openBlock}`}
          >
            <Text className={globalStyles.text}>Background colour:</Text>
            <ColorEditor element={shape} onPropertyChanged={propertyChanged} />
          </div>
        </div>
        <div
          className={`${globalStyles.selectBlock} ${globalStyles.bottomBorderDivider} ${globalStyles.gapBlock}`}
        >
          <div className={globalStyles.spaceBetweenBlock}>
            <Text className={globalStyles.text}>Num Points:</Text>
            <InputNumber
              size='small'
              min={3}
              max={100}
              value={shapeNumPoints}
              onChange={changeShapeNumPoints}
              className={globalStyles.input}
            />
          </div>

          <div className={globalStyles.spaceBetweenBlock}>
            <Text className={globalStyles.text}>Inner Radius:</Text>
            <InputNumber
              size='small'
              min={0}
              value={shapeInnerRadius}
              onChange={changeShapeInnerRadius}
              className={globalStyles.input}
            />
          </div>

          <div className={globalStyles.spaceBetweenBlock}>
            <Text className={globalStyles.text}>Outer Radius:</Text>
            <InputNumber
              size='small'
              min={0}
              value={shapeOuterRadius}
              onChange={changeShapeOuterRadius}
              className={globalStyles.input}
            />
          </div>
        </div>

        <div className={globalStyles.bottomBorderDivider}>
          <BorderControl element={shape} onPropertyChanged={propertyChanged} />
        </div>
        <div className={globalStyles.bottomBorderDivider}>
          <ShadowControl element={shape} onPropertyChanged={propertyChanged} />
        </div>
        <div className={globalStyles.bottomBorderDivider}>
          <RotationControl
            group={props.group}
            element={shape}
            refreshToggle={props.refreshToggle}
            onPropertyChanged={rotationChanged}
          />
        </div>
      </div>
    </div>
  );
}
