import React from 'react';
import ReactDOM from 'react-dom';

const root = document.body;

export default class Portal extends React.Component {
    constructor(props) {
      super(props);
      this.el = document.createElement('div');
    }
  
    componentDidMount() {
      root.appendChild(this.el);
    }
  
    componentWillUnmount() {
        root.removeChild(this.el);
    }
  
    render() {
      return ReactDOM.createPortal(
        this.props.children,
        this.el
      );
    }
}
  