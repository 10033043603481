import React, { useEffect, useState } from 'react';
import { Select, Button, Tooltip, Input, Divider, Space, Typography } from 'antd';
import ValueSelector from './ValueSelector';
import {
  CloseOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  validateMapping,
  validateXmlMapping,
} from '../services/ValidateService';

import {ReactComponent as PlusIcon} from 'svg/icon/plus-bold.svg';

import styles from '../MappingPage.module.css';

const {Text} = Typography;
export default (props) => {
    const changeFeedField = (mapping, value) => {
        const feedField = feedFields.find(t => t.name === value);        
        mapping.type = feedField.type ? feedField.type : 'field';
        mapping.feedFieldId = value;
        setMappings([...mappings]);
    }

    const [mappings, setMappings] = useState([]);
    const [newAttributeName, setNewAttributeName] = useState();
    const [paramAttributes, setParamAttributes] = useState([]);

    useEffect(() => {
        setMappings(props.mappings);
    }, [props.mappings]);

    useEffect(() => {
        setParamAttributes(props.paramAttributes);
    }, [props.paramAttributes]);

    const checkMapping = (mapping) => {        
        if (mapping) {
            const oldError = mapping.error;
            mapping.error = mapping.type === 'param' ? validateXmlMapping(mapping, props.productFields) : validateMapping(mapping, props.productFields, true);
            if (oldError !== mapping.error) {
                setMappings([...mappings]);
            }
        }
    }

    const deleteMapping = (mapping) => {
        if (props.deleteMapping) {
            props.deleteMapping(mapping);
        }
    }

    const addMapping = () => {
        if (props.addNewMapping) {
            props.addNewMapping();
        }
    }

    const addParam = () => {
        if (props.addNewMapping) {
            props.addNewMapping('param');
        }
    }

  const getNextAttributeValue = (attributeName, givenAttribute) => {
    if (givenAttribute && givenAttribute.includes(attributeName)) {
      return givenAttribute;
    }

    let maxNumber = 1;
    let attributeExists = false;

    mappings.forEach(mapping => {
      if (mapping.attribute && mapping.attribute.includes(attributeName)) {
        attributeExists = true;
        const attributeNumber = parseInt(mapping.attribute.replace(`${attributeName}__`, '').trim());
        if (!isNaN(attributeNumber) && attributeNumber > maxNumber) {
          maxNumber = attributeNumber;
        }
      }
    });

    return attributeExists ? `${attributeName}__${maxNumber + 1}` : attributeName;
  };

    const changeAttribute = (mapping, value) => {
      mapping.attribute = getNextAttributeValue( value, mapping?.attribute);

      if (mapping.error) {
        checkMapping(mapping);
      }
      setMappings([...mappings]);
    }

    const onNewAttributeNameChange = (event) => {
        setNewAttributeName(event.target.value);        
    }

    const addNewAttributeItem = () => {
        if (newAttributeName && !paramAttributes.includes(newAttributeName)) {
            paramAttributes.push(newAttributeName);
            setParamAttributes([...paramAttributes]);
            setNewAttributeName('');
        }
    }

    const onMappingValueChange = (mapping, value) => {
        props.onChange(mapping, value);
        checkMapping(mapping);
    }

    const feedFields = props.feedFields ?? [];

  return (
    <div className={styles.inputsWrapper}>
      <div className={`${styles.flexContainer} ${styles.halfBlock}`}>
        <div className={styles.halfBlock}>
          <Text className={styles.grayTitle}>
            Output feed fields:
          </Text>
        </div>
        <div className={`${styles.halfBlock} ${styles.rightBlock}`}>
          <Text className={styles.grayTitle}>
            Data source fields:
          </Text>
        </div>
      </div>
      {mappings && (
          <div className={styles.mappingsWrapper}>
          {mappings.map((mapping, i) => {
            return (
              <div
                key={`div-${i}`}
                className={`${styles.flexContainer} ${styles.mappingBlock}`}
              >
                {mapping.type === 'field' && (
                  <div className={styles.column}>
                    <Select
                      disabled={mapping.required}
                      size='small'
                      value={mapping.feedFieldId}
                      className={`${styles.outputInput} ${styles.select}`}
                      placeholder='Select the field you want to add:'
                      onChange={(e) => {
                        changeFeedField(mapping, e);
                      }}
                    >
                      {feedFields
                        .filter(
                          (t) =>
                            !mappings.find(
                              (m) => m.feedFieldId === t.name && !t.multiUse,
                            ) || mapping.feedFieldId === t.name,
                        )
                        .map((t, i2) => {
                          return (
                            <Select.Option
                              style={{
                                fontWeight:
                                  t.type === 'param' ? 'bold' : 'normal',
                              }}
                              key={`field-${i2}`}
                              value={t.name}
                            >
                              {t.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </div>
                )}
                {mapping.type === 'param' && (
                  <div
                    className={`${styles.flexContainer} ${styles.equalValue}`}
                  >
                    <span className={styles.param}>param</span>
                  </div>
                )}
                {mapping.type === 'field' && (
                  <>
                    <div className={`${styles.flexContainer} ${styles.equalValue}`}>=</div>
                    <div className={styles.column}>
                      <ValueSelector
                        fields={props.productFields}
                        value={mapping.value}
                        disabled={mapping.readonly}
                        onChange={(value) =>
                          onMappingValueChange(mapping, value)
                        }
                      />
                    </div>
                    <div className={`${styles.flexContainer} ${styles.actionBlock}`}>
                      {!!mapping.error && (
                        <Tooltip title={mapping.error} mouseEnterDelay='0'>
                          <InfoCircleOutlined className={styles.errorIcon} />
                        </Tooltip>
                      )}
                      {!mapping.required && (
                        <Button
                          size='small'
                          icon={<CloseOutlined />}
                          type='text'
                          onClick={() => deleteMapping(mapping)}
                        />
                      )}
                    </div>
                  </>
                )}
                {mapping.type === 'param' && (
                  <>
                    <div className={styles.column}>
                      <Select
                        className={`${styles.outputInput} ${styles.select}`}
                        value={mapping.attribute}
                        size='small'
                        onChange={(value) => changeAttribute(mapping, value)}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space
                              align='center'
                              style={{ padding: '0 8px 4px' }}
                            >
                              <Input
                                size='small'
                                placeholder='Attribute name'
                                value={newAttributeName}
                                onChange={onNewAttributeNameChange}
                              />
                              <Button
                                onClick={() => addNewAttributeItem()}
                                style={{ whiteSpace: 'nowrap' }}
                                size='small'
                              >
                                Add
                              </Button>
                            </Space>
                          </>
                        )}
                      >
                        {paramAttributes.map((attribute) => (
                          <Select.Option key={`param-${attribute}`} value={attribute}>
                            {attribute}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className={`${styles.flexContainer} ${styles.equalValue}`}>=</div>
                    <div className={styles.column}>
                      <ValueSelector
                        fields={props.productFields}
                        value={mapping.value}
                        onChange={(value) =>
                          onMappingValueChange(mapping, value)
                        }
                      />
                    </div>
                    <div className={`${styles.flexContainer} ${styles.actionBlock}`}>
                      {!!mapping.error && (
                        <Tooltip title={mapping.error} mouseEnterDelay='0'>
                          <InfoCircleOutlined className={styles.errorIcon} />
                        </Tooltip>
                      )}
                      {!mapping.required && (
                        <Button
                          size='small'
                          type='text'
                          icon={<CloseOutlined />}
                          onClick={() => deleteMapping(mapping)}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            );
          })}
          <div
            className={`${styles.flexContainer} ${styles.bottomBlock}` }
          >
            <div className={styles.flexContainer}>
              <Button
                type='text'
                icon={<PlusIcon />}
                className={styles.addFieldButton}
                onClick={() => addMapping()}

              >
                {props.isXmlFormat ? 'Add field' : 'Add'}
              </Button>
              {props.isXmlFormat && (
                <Button
                  type='text'
                  icon={<PlusIcon />}
                  className={styles.addFieldButton}
                  onClick={() => addParam()}
                >
                  Add param
                </Button>
              )}
            </div>
            <Button
                className={styles.saveButton}
                onClick={props.save}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
