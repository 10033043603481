
export const roleItems = [
    {
        value: 'admin',
        label: 'Admin',
    },
    {
        value: 'editor',
        label: 'Editor',
    },
    {
        value: 'viewer',
        label: 'Viewer',
    },
];
