import React, { useEffect, useState } from 'react';
import Konva from 'konva';
import { Button, InputNumber, Slider, Typography } from 'antd';

import { ReactComponent as PlusIcon } from 'svg/graphicEditor/plus-big-black.svg';
import { ReactComponent as MinusIcon } from 'svg/graphicEditor/minus-big-black.svg';
import { ReactComponent as RotationIcon } from 'svg/graphicEditor/rotation-small.svg';

import globalStyles from '../../GlobalGraphicEditor.module.css';

const { Text } = Typography;

export default function (props) {
  const [isRotationEnabled, setRotationEnabled] = useState();
  const [rotation, setRotation] = useState();

  const { element } = props;
  const { group } = props;

  useEffect(() => {
    setRotationEnabled(!!group.getAttr('rotation'));
    let value = parseInt(group.getAttr('rotation'));
    if (value < 0) {
      value = 360 - value;
    }
    setRotation(parseInt(group.getAttr('rotation')));
  }, [group, props.refreshToggle]);

  const changeRotationFromUI = (value) => {
    changeRotation(value);

    if (parseInt(value) === 0) {
      group.setAttr('_lastStateRotation', { rotation: 0 });
    }
  };

  const rotatePoint = ({ x, y }, rad) => {
    const rcos = Math.cos(rad);
    const rsin = Math.sin(rad);
    return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
  };

  const calculateDeltaXY = (newRotation) => {
    const scaleX = group.scaleX();
    const scaleY = group.scaleY();

    let width = element.width() * (scaleX ?? 1);
    let height = element.height() * (scaleY ?? 1);

    const extraDimension = group.getAttr('extraDimension');
    if (extraDimension) {
       width = extraDimension.width * (scaleX ?? 1);
       height = extraDimension.height * (scaleY ?? 1);
    }

    const topLeft = { x: -width / 2, y: -height / 2 };
    const current = rotatePoint(topLeft, Konva.getAngle(rotation));
    const rotated = rotatePoint(topLeft, Konva.getAngle(newRotation));
    const dx = rotated.x - current.x;
    const dy = rotated.y - current.y;

    return [dx, dy];
  };

  const changeRotation = (value) => {
    const val = parseInt(value);
    if (isNaN(val)) {
      return;
    }

    const [dx, dy] = calculateDeltaXY(val);

    group.x(group.x() + dx);
    group.y(group.y() + dy);

    group.setAttr('rotation', val);

    setRotation(val);
    propertyChanged();
  };

  const changeRotationEnable = () => {
    setRotationEnabled(!isRotationEnabled);
    updateState(!isRotationEnabled);
  };

  const updateState = (isEnabled) => {
    if (!isEnabled) {
      group.setAttr('_lastStateRotation', { rotation });
      changeRotation(0);
    } else {
      const state = group.getAttr('_lastStateRotation');
      if (state) {
        changeRotation(state.rotation);
      }
    }
  };

  const propertyChanged = () => {
    if (props.onPropertyChanged) {
      props.onPropertyChanged();
    }
  };

  return (
    <div>
      <div className={globalStyles.spaceBetweenBlock}>
        <Text className={globalStyles.smallTitle}>Rotation</Text>
        <Button
          shape='circle'
          type='text'
          icon={isRotationEnabled ? <MinusIcon /> : <PlusIcon />}
          onClick={changeRotationEnable}
        />
      </div>
      {isRotationEnabled && (
        <div
          className={`${globalStyles.innerBlock} ${globalStyles.selectBlock} ${globalStyles.gapBlock}`}
        >
          <div className={globalStyles.spaceBetweenBlock}>
            <Text className={globalStyles.text}>Rotation value:</Text>
            <InputNumber
              size='small'
              min={-180}
              max={180}
              step={1}
              value={rotation}
              prefix={<RotationIcon />}
              onChange={changeRotationFromUI}
              className={globalStyles.input}
            />
          </div>

          <Slider
            min={-180}
            max={180}
            value={rotation}
            onChange={changeRotationFromUI}
          />
        </div>
      )}
    </div>
  );
}
