import React from 'react';

import Header from '../graphicEditor/components/properties/PropertiesHeader/PropertiesHeader';
import trashIcon from '../../svg/node/trash.svg';
import uploadFontIcon from '../../svg/sidebar/upload-font.svg';

import './fonts-editor.css';
import { Popconfirm, Upload } from 'antd';

const styles = {
  main: {
    position: 'absolute',
    top: 0,
    right: '0px',
    zIndex: 2,
    width: '390px',
    height: '100vh',
    background: 'white',
    boxShadow: '4px 4px 12px #C3C4C4',
    padding: '25px 10px',
    overflow: 'overlay',
  },
  fontsContainer: {
    display: 'block',
    overflowX: 'none',
    overflowY: 'auto',
    height: 'calc(100vh - 500px)',
    maxHeight: 'calc(100vh - 500px)',
    margin: '0 0 0 0',
  },
  fontItemLine: {
    padding: '4px 4px 4px 8px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
  },
  delete: {
    marginRight: '10px',
  },
  buttonsArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '40px',
  },
  uploadButton: {
    marginTop: '15px',
    borderRadius: '2px',
    border: '1px solid #A6AAAB',
    background: 'white',
    color: '#4A4A4A',
    padding: '5px 10px',
    fontSize: '14px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  uploadText: {
    fontSize: '14px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    margin: '20px 40px 0 40px',
  },
};

function Fonts(props) {
  const fonts = props.fonts ?? [];

  const confirmDeleteFont = (e, font) => {
    e.stopPropagation();
    props.deleteFont(font.id);
  };

  const uploadFont = async ({ onSuccess, onError, file }) => {
    try {
      const result = await props.uploadFont(file);

      if (result) {
        onSuccess(result);
      } else {
        onError();
      }
    } catch (error) {
      onError();
    }
  };

  return (
    <div style={styles.main} className='scroll-panel'>
      <Header onClose={props.onClose} caption='Fonts' />
      <div style={styles.fontsContainer} className='scroll-panel'>
        {fonts.map((font, i) => {
          return (
            <div
              className='fonts-editor-font-item'
              key={`field-${i}`}
              style={styles.fontItemLine}
            >
              <div
                style={{
                  flex: '1',
                  fontSize: '18px',
                  fontFamily: `${font.family}`,
                }}
              >
                {font.family}
              </div>
              <div
                className='fonts-editor-font-item-delete'
                style={styles.delete}
              >
                <Popconfirm
                  title={`Delete font '${font.name}' ?`}
                  onClick={(e) => e.stopPropagation()}
                  onConfirm={(e) => {
                    confirmDeleteFont(e, font);
                  }}
                  onCancel={(e) => e.stopPropagation()}
                  okText='Yes'
                  cancelText='No'
                >
                  <img
                    src={trashIcon}
                    alt=''
                    className='fonts-editor-font-item-delete-icon'
                  />
                </Popconfirm>
              </div>
            </div>
          );
        })}
      </div>
      <div style={styles.buttonsArea}>
        <img src={uploadFontIcon} alt='' />

        <Upload
          accept='.ttf'
          fileList={[]}
          multiple={false}
          customRequest={(onSuccess, onError, file) =>
            uploadFont(onSuccess, onError, file)
          }
        >
          <button style={styles.uploadButton}>upload new font</button>
        </Upload>

        <div style={styles.uploadText}>
          Prepare font files for different weights (light, regular, bold) in
          WOFF and WOFF2 format. If you have a font in EOT or TTF format, you
          need to convert them to WOFF using an online converter.
        </div>
      </div>
    </div>
  );
}

export default Fonts;
