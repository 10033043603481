import React from 'react';
import {Tooltip} from "antd";

import {iconsList} from "../../../../svg/icons-list";
import MyButton from "../../../shared/MyButton/MyButton";
import {formatNumbers} from "../../../../helpers";
import {COLORS, EMAILS} from "../../../../constants";
import styles from './BillingPlanCard.module.css';


const BillingPlanCard = ({
                             plan,
                             onSelect,
                             active,
                             activePlan,
                             loading = false,
                             disabled = false,
                             isCreating = false,
                             isOrganizationCreateSuccess = false
                         }) => {

    const pushToMail = () => {
        window.location = `mailto:${EMAILS.join(',')}?subject=Flex billing plan for Dynamic Ads`;
    };

    return (
        <Tooltip
            title={!isCreating && plan.id < activePlan?.id ? 'You can only switch to a higher plan. To get a lower plan, please cancel your subscription and get a new one' : ''}
            placement='bottom'
        >
            <div
                className={`
                ${styles.card} 
                ${active && styles.activeCard}
            `}
            >
                <div className={styles.upperBlock}>
                    <p className={styles.upperBlockHeader}>
                        {plan.cost !== 'flex' ? `${formatNumbers(plan.feed_count)} items` : 'MORE'}
                    </p>
                    <p className={styles.upperBlockDescr}>
                        For feeds with {plan.cost !== 'flex' ? 'up to' : 'over than'} {plan.feed_count} products
                    </p>
                </div>
                <p className={styles.price}>
                 {plan.cost !== 'flex' ? `$${plan.cost.slice(0, -3)}` : 'Flex'}<span> /month</span>
                </p>
                <div className={styles.features}>
                    {plan.features.map((feature) => (
                        <div className={styles.feature} key={feature}>
                            <div>{iconsList.thick}</div>
                            <span>{feature}</span>
                        </div>
                    ))}
                </div>

                <MyButton
                    text={plan.cost === 'flex' ? 'Contacts us' : active ? 'Active plan' : 'Choose plan'}
                    customStyles={{
                        background: active && COLORS.white,
                        color: active && COLORS.mainViolet
                    }}
                    disabled={isOrganizationCreateSuccess || disabled || active || (!isCreating && plan.id < activePlan?.id )}
                    onClick={() => plan.cost === 'flex' ? pushToMail() : onSelect(plan?.id)}
                    loading={loading}
                />

            </div>
        </Tooltip>
    );
};

export default BillingPlanCard;