import {useLayoutEffect, useState, useEffect} from "react";

export const useWindowSize = () => {
    const [size, setSize] = useState([
        window.innerWidth, window.innerHeight
    ]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export const prepareOpacityValue = (value) => {
    return value || value === 0 ? parseInt(parseFloat(value) * 100) : 100;
}

export const useWindowKeyDown = () => {
    let [keyFired, setKeyFired] = useState(undefined);

    const setKeyFiredProcessed = () => {
        setKeyFired(undefined);
    }

    useEffect(() => {
        const onKeyDown = (e) => {
            const tagName = document.activeElement.tagName.toLowerCase();
            const isInput = tagName === 'input' || tagName === 'textarea';
            if (e.keyCode === 71 && (e.ctrlKey || e.metaKey)) {
                setKeyFired(KeyCombinations.ctrlG);
                e.preventDefault();
            }
            if (e.keyCode === 68 && (e.ctrlKey || e.metaKey)) {
                setKeyFired(KeyCombinations.ctrlD);
                e.preventDefault();
            }
            if (e.keyCode === 90 && (e.ctrlKey || e.metaKey) && !isInput) {
                setKeyFired(KeyCombinations.ctrlZ);
                e.preventDefault();
            }
            if (e.keyCode === 89 && (e.ctrlKey || e.metaKey) && !isInput) {
                setKeyFired(KeyCombinations.ctrlY);
                e.preventDefault();
            }            
            if (e.keyCode === 46 && !isInput) {
                setKeyFired(KeyCombinations.delete);
                e.preventDefault();
            }
            if (e.keyCode === 8 && !isInput) {
                setKeyFired(KeyCombinations.backspace);
                e.preventDefault();
            }
        }
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        }
    }, []);

    return [keyFired, setKeyFiredProcessed];
}

export const KeyCombinations = {
    ctrlG: 'ctrlG',
    ctrlD: 'ctrlD',
    ctrlZ: 'ctrlZ',
    ctrlY: 'ctrlY',
    delete: 'delete',
    backspace: 'backspace'
}

export const SetEditableByDblClick = (text, stage, onRefresh) => {
    text.on('dblclick dbltap', () => {
        const tr = stage.findOne('Transformer');

        const transformerHeight = tr.height();
        const transformerWidth = tr.width();

        text.hide();
        tr.hide();

        var textPosition = text.absolutePosition();

        var areaPosition = {
            x: stage.container().offsetLeft + textPosition.x,
            y: stage.container().offsetTop + textPosition.y,
        };

        // create textarea and style it
        var textarea = document.createElement('textarea');
        document.body.appendChild(textarea);

        // apply many styles to match text on canvas as close as possible
        // remember that text rendering on canvas and on the textarea can be different
        // and sometimes it is hard to make it 100% the same. But we will try...
        textarea.value = text.getAttr('placeholder');
        textarea.style.position = 'absolute';
        textarea.style.top = areaPosition.y + 'px';
        textarea.style.left = areaPosition.x + 'px';

        //textarea.style.width = text.width() - text.padding() * 2 + 'px';
        //textarea.style.height = text.height() - text.padding() * 2 + 5 + 'px';
        textarea.style.width = transformerWidth + 'px';
        textarea.style.height = transformerHeight + 'px';

        textarea.style.fontSize = text.fontSize() + 'px';
        textarea.style.border = '1px solid #58B1FF';
        textarea.style.padding = '5px';
        textarea.style.margin = '0px';
        textarea.style.overflow = 'hidden';
        textarea.style.background = 'none';
        textarea.style.outline = 'none';
        textarea.style.resize = 'none';
        textarea.style.lineHeight = text.lineHeight();
        textarea.style.fontFamily = text.fontFamily();
        textarea.style.transformOrigin = 'left top';
        textarea.style.textAlign = text.align();
        textarea.style.color = text.fill();

        const rotation = text.rotation();
        var transform = '';
        if (rotation) {
            transform += 'rotateZ(' + rotation + 'deg)';
        }

        var px = 0;
        // also we need to slightly move textarea on firefox
        // because it jumps a bit
        var isFirefox =
            navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isFirefox) {
            px += 2 + Math.round(text.fontSize() / 20);
        }
        transform += 'translateY(-' + px + 'px)';

        textarea.style.transform = transform;

        // reset height
        textarea.style.height = 'auto';
        // after browsers resized it we can set actual value
        textarea.style.height = textarea.scrollHeight + 3 + 'px';

        textarea.focus();

        function removeTextarea() {
            textarea.parentNode.removeChild(textarea);
            window.removeEventListener('click', handleOutsideClick);
            text.show();
            tr.show();
            tr.forceUpdate();
        }

        function setTextareaWidth(newWidth) {
            if (!newWidth) {
                // set width for placeholder
                newWidth = text.placeholder.length * text.fontSize();
            }
              // some extra fixes on different browsers
            var isSafari = /^((?!chrome|android).)*safari/i.test(
                navigator.userAgent
            );

            var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            if (isSafari || isFirefox) {
                newWidth = Math.ceil(newWidth);
            }

            var isEdge = document.documentMode || /Edge/.test(navigator.userAgent);
            if (isEdge) {
                newWidth += 1;
            }

            textarea.style.width = newWidth + 'px';
        }

        textarea.addEventListener('keydown', function (e) {
            if (e.keyCode === 13 && !e.shiftKey) {
                setTextValue(textarea.value);
                removeTextarea();
            }

            if (e.keyCode === 27) {
                removeTextarea();
            }
        });

        textarea.addEventListener('keydown', function (e) {
            const scale = text.getAbsoluteScale().x;
            setTextareaWidth(text.width() * scale);
            textarea.style.height = 'auto';
            textarea.style.height =
            textarea.scrollHeight + text.fontSize() + 'px';
        });

        function handleOutsideClick(e) {
            if (e.target !== textarea) {
                setTextValue(textarea.value);
                removeTextarea();
            }
        };

        function setTextValue(value) {
            text.text(value);
            text.setAttr('placeholder', value);
            text.setAttr('fullText', value);
            if (onRefresh) {
                onRefresh(text);
            }
        }

        setTimeout(() => {
            window.addEventListener('click', handleOutsideClick);
        });
    });
}
