import React from 'react';

import Appearance from '../ImageAppearance/ImageAppearance';
import Conditions from '../ConditionGroup/PropertyConditions';

export default function ({
  defaultProps,
  scaleImage,
  uploadFile,
  userImages,
  deleteImage,
  stage,
}) {
  return (
    <>
      {defaultProps.activeTab === 1 && (
        <Appearance
          group={defaultProps.group}
          product={defaultProps.product}
          productFields={defaultProps.productFields}
          refreshToggle={defaultProps.refreshToggle}
          attachToElement={defaultProps.attachToElement}
          detachElement={defaultProps.detachElement}
          onPropertyChanged={defaultProps.onPropertyChanged}
          scaleImage={scaleImage}
          uploadFile={uploadFile}
          userImages={userImages}
          deleteImage={deleteImage}
          stage={stage}
        />
      )}
      {defaultProps.activeTab === 3 && (
        <Conditions
          group={defaultProps.group}
          product={defaultProps.product}
          productFields={defaultProps.productFields}
          checkCondition={defaultProps.checkCondition}
          reloadingProducts={defaultProps.reloadingProducts}
        />
      )}
    </>
  );
}
