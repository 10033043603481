import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Divider, Form, Input, Result } from 'antd';
import MyButton from '../../shared/MyButton/MyButton';

import { SmileOutlined } from '@ant-design/icons';
import logo from '../../../svg/common/logo.svg';

const RequestResetPassword = ({ onReset }) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isResetSuccess, setIsResetSuccess] = useState(false);

  const onFinish = async (values) => {
    const result = await onReset(values, setError, setLoading);
    if (result) {
      setIsResetSuccess(true);
    }
  };

  return (
    <div className='centered-container'>
      <Col className='login-panel'>
        {isResetSuccess ? (
          <Result
            icon={<SmileOutlined className='registration-confirm-icon' />}
            title='Email successfully sent!'
            subTitle='Check your email to get a reset password link'
            extra={
              <Button size='large' type='primary' htmlType='link' href='/login'>
                Return to login page
              </Button>
            }
          />
        ) : (
          <>
            <div className='login-logo-div'>
              <img src={logo} alt='' />

              <p className='reset-password-text'>
                Enter the email address associated with your account and we'll
                send you a link to reset you password
              </p>
            </div>
            <div className='reset-password-form'>
              <Form onFinish={(values) => onFinish(values)}>
                <Form.Item
                  autoComplete='off'
                  className='login-input-label'
                  name='email'
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your username!',
                    },
                  ]}
                  validateStatus={error ? 'error' : ''}
                  help={error}
                >
                  <Input
                    className='login-input-field'
                    placeholder='Enter your email'
                  />
                </Form.Item>
                <div className='login-button-block'>
                  <Form.Item>
                    <MyButton
                      htmlType='submit'
                      className='login-button'
                      text='Reset my password'
                      loading={loading}
                    />
                  </Form.Item>
                </div>
              </Form>
              <div className='login-divider'>
                <Divider />
              </div>
              <div className='bottom-block '>
                <Link className='login-link-gray' to='/login'>
                  Return to login page
                </Link>
              </div>
            </div>
          </>
        )}
      </Col>
    </div>
  );
};

export default RequestResetPassword;
