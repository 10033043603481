import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";

import { SocketProvider } from 'contexts/SocketContext';
import App from "./App";

import './index.css'

const main = (
  <BrowserRouter>
    <SocketProvider>
      <App/>
    </SocketProvider>
  </BrowserRouter>
)

ReactDOM.render(main, document.getElementById('root'));
