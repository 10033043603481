import React, {useState, useEffect} from 'react';
import SelectFieldControl from '../../controls/SelectFieldControl/SelectFieldControl';
import {Input, Switch, InputNumber, Button, Typography, Select} from 'antd';
import {forceDigitFields} from '../../../../services/ProductHelperService';
import {ReactComponent as PlusIcon} from "../../../../../svg/graphicEditor/plus-big-black.svg";
import {ReactComponent as MinusIcon} from "../../../../../svg/graphicEditor/minus-big-black.svg";

import styles from './TextData.module.css';
import globalStyles from '../../GlobalGraphicEditor.module.css';

const { Text } = Typography;
const { Option } = Select;

const formatNumberOptions = [
    'None', '1 000 000.00', '1 000 000,00', '1,000,000.00', '1.000.000,00',
    '1 000 000', '1,000,000', '1.000.000'
];

export default (props) => {
    const text = props.group.findOne('Text');

    const [textType, setTextType] = useState();
    const [inputMaxLinesValue, setInputMaxLines] = useState();
    const [cutText, setCutText] = useState();
    const [regExpFrom, setRegExpFrom] = useState();
    const [regExpTo, setRegExpTo] = useState();
    const [minFontSize, setMinFontSize] = useState();
    const [maxFontSize, setMaxFontSize] = useState();
    const [inputPlaceholderValue, setInputPlaceholder] = useState();
    const [inputDynamicFieldValue, setInputDynamicFieldValue] = useState();

    const [isRegexEnabled, setRegexEnabled] = useState();
    const [isPlaceholderEnabled, setPlaceholderEnabled] = useState();
    const [isForceDoubleDigitEnabled, setForceDoubleDigitEnabled] = useState();
    const [isForceDoubleDigitVisible, setForceDoubleDigitVisible] = useState();

    const [numberFormat, setNumberFormat] = useState(formatNumberOptions[0]);
    const [isNumberFormatEnabled, setNumberFormatEnabled] = useState();
    const [prefixValue, setPrefix] = useState();
    const [postfixValue, setPostfix] = useState();
    const [isPrefixEnabled, setPrefixEnabled] = useState();
    const [isPostfixEnabled, setPostfixEnabled] = useState();

    useEffect(() => {
        setTextType(text.getAttr('dynamicField') ? 'dynamic' : 'static')
        setInputMaxLines(text.getAttr('maxLines'));
        setCutText(text.getAttr('cutText'));
        setRegExpFrom(text.getAttr('regexpMatch') || '');
        setRegExpTo(text.getAttr('regexpReplace') || '');
        setForceDoubleDigitEnabled(text.getAttr('forceDoubleDigit'));
        setMinFontSize(text.getAttr('minFontSize'));
        setMaxFontSize(text.getAttr('maxFontSize'));
        setInputPlaceholder(text.getAttr('placeholder'));
        setInputDynamicFieldValue(text.getAttr('dynamicField') || "");
        setRegexEnabled((text.getAttr('regexpMatch') || '') || (text.getAttr('regexpReplace') || ''));
        setPlaceholderEnabled(!!text.getAttr('placeholder'));
        setNumberFormatEnabled(!!text.getAttr('numberFormat'));
        setPrefix(text.getAttr('prefix'));
        setPrefixEnabled(!!text.getAttr('prefix'));
        setPostfix(text.getAttr('postfix'));
        setPostfixEnabled(!!text.getAttr('postfix'));
    }, [text, props.refreshToggle]);

    const setPropertyValue = (setter, value) => {
        setter(value);
        props.scaleText(text);
        propertyChanged();
    };

    useEffect(() => {
        const field = inputDynamicFieldValue;
        const useForceDoubleDigit = field && field.startsWith('__') && (field.indexOf('_numeric') >= 0 || !!forceDigitFields.find(t => t === field));
        setForceDoubleDigitVisible(useForceDoubleDigit);
        if (useForceDoubleDigit === false) {
            text.setAttr('_lastForceDoubleDigit', isForceDoubleDigitEnabled);
            changeForceDoubleDigitEnabled(false);
        }
        if (useForceDoubleDigit && isForceDoubleDigitVisible === false) {
            const state = text.getAttr('_lastForceDoubleDigit');
            changeForceDoubleDigitEnabled(!!state);
        }
    }, [inputDynamicFieldValue]);

    const changeNumberFormat = value => {
        text.setAttr('numberFormat', value);
        setPropertyValue(setNumberFormat, value);
    }

    const changePlaceholderText = (value) => {
        text.setAttr('placeholder', value);
        if (!text.getAttr('dynamicField')) {
            text.text(value);
            text.setAttr('fullText', value);
        } else {
            const fieldName = inputDynamicFieldValue;
            const textString = fieldName && props.product[fieldName] ? props.product[fieldName] : value;
            text.text(textString);
            text.setAttr('fullText', textString);
        }
        setPropertyValue(setInputPlaceholder, value);
    };

    const changePrefixText = (value) => {
        text.setAttr('prefix', value);
        setPropertyValue(setPrefix, value);
    }

    const changePostfixText = (value) => {
        text.setAttr('postfix', value);
        setPropertyValue(setPostfix, value);
    };

    const changeDynamicField = (fieldName) => {
        text.setAttr('dynamicField', fieldName || undefined);
        const textString = fieldName && props.product[fieldName] ? props.product[fieldName] : text.getAttr('placeholder');
        text.text(textString);
        text.setAttr('fullText', textString);
        setPropertyValue(setInputDynamicFieldValue, fieldName);
    };

    const changeMaxLines = (value) => {
        if (parseInt(value) > 0) {
            text.setAttr('maxLines', value)
            props.scaleText(text)
        } else {
            value = '0';
        }
        setInputMaxLines(value);
        propertyChanged();
    };

    const changeMinFont = (value) => {
        if (parseInt(value) > 0) {
            text.setAttr('minFontSize', value);
            props.scaleText(text);
        } else {
            value = '0';
        }
        setMinFontSize(value);
        propertyChanged();
    };

    const changeMaxFont = (value) => {
        if (parseInt(value) > 0) {
            text.setAttr('maxFontSize', value)
            props.scaleText(text)
        } else {
            value = '0';
        }
        setMaxFontSize(value);
        propertyChanged();
    };

    const changeCutText = (value) => {
        text.setAttr('cutText', value);
        setPropertyValue(setCutText, value);
    };

    const changeRegExpFrom = (value) => {
        text.setAttr('regexpMatch', value);
        setPropertyValue(setRegExpFrom, value);
    };

    const changeRegExpTo = (value) => {
        text.setAttr('regexpReplace', value);
        setPropertyValue(setRegExpTo, value);
    };

    const changeRegexEnable = () => {
        setRegexEnabled(!isRegexEnabled);
        updateRegexState(!isRegexEnabled);
    };

    const changeForceDoubleDigitEnabled = (value) => {
        text.setAttr('forceDoubleDigit', value)
        setPropertyValue(setForceDoubleDigitEnabled, value);
    };

    const updateRegexState = (isEnabled) => {
        if (!isEnabled) {
            text.setAttr('_lastStateRegex', {regExpFrom, regExpTo});
            changeRegExpFrom('');
            changeRegExpTo('');
        } else {
            const state = text.getAttr('_lastStateRegex');
            if (state) {
                changeRegExpFrom(state.regExpFrom);
                changeRegExpTo(state.regExpTo);
            }
        }
    };

    const changePlaceholderEnable = () => {
        setPlaceholderEnabled(!isPlaceholderEnabled);
        updatePlaceholderState(!isPlaceholderEnabled);
    };

    const updatePlaceholderState = (isEnabled) => {
        if (!isEnabled) {
            text.setAttr('_lastStatePlaceholder', {inputPlaceholderValue});
            changePlaceholderText('');
        } else {
            const state = text.getAttr('_lastStatePlaceholder');
            if (state) {
                changePlaceholderText(state.inputPlaceholderValue);
            }
        }
    };

    const changeNumberFormatEnable = () => {
        setNumberFormatEnabled(!isNumberFormatEnabled);
        updateNumberFormatState(!isNumberFormatEnabled);
    };

    const updateNumberFormatState = (isEnabled) => {
        if (!isEnabled) {
            text.setAttr('_lastStateNumberFormat', {numberFormat});
            changeNumberFormat('None');
        } else {
            const state = text.getAttr('_lastStateNumberFormat');
            if (state) {
                changeNumberFormat(state.numberFormat);
            }
        }
    };

    const changePrefixEnable = () => {
        setPrefixEnabled(!isPrefixEnabled);
        updatePrefixState(!isPrefixEnabled);
    };

    const updatePrefixState = (isEnabled) => {
        if (!isEnabled) {
            text.setAttr('_lastStatePrefix', {prefixValue});
            changePrefixText('');
        } else {
            const state = text.getAttr('_lastStatePrefix');
            if (state) {
                changePrefixText(state.prefixValue);
            }
        }
    };

    const changePostfixEnable = () => {
        setPostfixEnabled(!isPostfixEnabled);
        updatePostfixState(!isPostfixEnabled);
    };

    const updatePostfixState = (isEnabled) => {
        if (!isEnabled) {
            text.setAttr('_lastStatePostfix', {postfixValue});
            changePostfixText('');
        } else {
            const state = text.getAttr('_lastStatePostfix');
            if (state) {
                changePostfixText(state.postfixValue);
            }
        }
    };

    const propertyChanged = () => {
        if (props.onPropertyChanged) {
            props.onPropertyChanged();
        }
    };

    const sizesBlocks = [
        {
            title: 'Max size:',
            value: maxFontSize,
            min: minFontSize,
            onChange: changeMaxFont,
        },
        {
            title: 'Min size:',
            value: minFontSize,
            min: 1,
            onChange: changeMinFont,
        },
        {
            title: 'Max lines:',
            value: inputMaxLinesValue,
            min: 1,
            onChange: changeMaxLines,
        }
    ];

    const product = props.product ?? {};
    const fields = props.productFields ?? {};

    return (
        <div>
            {textType === 'static' && <div>
                <Input.TextArea
                    type="text"
                    onChange={(e) => changePlaceholderText(e.target.value)}
                    value={inputPlaceholderValue}
                    className={styles.textBlock}
                />
            </div>}

            {textType === 'dynamic' &&
                <div className={`${styles.blocksWrapper} ${styles.topBlocksWrapper}`}>
                    <SelectFieldControl
                        value={inputDynamicFieldValue}
                        onChange={changeDynamicField}
                        product={product}
                        fields={fields}
                    />
                    <div>
                        <div className={styles.spaceBetweenBlock}>
                            <Text className={styles.toggleTitle}>Number Format</Text>
                            <Button
                                icon={isNumberFormatEnabled ? <MinusIcon/> : <PlusIcon/>}
                                shape='circle'
                                type='text'
                                onClick={changeNumberFormatEnable}
                            />
                        </div>
                        {isNumberFormatEnabled &&
                            <Select value={numberFormat} style={{ width: '100%' }} onChange={changeNumberFormat}>
                                {formatNumberOptions.map(option => (
                                    <Option key={option} value={option}>{option}</Option>
                                ))}
                            </Select>}

                    </div>

                    <div>
                        <div className={styles.spaceBetweenBlock}>
                            <Text className={styles.toggleTitle}>Prefix</Text>
                            <Button
                                icon={isPrefixEnabled ? <MinusIcon/> : <PlusIcon/>}
                                shape='circle'
                                type='text'
                                onClick={changePrefixEnable}
                            />
                        </div>
                        {isPrefixEnabled &&
                            <Input
                                placeholder={'Prefix'}
                                onChange={(e) => changePrefixText(e.target.value)}
                                value={prefixValue}
                                style={{marginTop: '5px'}}
                                className={`${globalStyles.input} ${styles.input}`}
                            />}

                    </div>
                    <div>
                        <div className={styles.spaceBetweenBlock}>
                            <Text className={styles.toggleTitle}>Postfix</Text>
                            <Button
                                icon={isPostfixEnabled ? <MinusIcon/> : <PlusIcon/>}
                                shape='circle'
                                type='text'
                                onClick={changePostfixEnable}
                            />
                        </div>
                        {isPostfixEnabled &&
                            <Input
                                placeholder={'Postfix'}
                                onChange={(e) => changePostfixText(e.target.value)}
                                value={postfixValue}
                                style={{marginTop: '5px'}}
                                className={`${globalStyles.input} ${styles.input}`}

                            />
                        }
                    </div>
                    <div className={styles.blocksWrapper}>
                        <div>
                            <Text className={styles.blockTitle}>Autosize text to fit in the box</Text>
                            <div className={styles.maxSizesBlock}>
                                {sizesBlocks.map((block) => (
                                    <div key={block.title} className={styles.spaceBetweenBlock}>
                                        <Text className={globalStyles.text}>{block.title}</Text>
                                        <InputNumber
                                            size='small'
                                            min={block.min}
                                            value={block.value}
                                            onChange={block.onChange}
                                            className={`${globalStyles.input} ${styles.inputSmall}`}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className={styles.spaceBetweenBlock}>
                                <Text>Cut text if unable to fit</Text>
                                <Switch
                                    checked={cutText}
                                    onChange={changeCutText}
                                />
                            </div>
                            <div  className={styles.description}>
                                If auto-sizing wasnt possible using the provided settings we will cut the text to
                                finally fit in the box.
                                i.e. “very long text we were not able to...”
                            </div>
                        </div>
                    </div>

                    {isForceDoubleDigitVisible &&
                        <div>
                            <div className={styles.spaceBetweenBlock}>
                                <Text>Force double digit</Text>
                                <Switch
                                    checked={isForceDoubleDigitEnabled}
                                    onChange={changeForceDoubleDigitEnabled}
                                />
                            </div>
                        </div>
                    }
                    <div>
                        <div className={styles.spaceBetweenBlock}>
                            <Text className={styles.toggleTitle}>Apply regex replace</Text>
                            <Button
                                icon={isRegexEnabled ? <MinusIcon/> : <PlusIcon/>}
                                shape='circle'
                                type='text'
                                onClick={changeRegexEnable}
                            />
                        </div>
                        {isRegexEnabled && <div>
                            <div style={{marginTop: '5px', flex: 1}}>
                                <Input
                                    size="small"
                                    value={regExpFrom}
                                    onChange={(e) => changeRegExpFrom(e.target.value)}
                                    placeholder='From'
                                    className={`${globalStyles.input} ${styles.input}`}
                                />
                            </div>
                            <div style={{marginTop: '5px', flex: 1}}>
                                <Input
                                    size="small"
                                    value={regExpTo}
                                    onChange={(e) => changeRegExpTo(e.target.value)}
                                    placeholder='To'
                                    className={`${globalStyles.input} ${styles.input}`}
                                />
                            </div>
                        </div>}
                    </div>

                    <div>
                        <div className={isPlaceholderEnabled ? styles.placeholderBlock : ''}>
                            <div className={styles.spaceBetweenBlock}>
                                <Text className={styles.toggleTitle}>Add placeholder</Text>
                                <Button
                                    icon={isPlaceholderEnabled ? <MinusIcon/> : <PlusIcon/>}
                                    shape='circle'
                                    type='text'
                                    onClick={changePlaceholderEnable}
                                />
                            </div>
                            <div className={styles.description}>
                                Applies default value in case field is empty
                            </div>
                        </div>

                        {isPlaceholderEnabled &&
                            <Input
                                placeholder={'Your text here...'}
                                onChange={(e) => changePlaceholderText(e.target.value)}
                                value={inputPlaceholderValue}
                                className={`${globalStyles.input} ${styles.input}`}
                            />
                        }
                    </div>
                </div>
            }
        </div>
    );
};

