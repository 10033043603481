import React, { useRef, useEffect } from 'react';
import { Input } from 'antd';

import '../ControlStyles.css';
import styles from './InputControl.module.css';

export default (props) => {
  const inputField = useRef(null);
  const value = props.value;
  const checked = props.checked;

  useEffect(() => {
    if (props.type === 'checkbox') {
      inputField.current.checked = checked ?? false;
    } else {
      inputField.current.value = value ?? '';
    }
  }, [value, checked]);

  return (
    <div className={`${props.row ? styles.containerRow : styles.container}`}>
      {props.label && (
        <div style={{ width: props.width }} className={styles.label}>
          {props.label}
        </div>
      )}

      <div className={styles.selectDiv}>
        <Input
          className={`${props.hasErrors ? 'error-input' : ''} ${styles.input} ${props.customStyles || ''}`}
          readOnly={props.readOnly}
          type={props.type}
          ref={inputField}
          disabled={props.disabled}
          onChange={props.onChange}
          placeholder={props.placeholder || ''}
          value={props.value}
        />
      </div>
    </div>
  );
};
