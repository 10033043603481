import {useEffect, useRef, useState} from "react";
import _ from "lodash";

export const useDesignHistory = () => {

    const historyRef = useRef({items: [], index: -1});
    const [historyIndex, setHistoryIndex] = useState();

    useEffect(() => {
        setHistoryIndex(historyRef.current.index);
    }, [historyRef.current.index]);


    const saveHistory = (stage) => {
        if (!stage) return;

        const history = historyRef.current;
        if (!isStageChanged(stage, history)) return;

        const length = history.items.length;
        const index = history.index;

        if (index + 1 < length) {
            history.items.splice(index + 1, length - (index + 1));
        }

        let cloneObj = _.cloneDeep(stage);
        history.items.push({pathname: cloneObj, search: window.location.search});

        history.index = history.items.length - 1;
        setHistoryIndex(history.index);
    }

    const historyUndo = (stage, deleteElement, afterAction) => {
        const history = historyRef.current;
        if (history.index === 0) return;

        changeHistoryStageData(history.index - 1, stage, history, deleteElement, afterAction);
    }

    const historyRedo = (stage, deleteElement, afterAction) => {
        const history = historyRef.current;
        if (history.index === history.items.length - 1) return;

        changeHistoryStageData(history.index + 1, stage, history, deleteElement, afterAction);
    }

    const changeHistoryStageData = (newIndex, currentStage, history, deleteElement, afterAction) => {
        if (newIndex < 0) return
        const historyStage = history.items[newIndex];
        const historyLayer = historyStage.pathname.findOne('#canvas')
        const layer = currentStage.findOne('#canvas');
        const groups = historyStage.pathname.find('.elementGroup');

        groups.forEach((group) => {
            const image = group.findOne('Image');
            if (image) {
                const imageObj = new window.Image();
                imageObj.src = image.attrs.url;
                image.image(imageObj);
            }
        });

        currentStage.find('.elementGroup').forEach((element) => {
            const historyElement = groups.find(item => item._id === element._id);
            const stringCurrentElement = JSON.stringify(element);
            const stringHistoryElement = JSON.stringify(historyElement);

            if (historyElement) {
                copyElementAttributesWithSameStructure(element, historyElement);
                if (!_.isEqual(stringCurrentElement, stringHistoryElement)) {
                    deleteElement(element, true);
                }
            } else {
                deleteElement(element, true);
            }
        });

        historyStage.pathname.find('.elementGroup').forEach((element) => {
            const existElement = currentStage.find('.elementGroup').find(existItem => existItem._id === element._id);
            if (!existElement) {
                const clone = cloneElement(element);
                layer.add(clone);
            }
        });

        const groupElementsFromLayer = layer.children.filter(childElement => childElement.hasName('elementGroup'));
        groupElementsFromLayer.forEach((element) => {
            const historyElement = historyLayer.children.find(item => item._id === element._id);
            if (historyElement) {
                element.setZIndex(historyElement.index);
            }
        })

        afterAction(currentStage, layer);
        historyRef.current.index = newIndex;
        setHistoryIndex(newIndex);
    }

    return [saveHistory, historyRef.current, historyUndo, historyRedo, historyIndex]
}

const isStageChanged = (stage, history) => {
    const prevStage = history.items[history.index];
    const stringStage = JSON.stringify(stage);
    const stringPrevStage = JSON.stringify(prevStage?.pathname);
    const areStagesEqual = _.isEqual(stringStage, stringPrevStage);

    return !prevStage || !areStagesEqual;
}

const findChildElement = (source, id) => {
    if (source.children) {
        return source.children.find(childItem => childItem._id === id);
    }
}

const findChildElementByType = (source, type) => {
    if (source.hasName('elementGroup') && source.attrs.elementType === type) {
        return source.children.find(childItem => childItem.attrs.elementType === type && childItem.hasName('main'));
    }
}

const copyElementAttributesWithSameStructure = (target, source) => {
    if (!target || !source) return;

    target.setZIndex(source.index);
    for (let name in source.attrs) {
        if (name === 'image' || name === 'src') {
            target.attrs[name] = source.attrs[name];
        }
        target.setAttr(name, source.attrs[name])
    }

    for (let name in target.attrs) {
        if (source.attrs[name] === undefined) {
            target.setAttr(name, undefined);
            delete target.attrs[name];
        }
    }

    if (target.text) {
        target.text(source.text());
        target.setAttr('fullText', source.getAttr('fullText'));
    }

    if (target.children) {
        target.children.forEach(targetChildren => {
            const sourceChildren = findChildElement(source, targetChildren._id) || findChildElementByType(source, 'image');
            if (sourceChildren) {
                copyElementAttributesWithSameStructure(targetChildren, sourceChildren);
            }
        })
    }
}

const cloneElement = (element) => {
    const updateElementIds = (newElement, element) => {
        newElement._id = element._id;
        if (newElement.children) {
            for (let i = 0; i < newElement.children.length; i++) {
                updateElementIds(newElement.children[i], element.children[i])
            }
        }
    }
    const newElement = element.clone();
    updateElementIds(newElement, element);
    return newElement;
}