import React from 'react';

import styles from './PropertyTab.module.css'

export default ({activeTab, setActiveTab}) => {
    return (
        <div className={styles.tabContainer}>
            <div
                onClick={() => setActiveTab(1)}
                className={`${styles.tab} ${activeTab === 1 ?  styles.selectedTab : ''}`}
            >
                Data and appearance
            </div>
            <div
                onClick={() => setActiveTab(3)}
                className={`${styles.tab} ${activeTab === 3 ?  styles.selectedTab : ''}`}
            >
                Condition
            </div>
        </div>
    );
};
