import React from 'react';
import {Table} from "antd";
import './customTable.css';
const CustomTable = ({columns, data, rowSelection}) => {
    return (
        <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            pagination={false}
            className='custom-table'
            bordered
            size='small'
            tableLayout='auto'
        />
    );
};

export default CustomTable;