import React, { useEffect, useState } from 'react';
import { InputNumber } from 'antd';

import {
  getAbsoluteElementPosition,
  getParentPosition,
  refreshParentGroup,
} from '../../../services/PropertiesService';

import styles from './PositionSizeControl.module.css';
import globalStyles from '../../GlobalGraphicEditor.module.css';

export default function (props) {
  const [positionX, setPositionX] = useState();
  const [positionY, setPositionY] = useState();
  const [radius, setRadius] = useState();

  const [elementWidth, setElementWidth] = useState();
  const [elementHeight, setElementHeight] = useState();

  const { group } = props;
  const { element } = props;
  const { layoutPositioning } = props;

  useEffect(() => {
    const stage = props.group.getStage();
    const canvasPosition = stage.findOne('#canvas_bg').position();
    const position = getAbsoluteElementPosition(group, canvasPosition);

    const radiusName = props.radiusName ?? 'radius';

    const scaleX = group.getAttr('scaleX');
    const scaleY = group.getAttr('scaleY');
    const radius = element.getAttr(radiusName);

    setRadius(radius);
    setPositionX(position.x);
    setPositionY(position.y);

    setElementWidth(getSizeByScale(radius, scaleX));
    setElementHeight(getSizeByScale(radius, scaleY));
  }, [element, group, props.refreshToggle]);

  const changePositionX = (value) => {
    const val = parseInt(value);
    if (isNaN(val)) return;

    const parentPosition = getParentPosition(group);
    group.x(
      val + group.getStage().findOne('#canvas_bg').x() - parentPosition.x,
    );

    refreshParentGroup(group);
    setPositionX(val);
    propertyChanged();
  };

  const getSizeByScale = (radius, scale) => {
    return Math.round(radius * 2 * scale);
  };

  const getScaleBySize = (radius, size) => {
    return size / (radius ?? 1) / 2;
  };

  const changePositionY = (value) => {
    const val = parseInt(value);
    if (isNaN(val)) return;

    const parentPosition = getParentPosition(group);
    group.y(
      val + group.getStage().findOne('#canvas_bg').y() - parentPosition.y,
    );

    refreshParentGroup(group);
    setPositionY(val);
    propertyChanged();
  };

  const changeWidth = (value) => {
    const val = parseInt(value);
    if (isNaN(val) || val <= 0) {
      return;
    }

    const scaleX = getScaleBySize(radius, val);
    group.setAttr('scaleX', scaleX);

    setElementWidth(val);
    propertyChanged();
  };

  const changeHeight = (value) => {
    const val = parseInt(value);
    if (isNaN(val) || val <= 0) {
      return;
    }

    const scaleY = getScaleBySize(radius, val);
    group.setAttr('scaleY', scaleY);

    setElementHeight(val);
    propertyChanged();
  };

  const propertyChanged = () => {
    if (props.onPropertyChanged) {
      props.onPropertyChanged();
    }
  };

  return (
    <div className={styles.block}>
      <div className={styles.dataBlock}>
        <div className={styles.inputsBlock}>
          <div className={styles.label}>X</div>
          <InputNumber
            className={`${styles.input} ${globalStyles.input}`}
            size='small'
            disabled={layoutPositioning !== 'absolute'}
            value={positionX}
            onChange={changePositionX}
          />
        </div>
        <div className={styles.inputsBlock}>
          <div className={styles.label}>Y</div>
          <InputNumber
            className={`${styles.input} ${globalStyles.input}`}
            size='small'
            disabled={layoutPositioning !== 'absolute'}
            value={positionY}
            onChange={changePositionY}
          />
        </div>
      </div>
      <div className={styles.dataBlock}>
        <div className={styles.inputsBlock}>
          <div className={styles.label}>W</div>
          <InputNumber
            className={`${styles.input} ${globalStyles.input}`}
            size='small'
            value={elementWidth}
            onChange={changeWidth}
          />
        </div>
        <div className={styles.inputsBlock}>
          <div className={styles.label}>H</div>
          <InputNumber
            className={`${styles.input} ${globalStyles.input}`}
            size='small'
            value={elementHeight}
            onChange={changeHeight}
          />
        </div>
      </div>
    </div>
  );
}
