import React, { useState } from 'react';
import { Button, Spin } from 'antd';
import CustomPopConfirm from '../../../shared/CustomPopConfirm/CustomPopConfirm';
import { paymentBrands, paymentTypes } from '../../../../constants';
import { ReactComponent as DeleteIcon } from 'svg/icon/trash-small.svg';
import { ReactComponent as ViseIcon } from 'svg/icon/visa.svg';
import { ReactComponent as MastercardIcon } from 'svg/icon/mastercard.svg';
import { ReactComponent as LinkIcon } from 'svg/icon/payment-link.svg'

import styles from './PaymentMethodCard.module.css';

const PaymentMethodCard = ({payment, organization, onSelect, onDelete}) => {
    const [loading, setLoading] = useState(false);
    const {CARD, LINK} = paymentTypes;
    const {VISA, MASTERCARD} = paymentBrands;

    const onDeletePaymentMethodClick = async (event) => {
        event.stopPropagation();
        await onDelete(organization.id, payment.id)
    };

    const renderIcons = () => {
      if (payment.type === CARD) {
        if (payment.brand === VISA) {
          return <ViseIcon/>;
        }
        if (payment.brand === MASTERCARD) {
          return <MastercardIcon/>;
        }
      }
      if (payment.type === LINK) {
        return <LinkIcon/>;
      }
    };

    const renderTitle = () => {
      if (payment.type === CARD) {
       return  (
        <>
          <span>{payment.brand} </span> ending in {' '}
          {payment.last4 || ''}
        </>
       )}
      if (payment.type === 'link') {
      return <span>Link</span>
      }
    };

    return (
        <div
            className={`${styles.card} ${payment.default ? styles.cardActive : ''}`}
            onClick={() => onSelect(payment, setLoading)}
        >
            {loading ?
                <div className={styles.loading}>
                    <Spin/>
                </div> :
                <>
                    <div className={styles.leftBlock}>
                        <div
                            className={`${styles.paymentInfoWrapper} ${payment.default ? styles.paymentInfoWrapperActive : ''}`}>
                            <div>
                                <h5 className={styles.title}>
                                  {renderTitle()}
                                </h5>
                              {payment.expiry_date && payment.type === CARD &&
                                <p className={styles.subtitle}>
                                Expiry: {payment.expiry_date}
                                </p>}
                            </div>
                        </div>
                        <div className={styles.buttonsWrapper}>
                            <CustomPopConfirm
                                title='Confrim deleting payment method'
                                okText='Delete'
                                onConfirm={(event) => onDeletePaymentMethodClick(event)}
                                placement='topLeft'
                            >
                                <Button
                                    type='text'
                                    onClick={(event) => event.stopPropagation()}
                                    className={styles.button}
                                    disabled={payment.default}
                                >
                                    Delete <DeleteIcon/>
                                </Button>
                            </CustomPopConfirm>
                        </div>
                    </div>
                    <div className={styles.paymentImg}>
                        {renderIcons()}
                    </div>
                </>}
        </div>
    );
};

export default PaymentMethodCard;
