import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Tooltip } from "antd";
import Konva from "konva";
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import CustomPopConfirm from '../../shared/CustomPopConfirm/CustomPopConfirm';
import { copyDesignTemplate } from '../copyDesignService/copyDesignService';
import { scaleImage } from "../../graphicEditor/services/ScaleService";
import { isOutputsReadyToRun, isProjectRunning } from '../../Project/StatusBlock/StatusBlock';
import { useWebSocket } from '../../../contexts/SocketContext';
import { ReactComponent as CrossIcon } from '../../../svg/editor/close.svg';
import { ReactComponent as PlusIcon } from '../../../svg/editor/controls/zoom-in.svg';
import { ReactComponent as CopyIcon } from '../../../svg/sidebar/copy.svg';
import { ReactComponent as DeleteIcon } from '../../../svg/sidebar/trash.svg';

import styles from './TemplatesSideBar.module.css';

const loadStages = async (stage_json, templateSize, id) => {
    const stage = await Konva.Node.create(stage_json, id);
    stage.size({
        width: templateSize.width,
        height: templateSize.height
    });
    stage.position({
        x: 0,
        y: 0
    });
    stage.scale({
        x: 0.35,
        y: 0.35,
    });
    stage.draggable(false);

    const canvasLayer = stage.findOne('#canvas');
    const canvasBg = stage.findOne('#canvas_bg');
    const base = canvasBg.position()
    canvasLayer.move({
        x: -base.x - (stage.attrs.x ?? 0),
        y: -base.y - (stage.attrs.y ?? 0)
    })

    for (let image of stage.find('Image')) {
        const group = image.parent
        const rect = group.findOne('.background')
        const imgAttrs = image.getAttrs()
        const imageObj = new window.Image();
        imageObj.onload = function () {
            const oldImg = imgAttrs.image
            delete imgAttrs.image
            delete imgAttrs.width
            delete imgAttrs.height
            const imgNode = new Konva.Image(
                {
                    ...imgAttrs,
                    image: imageObj,
                    initWidth: imageObj.width,
                    initHeight: imageObj.height,
                }
            );
            rect.fillPatternImage(imageObj);
            group.add(imgNode);
            if (oldImg) oldImg.remove()
            image.destroy();
            scaleImage(imgNode)
        };
        imageObj.src = imgAttrs.url;
    }
    for (let group of stage.find('.elementGroup')) {
        group.draggable(false);
    }

};

export default (props) => {
    const history = useHistory();
    const { data: socketData } = useWebSocket();

    const isRunning = isProjectRunning(socketData[props.projectId]?.status);
    const isOutputRunning = isOutputsReadyToRun(socketData[props.projectId]?.outputs);
    const isButtonShouldDisable =  !props.organization.generation_allowed || isRunning || isOutputRunning;

    useEffect(() => {
        if (props.designTemplates) {
            props.designTemplates.forEach((template, index) => {
                loadStages(
                    template.design_json,
                    {width: 208, height: 208},
                    `konva_container_${index}`,
                )
            })
        }
    }, [props.designTemplates]);

    const confirmDeleteTemplate = async (e, template) => {
        e.stopPropagation();

        await props.deleteTemplate(template.id);
        await props.loadProject(props.projectId);
        props.loadTemplates();
    };

    const gotoTemplate = (template) => {
        if (isButtonShouldDisable) return;

        if (props.save_func) {
            props.save_func();
        }
        history.push( `/project/${props.projectId}/design/${template.id}`);
    };

    const transformDate = (date) => {
        const isoDate = new Date(date);
        return isoDate.toLocaleDateString();
    };

    const onCopyDesignTemplate = async(event, template) => {
      event.stopPropagation();
      await copyDesignTemplate(template, props.projectId, props.loadProject, props.loadTemplates)
    };

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.headerText}>Graphic Templates</div>
                <Button className={styles.headerCloseButton} onClick={props.onClose}><CrossIcon  /></Button>
            </div>
            <Button
                className={styles.addNewTemplateButton}
                onClick={() => history.push(`/project/${props.projectId}/design/new`)}
                disabled={isButtonShouldDisable}
            >
                <PlusIcon />
                <span>Add new template</span>
            </Button>
            {props.designTemplates && props.designTemplates.map((template, index) => (
                <div
                    key={template.id}
                    className={`${styles.templateWrapper} ${isButtonShouldDisable ? styles.disableCursor : ''}`}
                    onClick={() => gotoTemplate(template)}
                >
                    <div className={styles.template}>
                        <div
                            className={styles.templateImage}
                            id={`konva_container_${index}`}
                        />
                        <Tooltip
                            placement="right"
                            title={!props.organization?.generation_allowed ? 'No permission to open the template'  : 'Click to open in Graphic Editor'}
                        >
                            <div className={styles.templateTooltip}>
                            </div>
                        </Tooltip>
                        <div className={styles.templateControls} onClick={e => e.stopPropagation()}>
                            <div className={styles.templateName}>{template.name}</div>
                            <Dropdown
                                menu={
                                    {
                                        items: [
                                            {
                                                key: 'delete',
                                                label: (
                                                    <CustomPopConfirm
                                                        title={`Delete template '${template.name}'?`}
                                                        onConfirm={(e) => confirmDeleteTemplate(e, template)}
                                                        okText="Yes"
                                                        cancelText="No"
                                                        placement='bottom'
                                                    >
                                                        <Tooltip placement="right" title='Delete template'>
                                                            <Button
                                                              onClick={e => e.stopPropagation()}
                                                              className={styles.controlsButton}
                                                              disabled={isButtonShouldDisable}
                                                            >
                                                                <span>Delete</span><DeleteIcon />
                                                            </Button>
                                                        </Tooltip>
                                                    </CustomPopConfirm>
                                                ),
                                            },
                                            {
                                                key: 'copy',
                                                label: (
                                                    <Tooltip placement="right" title='Copy templete'>
                                                        <Button
                                                            className={styles.controlsButton} 
                                                            onClick={(event) => onCopyDesignTemplate(event, template)}
                                                            disabled={isButtonShouldDisable}
                                                        >
                                                            <span>Copy</span> <CopyIcon />
                                                        </Button>
                                                    </Tooltip>
                                                ),
                                            },
                                        ]
                                    }
                                }
                                placement="bottomLeft"
                                overlayClassName={styles.templateDropdown}
                            >
                                <Button className={styles.templateDropdownButton}>
                                    <MoreOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                        
                    </div>
                    <div className={styles.templateInfo}>
                        <div className={styles.templateText}><b>Size: </b>banner</div>
                        <div className={styles.templateText}><b>Last modified on: </b> {transformDate(template?.changed)}</div>
                    </div>    
                </div>
            ))}
            <div id='copy-template'/>
        </div>
    )
}
