import React from 'react';
import { Button, Typography } from 'antd';

import { ReactComponent as CloseIcon } from 'svg/icon/close-black.svg';

import styles from './PropertiesHeader.module.css';
import globalStyles from '../../GlobalGraphicEditor.module.css';

const { Text } = Typography;

export default function (props) {
  return (
    <div className={globalStyles.spaceBetweenBlock}>
      <Text className={styles.title}>{props.caption}</Text>
      <div>
        <Button
          icon={<CloseIcon />}
          shape='circle'
          type='text'
          onClick={props.onClose}
        />
      </div>
    </div>
  );
}
