import React, {useEffect, useState} from 'react';

import {ReactComponent as CopyIcon} from "svg/tooltipMenu/copy-small.svg";
import {ReactComponent as DeleteIcon} from "svg/tooltipMenu/delete-small.svg";
import {ReactComponent as GroupIcon} from "svg/tooltipMenu/grouping-small.svg";


import styles from './GraphicEditorTooltip.module.css'
import {Button} from "antd";

const GraphicEditorTooltip = ({createGroup, destroyGroup, copyElement, deleteElement, selectedGroup}) => {

	const [isGroupSelected, setIsGroupSelected] = useState();

	useEffect(() => {
		const item = selectedGroup;
		setIsGroupSelected(!(item && item.attrs.elementType === 'group' && item.hasName('elementGroup')));
	}, [selectedGroup])

	const processGroupItemClick = () => {
		if (isGroupSelected) {
			createGroup();
		} else {
			destroyGroup(selectedGroup);
		}
	};

	const onButtonClick = (type) => {
		const menuNode = document.getElementById('menu');

		if (!selectedGroup) return;

		switch (type) {
			case 'copy':
				copyElement(selectedGroup);
				break;
			case 'delete':
				deleteElement(selectedGroup);
				break;
			case 'group':
				processGroupItemClick();
				break;
			default:
				break;
		}

		menuNode.style.display = 'none';
	};

	return (
		<div id='menu' className={styles.menu}>
			<div className={styles.menuWrapper}>
				<Button
					type='text'
					icon={<GroupIcon/>}
					onClick={() => onButtonClick('group')}
					className={styles.button}
				/>
				<Button
					type='text'
					icon={<CopyIcon/>}
					onClick={() => onButtonClick('copy')}
					className={styles.button}
				/>
				<Button
					type='text'
					icon={<DeleteIcon/>}
					onClick={() => onButtonClick('delete')}
					className={styles.button}
				/>
			</div>
		</div>
	);
};

export default GraphicEditorTooltip;