import React from 'react';
import {Popconfirm} from "antd";

const CustomPopConfirm = ({title, open,onConfirm, onCancel, placement, okText, cancelText, children, arrow = false }) => {
    return (
        <Popconfirm
            title={title}
            open={open}
            onConfirm={onConfirm}
            onCancel={onCancel}
            placement={placement}
            icon={false}
            okText={okText}
            cancelText={cancelText || 'Cancel'}
            arrow={arrow}
        >
            {children}
        </Popconfirm>
    );
};

export default CustomPopConfirm;