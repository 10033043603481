import React, { useEffect, useState } from 'react';
import { Button, InputNumber, Radio, Typography } from 'antd';

import SelectControl from '../../controls/SelectControl/SelectControl';
import ColorEditor from '../../controls/ColorEditor/ColorEditor';
import BackgroundBorder from '../../controls/BackgroundBorderControl/BackgroundBorderControl';
import PositioningControl from '../../controls/PositioningControl/PositioningControl';
import PositionSizeControl from '../../controls/PositionSizeControl/PositionSizeControl';
import DataProperties from '../TextData/TextData';
import UploadFontButton from '../../../../fontsEditor/UploadFontButton/UploadFontButton';
import RotationControl from '../../controls/RotationControl/RotationControl';
import ShadowControl from '../../controls/ShadowControl/ShadowControl';
import StrokeControl from '../../controls/StrokeControl/StrokeControl';
import BorderControl from '../../controls/BorderControl/BorderControl';
import DynamicBoxControl from '../../controls/DynamicBoxControl/DynamicBoxControl';
import { ReactComponent as UpperCaseIcon } from 'svg/graphicEditor/uppercase-small.svg';
import { ReactComponent as LowerCaseIcon } from 'svg/graphicEditor/lowercase-small.svg';
import { ReactComponent as RegularCaseIcon } from 'svg/graphicEditor/regularcase-small.svg';
import { ReactComponent as FontSizeIcon } from 'svg/graphicEditor/font-size-small.svg';
import { ReactComponent as LineHeightIcon } from 'svg/graphicEditor/line-height-small.svg';
import { ReactComponent as LetterSpacingIcon } from 'svg/graphicEditor/letter-spacing-small.svg';
import { ReactComponent as UnderlineIcon } from 'svg/graphicEditor/underline-small.svg';
import { ReactComponent as StrikeIcon } from 'svg/graphicEditor/strike-small.svg';
import { ReactComponent as AlignLeftIcon } from 'svg/graphicEditor/alignment-left-small.svg';
import { ReactComponent as AlignRightIcon } from 'svg/graphicEditor/alignment-right-small.svg';
import { ReactComponent as AlignTopIcon } from 'svg/graphicEditor/alignment-top-small.svg';
import { ReactComponent as AlignBottomIcon } from 'svg/graphicEditor/alignment-bottom-small.svg';
import { ReactComponent as AlignHorizontalCenterIcon } from 'svg/graphicEditor/alignment-horizontal-center-small.svg';
import { ReactComponent as AlignVerticalCenterIcon } from 'svg/graphicEditor/alignment-vertical-center-small.svg';

import styles from './TextAppearance.module.css';
import globalStyles from '../../GlobalGraphicEditor.module.css';

const { Text } = Typography;

const emptyColor = 'rgba(0,0,0,0)';
const minLetterSpacingValue = 0.1;

export default function (props) {
  const text = props.group.findOne('Text');
  const background = props.group.findOne('.background');
  const substrate = props.group.findOne('.substrate');

  const [layoutPositioning, setLayoutPositioning] = useState();
  const [textType, setTextType] = useState(null);
  const [fontFamily, setFontFamily] = useState();
  const [fontSize, setFontSize] = useState();
  const [lineHeight, setLineHeight] = useState();
  const [letterSpacing, setLetterSpacing] = useState();
  const [alignV, setAlignV] = useState();
  const [alignH, setAlignH] = useState();
  const [textFormat, setTextFormat] = useState();
  const [textColor, setTextColor] = useState();
  const [textDecoration, setTextDecoration] = useState();
  const [positionAlignText, setPositionAlignText] = useState(null);

  const [isTextDecorationUnderline, setTextDecorationUnderline] =
    useState(false);
  const [isTextDecorationLineThrough, setTextDecorationLineThrough] =
    useState(false);

  const [isTextBoxBgEnabled, setTextBoxBgEnabled] = useState();
  const [refreshSizeToggle, setRefreshSizeToggle] = useState();

  useEffect(() => {
    updateRefreshSizeToggle();
  }, [props.refreshToggle]);

  // Если ранее значение было 0, ставим 0.1
  const fixTextAttributes = (text) => {
    if (!text) {
      return;
    }

    const letterSpacingValue = text.getAttr('letterSpacing');
    if (!letterSpacingValue || letterSpacingValue <= 0) {
      text.setAttr('letterSpacing', minLetterSpacingValue);
    }
  };

  useEffect(() => {
    fixTextAttributes(text);

    const textDecoration = text.getAttr('textDecoration') || 'none';
    const letterSpacingValue = text.getAttr('letterSpacing');
    setTextType(text.getAttr('dynamicField') ? 'dynamic' : 'static');
    setFontFamily(
      props.fonts.find((f) => f.family === text.getAttr('fontFamily'))?.id,
    );
    setFontSize(text.getAttr('fontSize'));
    setLineHeight(text.getAttr('lineHeight'));
    // Для НУЛЯ. В свойствах обекта для нуля храним 0.1, в свойствах (отобажение на форме) - 0.
    setLetterSpacing(
      letterSpacingValue && letterSpacingValue > minLetterSpacingValue
        ? letterSpacingValue
        : 0,
    );
    setAlignH(text.getAttr('align'));
    setAlignV(text.getAttr('verticalAlign'));
    setTextFormat(text.getAttr('textFormat'));
    setTextDecoration(textDecoration);
    setTextColor(text.getAttr('fill'));
    setPositionAlignText('To the right');

    setTextDecorationUnderline(textDecoration.indexOf('underline') > -1);
    setTextDecorationLineThrough(textDecoration.indexOf('line-through') > -1);
  }, [text, background, substrate, props.group, props.refreshToggle]);

  const isColorSet = (value) => {
    return value && value !== emptyColor;
  };

  const refreshTransform = () => {
    const tr = props.group.getStage().findOne('Transformer');
    tr.nodes(tr.nodes());
  };

  const changeAndRedraw = (obj, property, val, setter, minVal) => {
    if (!minVal || val >= minVal) {
      obj.setAttr(property, val);
      props.scaleText(text);
      if (setter) setter(val);
      propertyChanged();
    }
  };

  const changeFontFamily =  (id, createdFont = null) => {
    const selectedFont = createdFont || props.fonts.find((font) => font.id === id);
    const boldRegex = /\bBold\b/;
    const defaultFont = 'Inter';

    if (selectedFont && selectedFont.name.startsWith(defaultFont) && boldRegex.test(selectedFont.name)) {
      text.setAttr('fontStyle', 700);
    } else {
      text.setAttr('fontStyle', 'normal');
    }

    text.setAttr('fontUrl', selectedFont.font);
    changeAndRedraw(text, 'fontFamily', selectedFont.family);
    setFontFamily(id);
  };

  const changeFontSize = (value) => {
    changeAndRedraw(text, 'fontSize', parseInt(value), setFontSize, 1);
  };

  const changeLineHeight = (value) => {
    changeAndRedraw(text, 'lineHeight', parseFloat(value), setLineHeight, 0.1);
  };

  const changeLetterSpacing = (value) => {
    if (value >= 0) {
      // В свойства записываем минимум minLetterSpacingValue (0.1)
      text.setAttr(
        'letterSpacing',
        value === 0 ? minLetterSpacingValue : value,
      );
      props.scaleText(text);
      // В значение value (минимум 0)
      setLetterSpacing(value);
      propertyChanged();
    }
  };

  const changeAlignV = (value) => {
    changeAndRedraw(text, 'verticalAlign', value, setAlignV);
  };

  const changeAlignH = (value) => {
    changeAndRedraw(text, 'align', value, setAlignH);
  };

  const changeTextFormat = (value) => {
    changeAndRedraw(text, 'textFormat', value, setTextFormat);
  };

  const checkChangeTextFormat = (value) => {
    if (textFormat === value) {
      changeAndRedraw(text, 'textFormat', undefined, setTextFormat);
    }
  };

  const changeTextDecoration = (
    isTextDecorationUnderline,
    isTextDecorationLineThrough,
  ) => {
    let value = '';
    if (isTextDecorationUnderline) {
      value += 'underline';
    }

    if (isTextDecorationLineThrough) {
      value += ' line-through';
    }

    value = value.trim();
    if (!value) {
      value = 'none';
    }
    changeAndRedraw(text, 'textDecoration', value, setTextDecoration);
  };

  const changeTextDecorationUnderline = () => {
    setTextDecorationUnderline(!isTextDecorationUnderline);
    changeTextDecoration(
      !isTextDecorationUnderline,
      isTextDecorationLineThrough,
    );
  };

  const changeTextDecorationLineThrough = () => {
    setTextDecorationLineThrough(!isTextDecorationLineThrough);
    changeTextDecoration(
      isTextDecorationUnderline,
      !isTextDecorationLineThrough,
    );
  };

  const changeTextColor = (color) => {
    text.setAttr('fill', color);
    setTextColor(color);
    propertyChanged();
  };

  const fonts = props.fonts.map((font) => ({
    id: font.id,
    name: font.name.replace(/\.ttf$/, ''),
    elementStyle: {
      fontFamily: font.family,
    },
  }));

  const uploadFont = async (font) => {
    try {
      const result = await props.uploadFont(font);
      if (result?.id) {
        changeFontFamily(result.id, result);
      }

      return result;
    } catch (error) {
      return null;
    }
  };

  const onTextBoxBgEnabledChanged = (value) => {
    setTextBoxBgEnabled(value);
  };

  const isBgTextBoxEnabled = () => {
    return (
      isColorSet(substrate.getAttr('fill')) ||
      (!!substrate.getAttr('strokeWidth') &&
        isColorSet(substrate.getAttr('stroke')))
    );
  };

  const propertyChanged = () => {
    refreshTransform(props.group);
    if (props.onPropertyChanged) {
      props.onPropertyChanged();
    }
  };

  const updateRefreshSizeToggle = () => {
    setRefreshSizeToggle((refreshSizeToggle) => !refreshSizeToggle);
  };

  const rotationChanged = () => {
    updateRefreshSizeToggle();
    propertyChanged();
  };

  return (
    <div>
      <div
        className={`${globalStyles.bottomBorderDivider} ${globalStyles.bottomBigPaddings}`}
      >
        <PositionSizeControl
          element={background}
          group={props.group}
          layoutPositioning={layoutPositioning}
          refreshToggle={refreshSizeToggle}
          onPropertyChanged={propertyChanged}
          changeAndRedraw={changeAndRedraw}
        />
        <div className={styles.positionControlBlock}>
          <PositioningControl
            group={props.group}
            attachToElement={props.attachToElement}
            detachElement={props.detachElement}
            refreshToggle={props.refreshToggle}
            setLayoutPositioning={setLayoutPositioning}
            onPropertyChanged={propertyChanged}
          />
        </div>
      </div>

      <div className={`${globalStyles.bottomBorderDivider} ${styles.block}`}>
        {textType !== 'dynamic' && (
          <p className={`${globalStyles.text} ${styles.title}`}>
            Font settings
          </p>
        )}

        <div
          className={`${styles.containerColumn} ${styles.fontsSettingsBlock}`}
        >
          <DataProperties
            refreshToggle={props.refreshToggle}
            product={props.product}
            group={props.group}
            scaleText={props.scaleText}
            productFields={props.productFields}
            onPropertyChanged={propertyChanged}
          />
          <div className={`${styles.containerColumn} ${styles.fontsBlock}`}>
            <SelectControl
              value={fontFamily}
              dataSource={fonts}
              onChange={(id) => changeFontFamily(id)}
              popupMatchSelectWidth
            />
            <UploadFontButton uploadFont={uploadFont} />
          </div>
        </div>

        <div
          className={`${styles.containerColumn} ${styles.fontsSettingsBlock} ${styles.fontsSettingsPadding}`}
        >
          <div className={styles.fontsSettingsInner}>
            <Text>Font colour:</Text>
            <ColorEditor
              type='simple'
              value={textColor}
              onChange={changeTextColor}
            />
          </div>
          {textType !== 'dynamic' && (
            <div className={styles.fontsSettingsInner}>
              <Text>Font size:</Text>
              <InputNumber
                size='small'
                value={fontSize}
                onChange={changeFontSize}
                prefix={<FontSizeIcon />}
                className={`${globalStyles.input} ${styles.fontInput}`}
              />
            </div>
          )}
          <div className={styles.fontsSettingsInner}>
            <Text>Line height:</Text>
            <InputNumber
              size='small'
              value={lineHeight}
              min={0.1}
              step={0.1}
              onChange={changeLineHeight}
              prefix={<LineHeightIcon />}
              className={`${globalStyles.input} ${styles.fontInput}`}
            />
          </div>

          <div className={styles.fontsSettingsInner}>
            <Text>Letter spacing:</Text>
            <InputNumber
              size='small'
              value={letterSpacing}
              min={0}
              step={1}
              onChange={changeLetterSpacing}
              prefix={<LetterSpacingIcon />}
              className={`${globalStyles.input} ${styles.fontInput}`}
            />
          </div>

          <div className={styles.fontsSettingsInner}>
            <Text>Decoration:</Text>
            <div className={styles.textDecorationBlock}>
              <Button
                icon={<UnderlineIcon />}
                onClick={changeTextDecorationUnderline}
                className={`
                                     ${styles.textDecoration} 
                                     ${styles.underlineButton}
                                     ${isTextDecorationUnderline ? styles.activeTextDecoration : ''}
                                `}
              />
              <Button
                icon={<StrikeIcon />}
                onClick={changeTextDecorationLineThrough}
                className={`
                                     ${styles.textDecoration} 
                                     ${styles.strikeButton}
                                     ${isTextDecorationLineThrough ? styles.activeTextDecoration : ''}
                                `}
              />
            </div>
          </div>
          <div
            className={`${styles.fontsSettingsInner} ${styles.alignmentBlock}`}
          >
            <Text>Alignment:</Text>
            <div className={`${styles.containerColumn} ${styles.radioGroup}  `}>
              <Radio.Group
                size='small'
                onChange={(e) => changeAlignH(e.target.value)}
                value={alignH}
                className={`${styles.textDecorationBlock}`}
              >
                <Radio.Button
                  value='left'
                  className={`${styles.radioButton} ${styles.radioButtonLeft} ${alignH === 'left' ? styles.activeTextDecoration : ''} `}
                >
                  <AlignLeftIcon />
                </Radio.Button>
                <Radio.Button
                  value='center'
                  className={`${styles.radioButton} ${alignH === 'center' ? styles.activeTextDecoration : ''}`}
                >
                  <AlignHorizontalCenterIcon />
                </Radio.Button>
                <Radio.Button
                  value='right'
                  className={` ${styles.radioButton}  ${styles.radioButtonRight}  ${alignH === 'right' ? styles.activeTextDecoration : ''}`}
                >
                  <AlignRightIcon />
                </Radio.Button>
              </Radio.Group>

              <Radio.Group
                size='small'
                onChange={(e) => changeAlignV(e.target.value)}
                value={alignV}
                className={`${styles.textDecorationBlock}`}
              >
                <Radio.Button
                  value='top'
                  className={`${styles.radioButton} ${styles.radioButtonLeft}} ${alignV === 'top' ? styles.activeTextDecoration : ''}`}
                >
                  <AlignTopIcon />
                </Radio.Button>
                <Radio.Button
                  value='middle'
                  className={`${styles.radioButton} ${alignV === 'middle' ? styles.activeTextDecoration : ''}`}
                >
                  <AlignVerticalCenterIcon />
                </Radio.Button>
                <Radio.Button
                  value='bottom'
                  className={`${styles.radioButton} ${styles.radioButtonRight} ${alignV === 'bottom' ? styles.activeTextDecoration : ''}`}
                >
                  <AlignBottomIcon />
                </Radio.Button>
              </Radio.Group>
            </div>
          </div>

          <div className={`${styles.fontsSettingsInner}`}>
            <Text>Case:</Text>
            <Radio.Group
              size='small'
              onChange={(e) => {
                changeTextFormat(e.target.value);
              }}
              value={textFormat}
              className={`${styles.textDecorationBlock}`}
            >
              <Radio.Button
                value='upperCase'
                onClick={() => checkChangeTextFormat('upperCase')}
                className={`${styles.radioButton} ${styles.radioButtonLeft} ${textFormat === 'upperCase' ? styles.activeTextDecoration : ''}`}
              >
                <UpperCaseIcon />
              </Radio.Button>
              <Radio.Button
                value='lowerCase'
                onClick={() => checkChangeTextFormat('lowerCase')}
                className={`${styles.radioButton} ${textFormat === 'lowerCase' ? styles.activeTextDecoration : ''}`}
              >
                <LowerCaseIcon />
              </Radio.Button>
              <Radio.Button
                value='withCapital'
                onClick={() => checkChangeTextFormat('withCapital')}
                className={`${styles.radioButton} ${styles.radioButtonRight} ${textFormat === 'withCapital' ? styles.activeTextDecoration : ''}`}
              >
                <RegularCaseIcon />
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
      </div>
      {textType === 'dynamic' && (
        <div className={globalStyles.bottomBorderDivider}>
          <DynamicBoxControl
            background={background}
            element={substrate}
            changeAndRedraw={changeAndRedraw}
          />
        </div>
      )}
      <div
        className={`${globalStyles.bottomBorderDivider} ${globalStyles.selectBlock} ${globalStyles.gapBlock}`}
      >
        <BackgroundBorder
          isEnabledFunc={isBgTextBoxEnabled}
          element={substrate}
          label='Text Box Settings'
          onEnabledChanged={onTextBoxBgEnabledChanged}
          onPropertyChanged={propertyChanged}
        />
        {isTextBoxBgEnabled && (
          <div
            className={` ${globalStyles.selectBlock} ${globalStyles.gapBlock}`}
          >
            <BorderControl
              manualEnabled={isTextBoxBgEnabled}
              element={substrate}
              onPropertyChanged={propertyChanged}
            />

            <ShadowControl
              element={substrate}
              onPropertyChanged={propertyChanged}
            />
          </div>
        )}
      </div>
      <div className={globalStyles.bottomBorderDivider}>
        <StrokeControl element={text} changeAndRedraw={changeAndRedraw} />
      </div>
      <div className={globalStyles.bottomBorderDivider}>
        <ShadowControl element={text} onPropertyChanged={propertyChanged} />
      </div>
      <div className={globalStyles.bottomBorderDivider}>
        <RotationControl
          group={props.group}
          element={text}
          refreshToggle={props.refreshToggle}
          onPropertyChanged={rotationChanged}
        />
      </div>
    </div>
  );
}
