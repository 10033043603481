import axios from 'axios';

import SERVER_ADDRESS from "./config";
import {addLocalStorage, getUserLocalStorage, logoutLocalStorage} from "./helpers";
import {message} from "antd";

export const axiosApi = axios.create({
    baseURL: SERVER_ADDRESS,
    withCredentials: true,
});

axiosApi.interceptors.request.use((config) => {
    const token = getUserLocalStorage('tokens','csrf');

    if (token && config.method !== 'get') {
        config.headers = {
            ...config.headers,
            'X-XSRF-TOKEN': token,
        };
    }
    return config;
});
axiosApi.interceptors.response.use(
    async (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = error.config;
        const statusCode = error?.response?.status;
        const tokens = getUserLocalStorage();
        const {pathname} = window.location;

        if (
            tokens &&
            statusCode === 401 &&
            error.config &&
            !error.config._isReady
        ) {
            originalRequest._isReady = true;
            try {
                const resp = await axios.post(`${SERVER_ADDRESS}/v2/accounts/refresh/`, {refresh: tokens.refresh}, {withCredentials: true});
                if (resp.status === 200) {
                    const newTokens = resp.data;
                    const usersTokensLocal = {
                        csrf: newTokens.csrf,
                        refresh: newTokens.refresh,
                    };
                    await addLocalStorage('tokens', usersTokensLocal);
                    return axiosApi(originalRequest);
                }
            } catch {
                logoutLocalStorage();
            }
        }

        if (statusCode === 403 && pathname !== '/login') {
            return message.error({
                content: "You don't have access rights. Please check your subscription plan!",
                duration: 3
            });
        }

        return Promise.reject(error);
    },
);
