import React, {Component} from 'react';
import {Container, Row} from 'react-bootstrap';
import {withRouter} from "react-router-dom";
import DnDFlow from "./DnDNodes";
import {message} from "antd";
import {axiosApi} from "../../axios-api";
import {handleGenerationsLimitError} from '../../helpers';

class NodesApp extends Component {
    api = this.props.backend;

    fetchProject = async (project_id) => {
        try {
            return await this.api.fetchProject(project_id);
        } catch (err) {
            console.error(err);
            window.location.href = '/';
        }
    };

	loadProject = async (project_id) => {
		try {
			this.setState({
				projectLoading: true
			});
			const result = await this.fetchProject(project_id);
			if (!result) return;
			const foundOrganization = this.props.backend.user.roles.find((role) => role.organization === result.organization.id);

			let newProject = result;

			if (foundOrganization) {
				newProject = {
					...result,
					organization: {
						...result.organization,
						...foundOrganization,
					}
				};
			}

			this.setState({
				project: newProject,
				ruleId: result.rule?.id,
				rule_json: result.rule ? JSON.parse(result.rule.rule_json) : null,
				ruleName: result.rule?.name,
				feedId: result.feed.id,
			});
		} finally {
			this.setState({
				projectLoading: false
			})
		}
	};

    loadDesigns = async (reload = false) => {
        const projectId = this.state.projectId;
        if (!reload && !!this.state.designs) return;
        try {
            return await this.api.fetchDesigns(projectId);
        } catch (error) {
            message.error(error);
        }
    };


    loadFeed = async (feedPageIndex) => {
        const { project, feedId } = this.state;
        if (project) {
          this.setState({productDataLoading: true})
            try {
                const {data} = await axiosApi.get(`/feed/?feed_id=${feedId}&page_num=${feedPageIndex}`);
                this.setState({ feedPage: data });
            } catch (error) {
                message.error(error.message);
            } finally {
              this.setState({productDataLoading: false})
            }
        }
    };


    fetchFeedTypes = async () => {
            const data = await this.api.fetchFeedTypes();
            this.setState({ feedTypes: data });
    };


    downloadStage = async () => {
        try {
            const init_data = this.state.rfNodes.toObject();
            let json_data = JSON.parse(JSON.stringify(init_data));
            for (let element of json_data.elements) {
                delete element.data?.project;
                delete element.data?.save;
                if (element.type === 'graphic') {
                    element.data.selected = element.data.selected.map(item => Number(item));
                }

                if(element.type === 'export') {
                    const type = element.data.output_type

                    if (Number(type) === 0 || type === '') {
                        element.data.output_type = null
                    } else {
                        element.data.output_type = Number(type)
                    }

                    if(!element.data.name) {
                        throw new Error('Export name is required')
                    }
                }
            }

            const url = !this.state.ruleId
                ? `/projects/${this.state.projectId}/rules/`
                : `/projects/${this.state.projectId}/rules/${this.state.ruleId}/`;

            await axiosApi({
                method: !this.state.ruleId ? 'POST' : 'PUT',
                url,
                data: {
                    name: `${this.state.projectId}-${this.state.ruleId}`,
                    rule_json: JSON.stringify(json_data),
                },
            });

            await this.loadProject(this.state.projectId);

            return true
        } catch (error) {
		        if (error.isAxiosError) {
			        handleGenerationsLimitError(error);
		        } else {
			        message.error(error.message);
		        }

            return false
        }
    };


    run = async () => {
        try {
            const projectId = this.state.projectId;
            await axiosApi.get(`/run/${projectId}`);
            message.success("Started flow!");
        } catch (error) {
            message.error(error);
        }
    };


    setNodesRf = (rfInstance) => {
        this.setState({rfNodes: rfInstance});
    }

    setDnDRf = (rfInstance) => {
        this.setState({rfDnD: rfInstance});
    }

    setRuleFetched = () => {
        this.setState({rule_json: undefined});
    }

    deleteTemplate = async (id) => {
        try {
            const data = await this.api.deleteTemplate(this.state.projectId, id);
            await this.loadDesigns();
            return data;
        } catch (err) {
            message.error(err);
        }
    };

    updateLoadingProductData = (value) => {
      this.setState({productDataLoading: value})
    };

    state = {
        rule_json: undefined,
        app: this,
        rfNodes: undefined,
        rfDnD: undefined,
        projectLoading: false,
        productDataLoading: false,
        // ----------------------{ Project settings }----------------------
        ruleName: "",
        dataSource: undefined,
        feedId: undefined,
        ruleId: this.props.match.params.rule_id,
        projectId: this.props.demoProjectId || this.props.match.params.project_id,
        feedPage: null,
        feedTypes: undefined,
        // ----------------------{ Behavior,  Data }----------------------
        projectData: undefined,                                          // stage JSON for restore project

        // ----------------------{       AJAX       }----------------------
        ajaxErrorCanvasSizes: null,

        // ----------------------{     Functions    }----------------------
        downloadStage: this.downloadStage,
        setNodesRf: this.setNodesRf,
        setRuleFetched: this.setRuleFetched,
        run: this.run,
        updateLoadingProductData: this.updateLoadingProductData,

        // ----------------------{      Layers      }----------------------
        layers: []
    };

    componentDidMount() {
        this.state.projectId && this.loadProject(this.state.projectId);
        this.fetchFeedTypes();
    }

    render() {
        return (
            <Container fluid={true} style={{height: '100%'}}>
                <Row style={{height: '100%'}}>
                    <DnDFlow
                        api={this.api}
                        app={this.state}
                        rule_json={this.state.rule_json}
                        loadDesigns={this.loadDesigns}
                        loadProject={this.loadProject}
                        designTemplates={this.state.designTemplates}
                        deleteTemplate={this.deleteTemplate}
                        projectId={this.state.projectId}
                        feedId={this.state.feedId}
                        loadFeed={this.loadFeed}
                        feedPage={this.state.feedPage}
                        loading={this.state.projectLoading}
                    />
                </Row>
            </Container>
        );
    }
}

export default withRouter(NodesApp);
