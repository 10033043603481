import React, {useState, useEffect} from 'react';
import {Handle} from 'react-flow-renderer';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Button, message, Tooltip} from 'antd';
import {useHistory} from "react-router-dom";

import { NodeSelect } from './NodeSelect';
import { runProject, stopProject } from 'components/Project/ProjectsApi/projectsApi';
import { isOutputsReadyToRun, isProjectRunning } from 'components/Project/StatusBlock/StatusBlock';
import { useWebSocket } from 'contexts/SocketContext';
import { GenerationStatus } from '../../../constants';
import {ReactComponent as EditIcon} from 'svg/node/edit-new-2.svg';
import {ReactComponent as NodeIcon} from 'svg/node/export-node-label.svg';
import {ReactComponent as CreateNewIcon} from 'svg/node/create-new-node.svg';
import {ReactComponent as PlayIcon} from 'svg/node/start-export-node.svg';
import {ReactComponent as StopIcon} from 'svg/node/stop-export-node.svg';
import {ReactComponent as CopyIcon} from 'svg/node/copy.svg'

import styles from './Nodes.module.css';


const {IN_PROGRESS, STOPPING} = GenerationStatus;

export default (props) => {
    const [mappings, setMappings] = useState([]);
    const [selectedFeedTemplate, setSelectedFeedTemplate] = useState('');
    const [feedNameValue, setFeedNameValue] = useState(props.data.name || '');
		const [outputStatus, setOutputStatus] = useState('N');

    const organization = props?.data?.project?.organization;
    const projectId = props.data.project?.id;

    const { data: socketData } = useWebSocket();

    const allOutputs = props?.data?.project?.outputs || [];
    const output = allOutputs.find(outputItem => outputItem.name === props.data.name) || null;

    const history = useHistory();

    let prettyUrl = '';
    if (output?.output_file) {
        prettyUrl = output.output_file
            .replace('http://', '')
            .replace('https://', '')
        let urlParts = prettyUrl.split('/')
        prettyUrl = urlParts[urlParts.length - 1].split('?')[0]
    }

    const saveAndGo = (to) => {
        props.data.save();
        history.push({pathname: to, search: window.location.search});
    }

    const defaultMapping = {
        value: '',
        label: 'Same as input'
    };

		useEffect(() => {
			if (output && socketData) {
				const socketOutput = socketData[output?.project]?.outputs[output?.id];

				if (socketOutput) {
					setOutputStatus(socketOutput.status);
				}
			}
		}, [output, socketData]);


    useEffect(() => {
        if (props.data?.output_type) {
            setSelectedFeedTemplate(props.data.output_type.toString());
        } else {
          props.data.output_type = defaultMapping.value;
        }
    }, [props.data?.output_type])

    useEffect(() => {
        (props.data.project.mappings ?? []).forEach(t => {
            if (props.data.feedTypes) {
                const exist = props.data.feedTypes.find(feed => feed.id === t.preset_name);
                t.type = exist ? exist.icon : undefined;
            }
        });
        const selectMappingOptions = props.data.project.mappings.map((mapItem) => (
          {value: mapItem.id.toString(), label: mapItem.name}
        ));
        setMappings([defaultMapping, ...selectMappingOptions]);
    }, [props.data.project.mappings])

    const handleFeedNameChange = (e) => {
        const re = /^[a-zA-Z0-9\s()_-]+$/i;
        if (!re.test(e.target.value)) {
            e.target.value = e.target.value.substring(0, e.target.value.length - 1);
            setFeedNameValue(e.target.value);
        } else {
            props.data.name = e.target.value;
            setFeedNameValue(e.target.value);
        }
    };

    const handleOutputRun = async () => {
    const resultRunProject = allOutputs.length === 1
      ? await runProject(props.data.project.id)
      : await runProject(props.data.project.id, [output.id], false);

    if (resultRunProject) {
      message.success('Started flow!');
    }
  }

		const handleOutputStop = async () => {
			await stopProject(props.data.project.id, {outputs: [output.id]});
		};

    const isRunAllowed = isProjectRunning(outputStatus) || !props?.data?.project?.organization?.generation_allowed;
    const isRunning = outputStatus === IN_PROGRESS || outputStatus === STOPPING;
    const isStopping = outputStatus === STOPPING;
    const isProjectRunningNow = isProjectRunning(socketData[projectId]?.status);
    const isAnyOutputRunning = isOutputsReadyToRun(socketData[projectId]?.outputs);
    const isButtonShouldDisable = !organization.generation_allowed || isProjectRunningNow || isAnyOutputRunning;

    return (
        <div className={styles.node}>
            <div className={`${styles.nodeHead} ${styles.exportNodeHead}`}>
                <NodeIcon />
                <span>Export</span>
              {output && (
                isRunning ? (
                  <Button
                    disabled={isStopping}
                    onClick={handleOutputStop}
                    className={`${styles.exportNodeControlsButton} ${isStopping ? styles.exportNodeDisabledButton : ''}`}
                  >
                    <StopIcon/>
                  </Button>
                ) : (
                  <Button
                    disabled={isRunAllowed}
                    onClick={handleOutputRun}
                    className={`${styles.exportNodeControlsButton} ${isRunAllowed ? styles.exportNodeDisabledButton : ''}`}
                  >
                    <PlayIcon/>
                  </Button>
                )
              )}
            </div>
            <div className={`${styles.nodeBody} ${styles.exportNodeBody}`}>
                <div className={`${styles.nodeBodyRow} ${styles.nodeBodyRowExport}`}>
                    <span className={styles.exportNodeFeedTitle}>Feed name</span>
                    <input
                        className={styles.nodeData}
                        type={'text'}
                        placeholder={'[a-zA-Z\\d\\s()_-]+'}
                        onChange={e => handleFeedNameChange(e)}
                        disabled={!organization.generation_allowed}
                        value={feedNameValue}
                        required
                    />
                    {feedNameValue === '' && <div className={styles.exportNodeFeedTitleNote}>* feed name is required</div>}
                </div>
                <div className={`${styles.nodeBodyRow} ${styles.nodeBodyRowExport} ${!!output ? styles.nodeBodyRowExportLink : ''}`}>
                    <span className={styles.exportNodeFeedTitle}>Feed link</span>
                    {!!output ? (<>
                            <input
                                className={styles.nodeData}
                                value={prettyUrl}
                                type='text'
                                disabled
                                readOnly
                            />
                            <CopyToClipboard
                                text={output.output_file?.split('?')[0]}
                                onCopy={() =>
                                    message.success({
                                        content: `Feed URL copied!\n${output.output_file}`,
                                        duration: 3
                                    })
                                }
                            >
                                <Button
                                    className={`${styles.nodeButtonIcon} ${styles.nodeButtonIconExportCopy}`}
                                    disabled={!organization.generation_allowed}
                                >
                                    <CopyIcon />
                                </Button>
                            </CopyToClipboard>
                        </>)
                        :
                        <input
                            className={styles.nodeData}
                            value={'file not ready'}
                            type='text'
                            disabled
                            readOnly
                        />
                    }
                </div>
                <div className={`${styles.nodeBodyRow} ${selectedFeedTemplate > 0 ? styles.nodeBodyRowIcons : ''}`}>
                    <NodeSelect
                        className={`${styles.nodeData} ${styles.nodeDataExport}`}
                        defaultValue={selectedFeedTemplate}
                        onChange={(value) => {
                            props.data.output_type = value
                            setSelectedFeedTemplate(value)
                        }}
                        disabled={isButtonShouldDisable}
                        options={mappings}
                    />
                    {selectedFeedTemplate > 0 &&
                        <Button
                            className={styles.nodeButtonIcon}
                            onClick={() => {
                                saveAndGo(`/project/${props.data.project.id}/mapping/${selectedFeedTemplate}`)
                            }}
                            disabled={isButtonShouldDisable}
                        >
                            <EditIcon />
                        </Button>
                    }
                </div>
              <Tooltip title={!props.data.name ? 'Feed name is required!' : ''}>
                <Button
                    className={`${styles.nodeButton} ${styles.nodeButtonExport}`}
                    onClick={() => {
                        saveAndGo(`/project/${props.data.project.id}/mapping/new`)
                    }}
                    disabled={isButtonShouldDisable || !props.data.name}
                    >
                    <span>Create new feed template</span>
                    <CreateNewIcon />
                </Button>
              </Tooltip>
            </div>
            <Handle
                type="target"
                position="left"
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={props.isConnectable}
                className={`${styles.nodeHandle} ${styles.nodeHandleLeft}`}
            />
        </div>
    );
};
