import React, { useEffect, useState } from 'react';
import { InputNumber } from 'antd';

import {
  getAbsoluteElementPosition,
  getParentPosition,
  refreshParentGroup,
} from '../../../services/PropertiesService';

import styles from './PositionSizeControl.module.css';
import globalStyles from '../../GlobalGraphicEditor.module.css';

export default function (props) {
  const defaultWidth = 200;

  const [positionX, setPositionX] = useState();
  const [positionY, setPositionY] = useState();

  const [elementWidth, setElementWidth] = useState();
  const [elementHeight, setElementHeight] = useState();

  const { group } = props;
  const { element } = props;
  const elementType = element?.attrs?.elementType;
  const { layoutPositioning } = props;

  useEffect(() => {
    const stage = props.group.getStage();
    const canvasPosition = stage.findOne('#canvas_bg').position();
    const position = getAbsoluteElementPosition(group, canvasPosition);

    const scaleX = group.getAttr('scaleX');
    const scaleY = group.getAttr('scaleY');
    setPositionX(position.x);
    setPositionY(position.y);

    setElementWidth(
      elementType === 'rect' ? element.width() : getSizeByScale(scaleX),
    );
    setElementHeight(
      elementType === 'rect' ? element.height() : getSizeByScale(scaleY),
    );
  }, [element, group, props.refreshToggle]);

  const changePositionX = (value) => {
    const val = parseInt(value);
    if (isNaN(val)) {
      return;
    }

    const parentPosition = getParentPosition(group);
    group.x(
      val + group.getStage().findOne('#canvas_bg').x() - parentPosition.x,
    );

    refreshParentGroup(group);
    setPositionX(val);
    propertyChanged();
  };

  const getSizeByScale = (scale) => {
    return Math.round(defaultWidth * scale);
  };

  const getScaleBySize = (size) => {
    return size / defaultWidth;
  };

  const changePositionY = (value) => {
    const val = parseInt(value);
    if (isNaN(val)) {
      return;
    }

    const parentPosition = getParentPosition(group);
    group.y(
      val + group.getStage().findOne('#canvas_bg').y() - parentPosition.y,
    );

    refreshParentGroup(group);
    setPositionY(val);
    propertyChanged();
  };

  const changeWidth = (value) => {
    const val = parseInt(value);
    if (isNaN(val) || val <= 0) {
      return;
    }

    if (elementType === 'rect') {
      element.width(val);
    } else {
      const scaleX = getScaleBySize(val);
      group.setAttr('scaleX', scaleX);
    }

    setElementWidth(val);
    propertyChanged();
  };

  const changeHeight = (value) => {
    const val = parseInt(value);
    if (isNaN(val) || val <= 0) {
      return;
    }
    if (elementType === 'rect') {
      element.height(val);
    } else {
      const scaleY = getScaleBySize(val);
      group.setAttr('scaleY', scaleY);
    }
    setElementHeight(val);
    propertyChanged();
  };

  const propertyChanged = () => {
    if (props.onPropertyChanged) {
      props.onPropertyChanged();
    }
  };

  return (
    <div className={styles.block}>
      <div className={styles.dataBlock}>
        <div className={styles.inputsBlock}>
          <div className={styles.label}>X</div>
          <InputNumber
            size='small'
            disabled={layoutPositioning !== 'absolute'}
            value={positionX}
            onChange={changePositionX}
            className={`${styles.input} ${globalStyles.input}`}
          />
        </div>

        <div className={styles.inputsBlock}>
          <div className={styles.label}>Y</div>
          <InputNumber
            size='small'
            disabled={layoutPositioning !== 'absolute'}
            value={positionY}
            onChange={changePositionY}
            className={`${styles.input} ${globalStyles.input}`}
          />
        </div>
      </div>
      <div className={styles.dataBlock}>
        <div className={styles.inputsBlock}>
          <div className={styles.label}>W</div>
          <InputNumber
            size='small'
            value={Math.round(elementWidth)}
            onChange={changeWidth}
            className={`${styles.input} ${globalStyles.input}`}
          />
        </div>
        <div className={styles.inputsBlock}>
          <div className={styles.label}>H</div>
          <InputNumber
            size='small'
            value={Math.round(elementHeight)}
            onChange={changeHeight}
            className={`${styles.input} ${globalStyles.input}`}
          />
        </div>
      </div>
    </div>
  );
}
