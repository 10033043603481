const emptyArray = ['', null, undefined];

export const validateMapping = (mapping, fields, clickedOnSave) => {        
    if (clickedOnSave && mapping.required && emptyArray.includes(mapping.value)) {
        return 'Required field';
    }

    const error = validateCorrectBracketsFields(mapping.value, fields);    
    
    return error ? error : '';
}

export const validateXmlMapping = (mapping, fields) => {    
    if (emptyArray.includes(mapping.attribute)) {
        return 'Attribute name is empty';
    }

    if (emptyArray.includes(mapping.value)) {
        return 'Value is empty';
    }

    const error = validateCorrectBracketsFields(mapping.value, fields);
    
    return error ? error : '';
}

export const validateCorrectBracketsFields = (ruleString, fields) => {
    let position = 0;
    let subString = ruleString;
    while (position > -1) {        
        let start = subString.indexOf('{{');
        let end = subString.indexOf('}}');

        if (start === -1 && end === -1) {
            return '';
        }

        if (start !== -1 && end === -1) {
            return 'Close bracket not found';
        }

        if (start === -1 && end !== -1) {
            return 'Open bracket not found';
        }

        const fieldArea = subString.substring(start + 2, end);

        const exist = fields.find(t => !t.dynamics && t.displayName === fieldArea);
        const dynamicExist = fields.find(t => t.dynamics === true && fieldArea.indexOf(t.name) === 0);

        let dynamicCorrect = false;
        if (dynamicExist) {
            dynamicCorrect = !!getSequenceNumberFromDynamicField(fieldArea);
        }

        if (!(exist || (dynamicExist && dynamicCorrect))) {
            return 'Product field not found';
        }

        subString = subString.substring(end + 2, subString.length);
    }
}

export const changeFieldsDisplayNameWithId = (ruleString, fields = []) => {
    let position = 0;
    let subString = ruleString;
    let result = ruleString;
    while (position > -1) {        
        let start = subString.indexOf('{{');
        let end = subString.indexOf('}}');

        if ((start === -1 && end === -1) || (start !== -1 && end === -1) || (start === -1 && end !== -1)) {
            break;
        }

        const fieldArea = subString.substring(start + 2, end);
        const exist = fields.find(t => t.displayName === fieldArea);
        const dynamicExist = fields.find(t => t.dynamics === true && fieldArea.indexOf(t.name) === 0);

        if (exist && !exist.dynamics) {
            result = result.replace(fieldArea, exist.id);
        } else if (dynamicExist) {
            const idFromName = getSequenceNumberFromDynamicField(fieldArea);
            if (idFromName) {
                result = result.replace(fieldArea, dynamicExist.id.replace('[N]', idFromName));
            } else {
                //ToDo: remove?
            }
        }

        subString = subString.substring(end + 2, subString.length);
    }
    return result;
}

export const changeFieldsIdWithDisplayName = (ruleString, fields = []) => {
    let position = 0;
    let subString = ruleString;
    let result = ruleString;
    while (position > -1) {        
        let start = subString.indexOf('{{');
        let end = subString.indexOf('}}');

        if ((start === -1 && end === -1) || (start !== -1 && end === -1) || (start === -1 && end !== -1)) {
            break;
        }

        const fieldArea = subString.substring(start + 2, end);
        const exist = fields.find(t => t.id === fieldArea);

        if (exist) {
            result = result.replace(fieldArea, exist.displayName);
        } else {
            const idPart = fieldArea.substring(0, fieldArea.lastIndexOf('_'));
            const idFromName = fieldArea.substring(fieldArea.lastIndexOf('_') + 1, fieldArea.length);
            const dynamicExist = fields.find(t => t.id === idPart + '_[N]');
            if (dynamicExist) {
                result = result.replace(fieldArea, dynamicExist.name + ' ' + idFromName);
            }
        }

        subString = subString.substring(end + 2, subString.length);
    }
    return result;
}

export const getSequenceNumberFromDynamicField = (displayName) => {
    if (!displayName) {
        return undefined;
    }
    
    const parts = displayName.split(' ');

    if (parts.length < 2) {
        return undefined;
    }

    var reg = /^\d+$/;

    const last = parts[parts.length - 1];
    const isInteger = reg.test(last) && +last !== 0;
    const value = Number.parseInt(last);

    if (!isNaN(value) && isInteger) {
        return value;
    }

    return undefined;
}