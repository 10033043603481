import React from 'react';
import {useState} from "react";
import {Button} from "antd";

import './NodeConditionSwitch.css';

const styles = {
    main: {
        display: 'flex',
        backgroundColor: '#E5C7FD',
        borderRadius: '18px',
        marginLeft: '8px',
    },
    button: {
        backgroundColor: 'transparent',
        borderRadius: '18px',
        fontSize: '12px',
        color: '#FFFFFF',
        border: 'none',
        height: '24px',
        width: '45px',
        padding: '0',
        fontWeight: '700',
        color: '#BD4BF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonActive: {
        backgroundColor: 'white',
    }
}

export default (props) => {
    let [condition, setCondition] = useState(props.condition);

    const click = (value) => {
        setCondition(value);
        if (props.onConditionChanged) {
            props.onConditionChanged(value);
        }
    }

    const andButtonStyle = condition === 'AND' ? {...styles.button, ...styles.buttonActive} : {...styles.button};
    const orButtonStyle = condition === 'OR' ? {...styles.button, ...styles.buttonActive} : {...styles.button};

    return (
        <div style={styles.main}>
            <Button
                className="switch-button"
                style={andButtonStyle}
                onClick={() => click('AND')}
                disabled={props.disabled}
            >
                <span>AND</span>
            </Button>
            <Button
                className="switch-button"
                style={orButtonStyle}
                onClick={() => click('OR')}
                disabled={props.disabled}
            >
                <span>OR</span>
            </Button>
        </div>
    );
};
