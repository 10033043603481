import React from 'react';
import { Progress, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { COLORS } from '../../../../constants';
import { formatNumbers } from '../../../../helpers';
import { iconsList } from '../../../../svg/icons-list';
import SubscriptionNotification from '../../../SubscriptionNotification/SubscriptionNotification';
import CustomPopConfirm from '../../../shared/CustomPopConfirm/CustomPopConfirm';
import MyButton from '../../../shared/MyButton/MyButton';
import { NoticeCode } from '../../../../constants';

import styles from './CurrentPlan.module.css';

const { Text } = Typography;

function CurrentPlan({
  organizationId,
  monthlyReport,
  isPlanCustom,
  openBillingPlans,
  features,
  isAllowed,
  noticeCode,
  noticeDeadline,
  query,
  cancelSubscription,
  isOrganizationParamsMatch = false,
}) {
  const history = useHistory();
  const [percent, setPercent] = useState(0);
  const [isBlockClosed, setIsBlockClosed] = useState(false);

  useEffect(() => {
    if (monthlyReport.generated) {
      setPercent(
        (monthlyReport.generated / monthlyReport.plan.img_count) * 100,
      );
    }
  }, [monthlyReport]);

  const closeNotification = () => {
    setIsBlockClosed(false);
    history.push(`/admin/${organizationId}`);
  };

  return (
    <div style={{ width: '100%' }}>
      <div className={styles.planBlock}>
        <h3 className={styles.planTitle}>
          Current plan
          <span className={styles.badge}>
          {formatNumbers(monthlyReport.processed || 0)}  /  {formatNumbers(monthlyReport?.plan?.feed_count || 0)} items
          </span>
        </h3>
        {noticeCode && (
          <SubscriptionNotification
            status={noticeCode === NoticeCode.CANCELLED ? NoticeCode.SHORT_NOTICE : noticeCode}
            endDate={noticeDeadline}
          />
        )}
        {query.get('status') === 'success' &&
          !isBlockClosed &&
          isOrganizationParamsMatch && (
            <SubscriptionNotification
              status='success'
              onNotificationClose={closeNotification}
            />
          )}
      </div>
      <div className={`${styles.planBlock} ${styles.flexBetweenBlock}`}>
        <div>
          {isPlanCustom && (
            <Text className={styles.renewalDate}>
              {noticeCode === NoticeCode.TRIAL ?
                `End Date: ${dayjs(noticeDeadline).format('MMM DD, YYYY')}` :
                `Renewal Date: ${dayjs(noticeDeadline).format('MMM DD, YYYY')}`
              }
            </Text>
          )}
        </div>
        {noticeCode !== NoticeCode.TRIAL && (
          <Text className={styles.price}>
            ${monthlyReport?.plan?.cost || 0}/month
          </Text>
        )}
      </div>
      <div className={styles.generationsBlock}>
        <div className={styles.flexBetweenBlock}>
          <Text className={styles.generationsText}>Image generations:</Text>
          <Text className={styles.generationsText}>
            {monthlyReport?.generated || 0}
            <span> of</span> {monthlyReport?.plan?.img_count || 0}{' '}
            <span>generations used</span>
          </Text>
        </div>
        <Progress
          percent={percent}
          showInfo={false}
          strokeColor={COLORS.mainViolet}
          size={['100%', 15]}
        />
      </div>
      <div>
        <Text className={styles.planTitle}>Current plan benefits</Text>
        <div className={styles.benefitsBlock}>
          {!!features.length &&
            features.map((benefit) => (
              <div key={benefit} className={`${styles.benefitBlock}`}>
                {iconsList.thick}
                <Text className={` ${styles.benefitTitle} `}>{benefit}</Text>
              </div>
            ))}
        </div>
      </div>
      {
        isPlanCustom && (
          <div className={styles.buttonsBlock}>
            <div className={styles.buttonsLeftBlock}>
              {noticeCode !== NoticeCode.TRIAL && (
                <CustomPopConfirm
                  title='Confirm canceling of the subsciption'
                  onConfirm={() => cancelSubscription(organizationId)}
                  placement='bottomLeft'
                  okText='Confirm'
                >
                    <MyButton
                      text='Cancel subscription'
                      customStyles={{
                        backgroundColor: COLORS.white,
                        color: COLORS.fontBlack,
                        border: `1px solid ${COLORS.grayBorder}`,
                        opacity: !isAllowed ? '0.5' : 'none',
                      }}
                      disabled={!isAllowed}
                    />
                </CustomPopConfirm>
                )}
              <MyButton
                onClick={openBillingPlans}
                text={
                  noticeCode === NoticeCode.EXPIRED || noticeCode === NoticeCode.CANCELLED
                    ? 'Renew subscription '
                    : 'Switch plan'
                }
                className={
                  noticeCode === NoticeCode.EXPIRED || noticeCode === NoticeCode.CANCELLED
                    ? styles.renewSubscriptionButton
                    : ''
                }
              />
            </div>
          </div>
        )
      }
    </div>
  );
}

export default CurrentPlan;
