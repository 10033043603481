import * as Sentry from '@sentry/react';

const { REACT_APP_SENTRY_DSN } = process.env;
const { REACT_APP_SENTRY } = process.env;

const initSentry = () => {
    if (REACT_APP_SENTRY_DSN) {
        Sentry.init({
            dsn: REACT_APP_SENTRY_DSN,
            integrations: [
                new Sentry.BrowserTracing(),
            ],
            tracesSampleRate: 1.0,
            environment: REACT_APP_SENTRY
        });
    }
};

export default initSentry;
