export const getAbsoluteElementPosition = (element, canvasPosition) => {
    const parentPosition = getParentPosition(element);
    return {
        x: Math.round(element.getAttr('x') - canvasPosition.x + parentPosition.x),
        y: Math.round(element.getAttr('y') - canvasPosition.y + parentPosition.y),
    }
}

export const isParentGroupElement = (element) => {
    return element && element.parent && element.parent.getAttr('elementType') === 'group';
}

export const getParentPosition = (element) => {
    let position = {x: 0, y: 0};
    if (isParentGroupElement(element)) {
        position.x = element.parent.getAttr('x');
        position.y = element.parent.getAttr('y');
    }

    if (element.parent) {
        const parentPosition = getParentPosition(element.parent);
        position.x += parentPosition.x;
        position.y += parentPosition.y;
    }

    return position;
}

export const refreshParentGroup = (element) => {
    if (isParentGroupElement(element)) {            
        const group = element.parent;
        const children = group.children;
        const newPosition = {
            x: Math.min(...children.map(o => o.x())),
            y: Math.min(...children.map(o => o.y())),
        }
        group.x(newPosition.x + group.x());
        group.y(newPosition.y + group.y());

        for (let child of children) {                
            child.position({
                x: child.x() - newPosition.x,
                y: child.y() - newPosition.y,
            })
        }

        refreshParentGroup(group);
    }
}

export const roundPositionAndSize = (stage) => {
  const designKeys = [
    'x',
    'y',
    'height',
    'width',
    'scaleX',
    'scaleY',
    'offsetX',
    'offsetY',
    'fillPatternScaleX',
    'fillPatternScaleY',
    'fillPatternOffsetX',
    'fillPatternOffsetY',
  ];

  const roundValue = (value) => Math.round(value * 100) / 100;

  const updateItemsAttrs = (item, isLayerChildren = false) => {
    if (item.attrs) {
      for (const key in item.attrs) {
        if (typeof item.attrs[key] === 'number' && designKeys.includes(key)) {
          item.setAttr(key, roundValue(item.attrs[key]));
        }
      }
    }
    if (item.children && item.children.length && isLayerChildren) {
      item.children.forEach(child => updateItemsAttrs(child, true));
    }
  };
  
  updateItemsAttrs(stage);

  const layerChildren = stage.findOne('#canvas')?.children;

  if (layerChildren.length > 0) {
    layerChildren.forEach(child => {
      updateItemsAttrs(child, true);
    });
  }
};