const SERVER_ADDRESS = process.env.REACT_APP_BACKEND_HOST ? `https://${process.env.REACT_APP_BACKEND_HOST}/api` : "http://localhost/api";
export const baseUrl = `https://${process.env.REACT_APP_BACKEND_HOST}`;
export const defaultFontFamilies = [
    {id: 200, family: 'Arial Normal', font: 'https://storage.yandexcloud.net/django/default_fonts/Arial.ttf', font_format: 'truetype'},
    {id: 201, family: 'Arial Bold', font: 'https://storage.yandexcloud.net/django/default_fonts/Arial_Bold.ttf', font_format: 'truetype'},
    {id: 203, family: 'Arial Italic', font: 'https://storage.yandexcloud.net/django/default_fonts/Arial_Italic.ttf', font_format: 'truetype'},
    {id: 204, family: 'Calibri', font: 'https://storage.yandexcloud.net/django/default_fonts/Calibri.ttf', font_format: 'truetype'},
    {id: 205, family: 'OpenSans Regular', font: 'https://storage.yandexcloud.net/django/default_fonts/OpenSans-Bold.ttf', font_format: 'truetype'},
    {id: 206, family: 'OpenSans Semibold', font: 'https://storage.yandexcloud.net/django/default_fonts/OpenSans-Semibold.ttf', font_format: 'truetype'},
    {id: 207, family: 'OpenSans Bold', font: 'https://storage.yandexcloud.net/django/default_fonts/OpenSans-Regular.ttf', font_format: 'truetype'},
    {id: 208, family: 'ElectroluxSans Regular', font: 'https://storage.yandexcloud.net/django/default_fonts/ElectroluxSans-Regular.otf', font_format: 'opentype'},
    {id: 209, family: 'ElectroluxSans SemiBold', font: 'https://storage.yandexcloud.net/django/default_fonts/ElectroluxSans-SemiBold.otf', font_format: 'opentype'},
    {id: 210, family: 'SamsungSharp SansBd', font: 'https://storage.yandexcloud.net/django/default_fonts/SamsungSharpSansBd.ttf', font_format: 'truetype'},
    {id: 211, family: 'CirceRounded-Regular', font: 'https://storage.yandexcloud.net/django/default_fonts/CirceRounded-Regular.ttf', font_format: 'truetype'},
    {id: 212, family: 'CirceRounded-Bold', font: 'https://storage.yandexcloud.net/django/default_fonts/CirceRounded-Bold.ttf', font_format: 'truetype'},
]

export const DEFAULT_IMAGE = 'https://storage.yandexcloud.net/django/default_images/cheboard.png';

export default SERVER_ADDRESS;
