import Konva from 'konva';
import { axiosApi } from '../../../axios-api';
import { message } from 'antd';

export const copyDesignTemplate = async (template, projectId , loadProject, loadTemplates) => {
  const copyStage = Konva.Node.create(
    template.design_json,
    'copy-template'
  );

  try {
    const stage_json = copyStage.toJSON();
    copyStage.destroy();
    await axiosApi.post(`/projects/${projectId}/designs/`,
      {
        name: template.name.replace(/_\d$/, ""),
        canvas_width: template.canvas_width,
        canvas_height: template.canvas_height,
        preset_name: template.preset_name,
        design_json: stage_json
      }
    );

    await loadProject(projectId);
    await loadTemplates();
    message.success("Copied!");
  } catch (error) {
    if (error.isAxiosError) {
      return message.warning({
        content: error.message,
        duration: 3,
      });
    }
    throw new Error(error);
  }
};