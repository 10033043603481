import React, {useState} from 'react';
import {Button, Popover, Switch, Typography} from "antd";
import ShapesMenu from "./ShapesMenu";
import {ReactComponent as BackArrow} from "../../../../svg/icon/left-arrow-short.svg";
import {ReactComponent as DownIcon} from "../../../../svg/icon/arrrow-down-small.svg"
import {ReactComponent as AddShapes} from "../../../../svg/graphicEditor/shapes.svg"
import {ReactComponent as AddImage} from "../../../../svg/graphicEditor/add-image.svg"
import {ReactComponent as AddText} from "../../../../svg/graphicEditor/text.svg"
import {ReactComponent as SaveDisk} from "../../../../svg/graphicEditor/save-disk.svg"
import {ReactComponent as Plus} from "../../../../svg/icon/plus.svg"

import styles from './GraphicEditorHeader.module.css';


const {Text} = Typography;
const GraphicEditorHeader = (props) => {
        const [shapesMenuOpen, setShapesMenuOpen] = useState(false);

        return (
            <header className={styles.header}>
                <div className={styles.wrapper}>
                    <Button
                        type='text'
                        shape='circle'
                        icon={<BackArrow/>}
                        onClick={props.exit}
                    />
                    <div className={`${styles.flexContainer} ${styles.buttonsWrapper}`}>
                        <Popover
                            content={
                                <ShapesMenu
                                    addRect={props.addRect}
                                    addEllipse={props.addEllipse}
                                    addPolygon={props.addPolygon}
                                    addStar={props.addStar}
                                    addLine={props.addLine}
                                />
                            }
                            placement='bottom'
                            trigger="click"
                            onOpenChange={(open) => setShapesMenuOpen(open)}
                        >

                            <Button
                                type='text'
                                icon={<AddShapes/>}
                                className={styles.borderlessButton}

                            >
                                <DownIcon className={shapesMenuOpen ? styles.activeArrow : ''}/>
                            </Button>
                        </Popover>
                        <Button
                            type='text'
                            icon={<AddImage/>}
                            className={styles.borderlessButton}
                            onClick={() => props.addImage()}
                        />
                        <Button
                            type='text'
                            icon={<AddText/>}
                            className={styles.borderlessButton}
                            onClick={() => props.addText()}
                        />
                        <Button
                            className={`${styles.roundButton} ${styles.addFieldButton}`}
                            onClick={props.onProductDataClick}
                            disabled={props.productDataLoading}
                        >
                            Add field
                            <Plus/>
                        </Button>

                        <div className={`${styles.flexContainer} ${styles.switchBlock}`}>
                            <Text>
                                Layers
                            </Text>
                            <div>
                                <Switch
                                    checked={props.isLayersPanelOpen}
                                    onChange={props.onLayersClick}
                                />
                            </div>
                        </div>

                        <div className={`${styles.flexContainer} ${styles.switchBlock}`}>
                            <Text>
                                Canvas on
                            </Text>
                            <div>
                                <Switch
                                    checked={!props.isCanvasOn}
                                    onChange={props.canvasOnOff}
                                />
                            </div>
                        </div>
                    </div>
                    <Button
                        className={`${styles.roundButton} ${styles.saveButton}`}
                        onClick={props.saveDesign}
                    >
                        Save
                        <SaveDisk/>
                    </Button>
                </div>
            </header>
        )
    }
;

export default GraphicEditorHeader;