import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Divider, Form, Input, Result, Alert } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { useQuery } from '../../../helpers';
import MyButton from '../../shared/MyButton/MyButton';
import logo from '../../../svg/common/logo.svg';

const ResetPassword = ({ setNewPassword }) => {
  const [resetCode, setResetCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const query = useQuery();

  useEffect(() => {
    const queryCode = query.get('code');
    if (queryCode) {
      setResetCode(queryCode);
    }
  }, [query]);

  const onFinish = async (values) => {
    if (values.password !== values.confirm_password) {
      setError('Passwords do not match!');
      return;
    }
    const result = await setNewPassword(
      resetCode,
      values,
      setError,
      setLoading,
    );
    if (result) {
      setConfirmed(true);
    }
  };

  return (
    <div className='centered-container'>
      <Col className='login-panel'>
        {confirmed ? (
          <Result
            icon={<SmileOutlined className='registration-confirm-icon' />}
            title='Password successfully changed'
            subTitle='Try to login with new password'
            extra={
              <Button size='large' type='primary' htmlType='link' href='/login'>
                Return to login page
              </Button>
            }
          />
        ) : (
          <>
            <div className='login-logo-div'>
              <img src={logo} alt='' />
              <p className='reset-password-text'>Enter the new password</p>
            </div>
            <div className='reset-password-form'>
              <Form onFinish={(values) => onFinish(values)}>
                <Form.Item
                  className='login-input-label'
                  label='Password'
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your password!',
                    },
                  ]}
                >
                  <Input.Password
                    autoComplete='new-password'
                    visibilityToggle={false}
                    className='login-input-field registration-input-field'
                  />
                </Form.Item>

                <Form.Item
                  className='login-input-label'
                  label='Confirm password'
                  name='confirm_password'
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                  ]}
                >
                  <Input.Password
                    autoComplete='off'
                    visibilityToggle={false}
                    className='login-input-field registration-input-field'
                  />
                </Form.Item>
                {error && (
                  <div className='error-alert-block'>
                    <Alert message={error} type='error' showIcon />
                  </div>
                )}
                <div className='login-button-block'>
                  <Form.Item>
                    <MyButton
                      htmlType='submit'
                      className='login-button'
                      text='Submit new password'
                      loading={loading}
                    />
                  </Form.Item>
                </div>
              </Form>
              <div className='login-divider'>
                <Divider />
              </div>
              <div className='bottom-block '>
                <Link className='login-link-gray' to='/login'>
                  Return to login page
                </Link>
              </div>
            </div>
          </>
        )}
      </Col>
    </div>
  );
};

export default ResetPassword;
