import React from 'react';
import { Redirect } from 'react-router-dom';

const ProtectedRoute = ({ isAllowed, pathRedirect, children }) => {
    if (!isAllowed) {
        return <Redirect to={pathRedirect} />;
    }

    return children;
};

export default ProtectedRoute;