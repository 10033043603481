import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { ReactComponent as DownIcon } from 'svg/icon/arrow-down-small-gray.svg';

import styles from './SelectControl.module.css';
import globalStyles from '../../GlobalGraphicEditor.module.css';

export default (props) => {
    const [value, setValue] = useState(props.value);
    const [isOpen, setIsOpen] = useState(false);

    const dataSource = props.dataSource ?? [];

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const realValue = dataSource.find(item => item.id === value);
    const dropdownMatchSelectWidth = props.dropdownMatchSelectWidth || null;

    const createOptionWithImage = (element) => {
        return (
            <div className={styles.imageOption}>
                {element.icon}<span>{element.name}</span>
            </div>
        );
    };

    const onSelectClick = () => {
        setIsOpen(!isOpen);
    };

  return (
    <div>
      {props.label && <div className={styles.label}>{props.label}</div>}
      <div className={globalStyles.selectBlock}>
        <Select
          size='small'
          open={isOpen}
          value={realValue?.id}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onClick={onSelectClick}
          onChange={props.onChange}
          popupMatchSelectWidth={dropdownMatchSelectWidth}
          dropdownStyle={props.dropdownStyle}
          suffixIcon={
            <DownIcon
              className={
                !props.disabled && isOpen ? globalStyles.activeIcon : ''
              }
            />
          }
          className={`${!props.isRoundSelect ? globalStyles.select : ''} ${(value && realValue?.id !== value) || props.hasErrors ? globalStyles.selectError : ''} ${props.style || ''}`}
        >
          {dataSource.map((element, index) => {
            return (
              <Select.Option
                style={element.elementStyle}
                key={`field-${index}`}
                value={element.id}
              >
                {!element.icon ? element.name : createOptionWithImage(element)}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};
