import React, { useEffect, useState } from 'react';
import { Button, InputNumber, Typography } from 'antd';
import { ColumnWidthOutlined } from '@ant-design/icons';

import ColorEditor from '../ColorEditor/ColorEditor';
import { isColorSet } from '../../properties/PropertiesHelper';
import { ReactComponent as PlusIcon } from 'svg/graphicEditor/plus-big-black.svg';
import { ReactComponent as MinusIcon } from 'svg/graphicEditor/minus-big-black.svg';

import globalStyles from '../../GlobalGraphicEditor.module.css';

const { Text } = Typography;

export default function (props) {
  const [isStrokeEnabled, setStrokeEnabled] = useState(false);
  const [strokeWidth, setStrokeWidth] = useState();
  const [strokeColor, setStrokeColor] = useState();

  const { element } = props;

  useEffect(() => {
    setStrokeEnabled(
      !!element.getAttr('strokeWidth') && isColorSet(element.getAttr('stroke')),
    );
    setStrokeWidth(element.getAttr('strokeWidth'));
    setStrokeColor(element.getAttr('stroke'));
  }, [element]);

  const changeStrokeColor = (color) => {
    changeAndRedraw(element, 'stroke', color, setStrokeColor, undefined);
  };

  const changeStrokeWidth = (value) => {
    changeAndRedraw(element, 'strokeWidth', parseInt(value), setStrokeWidth, 0);
  };

  const changeStrokeEnable = () => {
    setStrokeEnabled(!isStrokeEnabled);
    updateState(!isStrokeEnabled);
  };

  const updateState = (isEnabled) => {
    if (!isEnabled) {
      element.setAttr('_lastStateStroke', { strokeWidth, stroke: strokeColor });
      changeStrokeWidth(0);
    } else {
      const state = element.getAttr('_lastStateStroke');
      if (state) {
        changeStrokeWidth(state.strokeWidth);
        setStrokeColor(state.strokeColor);
      }
    }
  };

  const changeAndRedraw = (obj, property, val, setter, minVal) => {
    if (props.changeAndRedraw) {
      props.changeAndRedraw(obj, property, val, setter, minVal);
    }
  };

  return (
    <div>
      <div className={globalStyles.spaceBetweenBlock}>
        <Text className={globalStyles.smallTitle}>Stroke</Text>
        <Button
          shape='circle'
          type='text'
          icon={isStrokeEnabled ? <MinusIcon /> : <PlusIcon />}
          onClick={changeStrokeEnable}
        />
      </div>
      {isStrokeEnabled && (
        <div
          className={`${globalStyles.innerBlock} ${globalStyles.selectBlock} ${globalStyles.gapBlock}`}
        >
          <div className={globalStyles.spaceBetweenBlock}>
            <Text className={globalStyles.text}>Stroke:</Text>
            <InputNumber
              size='small'
              prefix={<ColumnWidthOutlined />}
              value={strokeWidth}
              onChange={changeStrokeWidth}
              className={globalStyles.input}
            />
          </div>
          <div className={globalStyles.spaceBetweenBlock}>
            <Text className={globalStyles.text}>Stroke colour:</Text>
            <ColorEditor
              value={strokeColor}
              onChange={changeStrokeColor}
              type='simple'
            />
          </div>
        </div>
      )}
    </div>
  );
}
