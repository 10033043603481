import React, { useEffect, useRef, useState } from 'react';
import Konva from 'konva';
import { Button, Input, Typography, Upload } from 'antd';

import SelectControl from '../../controls/SelectControl/SelectControl';
import ImageGallery from '../../ImageGallery/ImageGallery';
import Portal from '../../Portal';
import { DEFAULT_IMAGE } from '../../../../../config';
import { ReactComponent as UploadIcon } from 'svg/graphicEditor/upload-white.svg';

import myStyles from './ImageData.module.css';
import styles from '../../GlobalGraphicEditor.module.css';

const { Text } = Typography;
export default function (props) {
  const background = props.group.findOne('.background');

  const [image, setImage] = useState();
  const [inputPlaceholderValue, setInputPlaceholder] = useState();
  const [inputDynamicFieldValue, setInputDynamicFieldValue] = useState();
  const [imageType, setImageType] = useState();
  const [imageId, setImageId] = useState();
  const [userImages, setUserImages] = useState();
  const [galleryPosition, setGalleryPosition] = useState({ left: 0, top: 0 });
  const [isLibraryOpen, setLibraryOpen] = useState();

  useEffect(() => {
    setImage(props.group.findOne('Image'));
  }, [image]);

  useEffect(() => {
    const img = props.group.findOne('Image');
    setImage(img);
    setImageType(img.getAttr('dynamicField') ? 'dynamic' : 'static');
    setInputDynamicFieldValue(img.getAttr('dynamicField') || '');
    setInputPlaceholder(img.getAttr('placeholder'));
    if (!img.getAttr('dynamicField')) {
      const selected = props.userImages.find(
        (t) => t.image === img.getAttr('url'),
      );
      if (selected && selected.id !== imageId) {
        setImageId(selected.id);
      } else if (!selected) {
        setImageId(undefined);
      }
    }
  }, [props.group]);

  useEffect(() => {
    setUserImages(props.userImages);
  }, [props.userImages]);

  const changeDynamicField = (newField) => {
    image.setAttr('dynamicField', newField || undefined);
    const imgAttrs = image.getAttrs();
    if (newField) {
      const imageObj = new window.Image();
      imageObj.onload = function async() {
        const oldImage = imgAttrs.image;
        delete imgAttrs.image;
        delete imgAttrs.width;
        delete imgAttrs.height;
        const imgNode = new Konva.Image({
          ...imgAttrs,
          url: props.product[newField],
          image: imageObj,
          initWidth: imageObj.width,
          initHeight: imageObj.height,
        });
        background.fillPatternImage(imageObj);
        props.group.add(imgNode);
        if (oldImage) oldImage.remove();
        image.destroy();
        setImage(imgNode);
        props.scaleImage(imgNode);
      };
      imageObj.src = props.product[newField];
    } else {
      const imageObj = new window.Image();
      imageObj.onload = function () {
        const oldImage = imgAttrs.image;
        delete imgAttrs.image;
        delete imgAttrs.width;
        delete imgAttrs.height;
        const imgNode = new Konva.Image({
          ...imgAttrs,
          url: imgAttrs.placeholder,
          image: imageObj,
          initWidth: imageObj.width,
          initHeight: imageObj.height,
        });
        background.fillPatternImage(imageObj);
        props.group.add(imgNode);
        if (oldImage) oldImage.remove();
        image.destroy();

        setImage(imgNode);
        props.scaleImage(imgNode);
      };
      imageObj.src = imgAttrs.placeholder;
    }

    propertyChanged();
  };

  const changePlaceholderImage = (newUrl) => {
    image.setAttr('placeholder', newUrl);
    const imgAttrs = image.getAttrs();
    if (!image.getAttr('dynamicField')) {
      const imageObj = new window.Image();
      imageObj.onload = function () {
        const oldImage = imgAttrs.image;
        delete imgAttrs.image;
        delete imgAttrs.width;
        delete imgAttrs.height;
        const imgNode = new Konva.Image({
          ...imgAttrs,
          url: newUrl,
          image: imageObj,
          initWidth: imageObj.width,
          initHeight: imageObj.height,
        });
        background.fillPatternImage(imageObj);
        props.group.add(imgNode);
        if (oldImage) oldImage.remove();
        image.destroy();

        setImage(imgNode);
        props.scaleImage(imgNode);
        imageChanged();
      };
      imageObj.src = newUrl;
      setInputPlaceholder(image.getAttr('placeholder'));
    }
  };

  const onDynamicFieldChanged = (value) => {
    setInputDynamicFieldValue(value);
    changeDynamicField(value);
  };

  const customRequest = ({ onSuccess, onError, file }) => {
    props.uploadFile(file).then(
      (result) => {
        onSuccess(null, file);
        if (result) {
          changePlaceholderImage(result.image);
          setImageId(result.id);
        }
      },
      (error) => onError(error),
    );
  };

  const setLibraryImage = (id) => {
    const image = props.userImages.find((t) => t.id === id);
    if (image) {
      const imageUrl = image.image;
      changePlaceholderImage(imageUrl);
      setImageId(id);
      openLibrary();
    }
  };

  const uploadProps = {
    fileList: [],
    multiple: false,
  };

  const linkRef = useRef();

  const openLibrary = () => {
    if (!isLibraryOpen) {
      const el = linkRef.current;
      const left = el.offsetLeft + el.clientWidth + 175;
      let top = el.offsetTop - 114;
      if (top < 5) {
        top = 5;
      }
      setGalleryPosition({ left, top });
    }
    setLibraryOpen(!isLibraryOpen);
  };

  const deleteImage = async (id) => {
    await props.deleteImage(id);
    changePlaceholderImage(DEFAULT_IMAGE);
  };

  const propertyChanged = () => {
    if (props.onPropertyChanged) {
      props.onPropertyChanged();
    }
  };

  const imageChanged = () => {
    if (props.onImageChanged) {
      props.onImageChanged();
    }
  };

  return (
    <div>
      {imageType === 'static' && (
        <div
          className={`${styles.selectBlock} ${myStyles.actionButtonsWrapper}`}
        >
          <div className={`${styles.selectBlock} ${myStyles.actionButtonsTop}`}>
            <div className={styles.selectBlock}>
              <Text>Url</Text>
              <Input
                type='text'
                onChange={(e) => changePlaceholderImage(e.target.value)}
                value={inputPlaceholderValue}
                className={myStyles.input}
              />
            </div>
            <div className={`${styles.selectBlock}`}>
              <div ref={linkRef}>
                <button
                  type='button'
                  onClick={openLibrary}
                  className={myStyles.openLibraryButton}
                >
                  Select from library
                </button>
              </div>
            </div>
          </div>
          <Upload
            {...uploadProps}
            customRequest={(onSuccess, onError, file) =>
              customRequest(onSuccess, onError, file)
            }
            className={myStyles.uploadHandler}
          >
            <Button className={myStyles.uploadButton} icon={<UploadIcon />}>
              Upload
            </Button>
          </Upload>
        </div>
      )}

      {imageType === 'dynamic' && props.product && (
        <div>
          <SelectControl
            value={inputDynamicFieldValue}
            label='Field'
            onChange={onDynamicFieldChanged}
            dataSource={[
              ...Object.keys(props.product).map((item) => {
                return {
                  id: item,
                  name: item,
                  elementStyle: item.startsWith('__')
                    ? { color: '#3333ff' }
                    : null,
                };
              }),
            ]}
          />
        </div>
      )}

      {isLibraryOpen && (
        <Portal>
          <ImageGallery
            setLibraryImage={setLibraryImage}
            onClose={openLibrary}
            userImages={userImages}
            deleteImage={deleteImage}
          />
        </Portal>
      )}
    </div>
  );
}
